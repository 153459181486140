import React, { useState, memo } from "react";
import { Row, Col, Input } from "antd";
import { IBulkAttendanceInputProps, IKeyValue } from "./type";
import { disableInput } from "screens/bulkAttendance/helper";
import text from "text";
import { NA, defaultTimeValue } from "./constant";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";

function BulkAttendanceInput(props: IBulkAttendanceInputProps) {
  const {
    menuList,
    dropdownText,
    getSelectedValue,
    inputType,
    hour,
    shouldDisable,
  } = props;

  const getHour = () => {
    if (hour?.includes(":")) {
      return hour;
    }
    if (hour === null) return defaultTimeValue;
    if(hour?.includes(".")){
    const hours = hour?.split(".") || [];
    const formattedHours = hours[0].length === 2 ? `${hours[0]}:${hours[1]}`: `0${hours[0]}:${hours[1]}`;
    return formattedHours;
    }
    return `${hour}:00` || defaultTimeValue;
  };

  const [prevHours, setPrevHours] = useState(hour);
  const [prevDropdownText, setPrevDropdownText] = useState(dropdownText);
  const [dropdownValue, setDropdownValue] = useState(
    dropdownText === null || dropdownText === undefined
      ? text.SELECT
      : dropdownText
  );
  const [inputValue, setInputValue] = useState(getHour);

  const onChangeValue = (val: any) => {
    setInputValue(val.currentTarget.value);
    getSelectedValue(dropdownValue, val.currentTarget.value);
  };

  const getValue = (value: IKeyValue) => {
    setDropdownValue(value.value);
    getSelectedValue(value.key, inputValue);
  };

  const getPrevHours = () => {
    setPrevHours(hour);
    setInputValue(getHour);
    return getHour();
  };

  const getDropdownText = () => {
    setPrevDropdownText(dropdownText);
    setDropdownValue(dropdownText || text.SELECT);
    return dropdownText;
  };

  return (
    <Row type="flex">
      <Col className="mr-4">
        <SearchableDropdown<IKeyValue>
          list={menuList}
          onSelect={(i) => getValue(i as IKeyValue)}
          value={
            prevDropdownText != dropdownText ? getDropdownText() : dropdownValue
          }
          isDisabled={shouldDisable || dropdownValue === NA}
          width = {100}
          dropdownWidth={250}
          optionKeyExtractor={(item) => item.key}
          optionLableExtractor={(item) => item.value}
        />
      </Col>
      {inputType === "H" && (
        <Col className="mt-1 align-middle">
          <Input
            type="time"
            onChange={onChangeValue}
            value={prevHours !== hour ? getPrevHours() : inputValue}
            disabled={
              shouldDisable ||
              dropdownValue === NA ||
              disableInput(dropdownValue)
            }></Input>
        </Col>
      )}
    </Row>
  );
}

export default memo(
  BulkAttendanceInput,
  (prevState, nextState) => prevState === nextState
);
