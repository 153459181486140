import { EmployeeList, Reportees } from "models/associate.data";

export const getAssociateList = (employeeList: EmployeeList[]) => {
  const associateList : Reportees[] = [];
  employeeList?.map((item: EmployeeList, index) => {
    associateList.push({
      id: index,
      associateUsers: "",
      customOutpt1: item.positionDescription,
      customOutput2: item.customTextOutputEL2,
      customOutput3: 0,
      employeeCode: item.employeeCode,
      employeeName: item.employeeName,
      primaryEmailId: item.emailID,
      primaryMobileNumber: item.mobileNumber,
    });
  });
  return associateList;
};