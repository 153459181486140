import { SummaryCardObj } from "components/AdvanceSalaryComponent/type";
import { SummaryList } from "models/advanceSalary.data";
import text from "text";

export const defaultList = {
  data: [],
  message: "",
};

export const CLMGR_INTERNAL_ROLE = "CLMGR";

export const summaryCardData: SummaryCardObj[] = [
  {
    title: text.PENDING,
    value: 0,
    color: "warning",
  },
  {
    title: text.APPROVED,
    value: 0,
    color: "success",
  },
  {
    title: text.REJECTED,
    value: 0,
    color: "error",
  },
];

export const getSummaryValue = (data: SummaryList[]) => {
  if (data.length) {
    summaryCardData[1].value = data.filter(
      element => element.statusCode === "A",
    ).length && data.filter(
      element => element.statusCode === "A",
    ) [0].recordCount;
    summaryCardData[0].value = data.filter(
      element => element.statusCode === "P",
    ).length && data.filter(
      element => element.statusCode === "P",
    )[0].recordCount;
    summaryCardData[2].value = data.filter(
      element => element.statusCode === "R",
    ).length && data.filter(
      element => element.statusCode === "R",
    )[0].recordCount;
  }
  return summaryCardData;
};

export const defaultFilterValues = {
  statusType: "",
  startDate: null,
  endDate: null,
};

export const REJT = "Rejt";
export const AUTH = "Auth";