
import React from "react";
import { Row, DatePicker } from "antd";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import text from "text";
import Search from "components/Search";
import moment, { Moment } from "moment";
import { IFilter, StatusList } from "./type";
import { statusList } from "./constant";

export default function Filter(props: IFilter) {

  const {
    filterValues,
    onChangeFilters,
    setSearchText,
    searchValue,
  } = props;

  const onStatusChange = (value: StatusList) => {
    onChangeFilters({ ...props.filterValues, statusType: value.statusCode });
  };

  const onStartDateChange = (date: Moment | null, dateString: string) => {
    onChangeFilters({ ...filterValues, startDate: date});
  };

  const onEndDateChange = (date: Moment | null, dateString: string) => {
    onChangeFilters({ ...filterValues, endDate: date});
  };

  const isStartDateDisabled = (date: Moment | null) => {
    if (date && filterValues.endDate && date > filterValues.endDate) return true;
    return false;
  };

  const isEndDateDisabled = (date: Moment | null) => {
    if (date && filterValues.startDate && date < filterValues.startDate) return true;
    return false;
  };

  return (
    <Row type="flex" className="my-2" justify="space-between">
        <Row type="flex" align="middle">
            <Search
                placeholder={text.SEARCH}
                data={[]}
                getSearchedValue={setSearchText}
                width={200}
                value={searchValue}
            />
            <Row className="ml-2">
              <SearchableDropdown<StatusList>
                label={text.STATUS_TYPE}
                list={statusList}
                onSelect={(e) => onStatusChange(e as StatusList)}
                optionLableExtractor={(item) => item.statusDesc}
                optionKeyExtractor={(item) => item.statusCode}
                value={filterValues.statusType || text.ALL}
              />
            </Row>
        </Row>
        <Row type="flex" align="middle">
            <div className="w-100 w-md-auto mr-2"> {text.START_DATE} :</div>
            <DatePicker
                value={
                    filterValues.startDate
                    ? moment(filterValues.startDate)
                    : undefined
                }
                onChange={onStartDateChange}
                disabledDate={isStartDateDisabled}
            />
            <div className="w-100 w-md-auto mx-2">{text.END_DATE} :</div>
            <DatePicker
                value={
                filterValues.endDate
                    ? moment(filterValues.endDate)
                    : undefined
                }
                onChange={onEndDateChange}
                disabledDate={isEndDateDisabled}
                disabled={!filterValues.startDate}
            />
        </Row>
    </Row>
  );
}
