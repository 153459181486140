import React, { useEffect, useState } from "react";
import { Row, Button } from "antd";
import { style } from "styles/Fonts";
import { homeBackground, adeccoLogo,homeScreenImage } from "AssetHelper";
import { webUrl } from "./Constants";
import { AuthHelper } from "helpers";
import { UserContext, ContextType } from "models/userGontext.data";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { ContextActions } from "screens/home/store/home/actions";
import Loader from "components/Loader";
import ErrorMessage from "components/ErrorMessage";
import ContextService from "../store/home/service";

export default function Home(props: any) {
  const [contextData, setContextData] = useState<string>();
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const contextApiData: UserContext = useSelector((state: any) => {
    return (
      state.contextStore.contextDetails &&
      state.contextStore.contextDetails.data
    );
  }, shallowEqual);

  const contextDataStore: ContextType = useSelector((state: any) => {
    return state.contextStore;
  }, shallowEqual);

  const isLoading: boolean = useSelector((state: any) => {
    return state.contextStore.loading;
  }, shallowEqual);

  const dispatch = useDispatch();

  const getContextData = () => {
    dispatch(ContextActions.getContextDetails.started());
  };
  const getRelativePath = () => {
    dispatch(ContextActions.fetchCandidateRelativePathList.started());
  };

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== "undefined"
    ) {
      if (AuthHelper.isAuthenticated()) {
        getContextData();
      }
    } else if (window.location.search.includes("code=")) {
      const data = window.location.search.replace("?", "").split("&");
      const code = data[0].split("=")[1];
      setShowLoader(true);
      ContextService.fetchAccessRefreshtoken(code)
        .then(({ access_token, refresh_token }: any) => {
          setShowLoader(false);
          if (access_token !== "undefined" && refresh_token !== "undefined") {
            AuthHelper.onSetAuthDetails(access_token, refresh_token);
            if (AuthHelper.isAuthenticated()) {
              getContextData();
            }
          }
        })
        .catch((err: any) => setShowLoader(false));
    }
  }, []);

  useEffect(() => {
    if (
      contextApiData !== null &&
      contextApiData !== undefined &&
      AuthHelper.isAuthenticated()
    ) {
      const isClient =
        contextDataStore.userDetails.data.filter(
          (item: any) =>
            item.internalRole === "CLI" || item.internalRole === "CLMGR"
        ).length > 0;
      if (isClient) {
        props.history.push("/home/dashboard");
      } else {
        setErrorMessages(["You are not Authorized to view, Kindly Logout."]);
        AuthHelper.onLogoutUser(false);
        setContextData("");
      }
    }
  }, [JSON.stringify(contextDataStore.allowedActivities)]);

  const onClick = () => {
    window.location.href = webUrl;
  };

  const onLogoutUnauthorizedUser = () => {
    AuthHelper.openLogoutTab();
    props.history.replace("/");
    setErrorMessages([]);
  };

  return (
    <Row className="vh-100" type="flex" id="home">
      <Row
        className="bg-home w-100 w-lg-50 min-vh-100 d-flex d-lg-none"
        type="flex"
        align="middle"
        justify="center"
        id="textSection"
        style={{ backgroundImage: `url(${homeBackground})` }}
      >
        <div id="text" className="d-flex flex-column vh-100">
          <Row type="flex" justify="center" id="adecco" style={{flex:0.55}} align="bottom">
            <h1 className="c-white">
              <img width="103%" src={adeccoLogo} />
            </h1>
          </Row>
          <Row
            type="flex"
            justify="center"
            id="buttonSection"
            style={{flex:0.45}}
            align="middle">
            {errorMessages.length > 0 && (
              <ErrorMessage messages={errorMessages} />
            )}
            <div>
              {(contextData === undefined && AuthHelper.isAuthenticated()) ||
              showLoader ? (
                <Loader />
              ) : errorMessages.length === 0 ? (
                <Button
                  className="bg-white w-auto my-1"
                  onClick={onClick}
                  id="proceedButton"
                  style={{height:"120%",fontWeight:"bold"}}
                  type="primary"
                  shape="round">
                  <div style={{...style.normal, ...({padding:5})}} className="c-primary m-1">
                    Proceed to Login{" "}
                  </div>
                </Button>
              ) : (
                <Button
                  className="bg-white w-auto my-1"
                  onClick={onLogoutUnauthorizedUser}
                  type="primary"
                  shape="round">
                  <div style={style.normal} className="c-primary m-1">
                    Logout{" "}
                  </div>
                </Button>
              )}
            </div>
          </Row>
        </div>
      </Row>
      {/* before*/}
      <Row className="d-none d-lg-flex w-100">
      <Row
        className="bg-home w-100 d-none d-lg-flex"
        style={{flex:0.65}}
        type="flex"
        align="middle"
        justify="center"
        id="textSection"
      >
        <div id="text">
          <Row type="flex" justify="center" id="adecco" >
            <h1 className="c-white">
              <img width="103%" src={homeScreenImage} />
            </h1>
          </Row>
        </div>
      </Row>
      <Row
        className=" bg-white d-none d-lg-flex"
        style={{flex:0.35}}
        type="flex">
        {errorMessages.length > 0 && <ErrorMessage messages={errorMessages} />}
        <Row type="flex" justify="center" id="buttonSection" className="w-100" style={{alignSelf:"center"}}>
          {(contextData === undefined && AuthHelper.isAuthenticated()) ||
          showLoader ? (
            <Loader />
          ) : errorMessages.length === 0 ? (
            <Button
              className="bg-primary w-65 my-1"
              onClick={onClick}
              id="proceedButton"
              type="primary"
              style={{height:"120%",fontWeight:"bold"}}
              shape="round">
              <div style={{...style.normal, ...({padding:5})}} className="c-white m-1">
                Proceed to Login{" "}
              </div>
            </Button>
          ) : (
            <Button
              className="bg-primary w-65 my-1"
              onClick={onLogoutUnauthorizedUser}
              type="primary"
              shape="round">
              <div style={style.normal} className="c-white m-1">
                Logout{" "}
              </div>
            </Button>
          )}
        </Row>
      </Row>
      </Row>
    </Row>
  );
}
