import moment from "moment";

export const dateFormat = "YYYY-MM-DDTHH:mm:ss";

export const defaultClientEndDate = moment().endOf("day");
export const defaultClientStartDate = moment()
  .startOf("day")
  .subtract(3, "months");
export const defaultClientLeaveEndDate = moment()
  .endOf("day")
  .add(3, "months");
export const defaultClientLeaveStartDate = moment()
  .startOf("day")
  .subtract(3, "months");

export const defaultLeaveEndDate = moment().endOf("year");

export const defaultTransferEndDate = moment().add(1, "day");

export const dateCodes = {
  FULL_WEEK_DAY: "dddd",
  HALF_WEEK_DAY: "ddd",
  AM_PM: "hh:mma",
  MONTH_dATE: "MMM DD",
  STRING_DATE: "YYYY-MM-DD",
  MONTH_NUMBER: "MM",
  MONTH_NUMBER_SINGLE_DIGIT: "M",
  FULL_YEAR: "YYYY",
  MONTH: "month",
  FULL_MONTH: "MMMM",
  HALF_DETAILED: "ddd, DD MMM YY",
  HR: "Hr",
  MIN: "Min",
  AM: "AM",
  PM: "PM",
  MOMENT_H: "h",
  NORMAL_HOURS_FORMAT: "hh",
  MINUTE: "mm",
  MOMENT_H_24: "HH",
  MOMENT_M: "m",
  MOMENT_A: "a",
  TO_ISO_8601: "YYYY-MM-DDTHH:mm:ss",
  TIME_IN_HOURS: "HH:mm:ss.000",
  HALF_MONTH: "MMM",
  FULL_MONTH_YEAR: "MMMM YYYY",
  FULL_DATE: "YYYY-MM-DD",
  MONTH_DATE: "MM/DD/YYYY",
  START_TIME: "T00:00:00.000",
  END_TIME: "T23:59:59.999",
  WEEK_DATE_MONTH_YEAR: "ddd, DD MMM YY",
  DATE_MONTH_YEAR: "DD MMMM, YYYY",
  DATE_NUMBER: "DD",
  DATE_WITH_TIME: "Do MMM'YY, h:mm a",
  HALF_DATE_MONTH: "Do MMM'YY",
  DATE_MONTH: "DD MMM",
  MONTH_DATE_YEAR: "MMM DD, YYYY",
  DATE_HALF_MONTH_YEAR: "DD-MMM-YYYY",
  STD_DATE: "DD/MM/YYYY",
  OUT_TIME_FORMAT: "HH:mm:00.000",
  IN_TIME_FORMAT: "HH:mm:00.000",
  MONTH_YEAR: "MM-YYYY",
};
