import axios from "axios";
import { EndPoints } from "store/model/ApiConfig.data";
import { store } from "../store/Store";

export enum AuthConstants {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  ON_LOGOUT_ACTION = "ON_LOGOUT",
}

export class AuthHelper {
  static setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static getItem(key: string) {
    return localStorage.getItem(key);
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  static onSetAuthDetails(accessToken: string, refreshToken: string) {
    AuthHelper.setItem(AuthConstants.ACCESS_TOKEN, accessToken);
    AuthHelper.setItem(AuthConstants.REFRESH_TOKEN, refreshToken);
  }

  static onRemoveAuthDetails() {
    AuthHelper.removeItem(AuthConstants.ACCESS_TOKEN);
    AuthHelper.removeItem(AuthConstants.REFRESH_TOKEN);
  }

  static isAuthenticated() {
    return AuthHelper.getItem(AuthConstants.ACCESS_TOKEN) ? true : false;
  }

  static getAccessToken() {
    return `Bearer ${AuthHelper.getItem(AuthConstants.ACCESS_TOKEN)}`;
  }

  static getRefreshToken() {
    return AuthHelper.getItem(AuthConstants.REFRESH_TOKEN);
  }

  static async refreshAccessToken(): Promise<{ access_token: string }> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(
          `${
            process.env.REACT_APP_AUTH_REFRESH_TOKEN_URL
          }?refresh_token=${AuthHelper.getRefreshToken()}&type=WEB`,
          {
            headers: {
              Authorization: AuthHelper.getAccessToken(),
            },
          },
        );
        AuthHelper.onSetAuthDetails(data.access_token, data.refresh_token);
        window.location.reload();
        resolve(data);
      } catch (err) {
        reject(err);
        await AuthHelper.onLogoutUser(true);
      }
    });
  }

  static openLogoutTab = () => {
    const windowRef: any = window.open(
      `${process.env.REACT_APP_AUTH_BASE_URL}${EndPoints.EndSession}`,
    );
    setTimeout(() => {
      windowRef.close();
    }, 1500);
  };

  static async onLogoutUser(openTab = true) {
    return new Promise(async (resolve, reject) => {
      try {
        if (openTab) {
          AuthHelper.openLogoutTab();
        }
        await axios.get(
          `${
            process.env.REACT_APP_AUTH_REVOKE_TOKEN_URL
          }?refresh_token=${AuthHelper.getRefreshToken()}&type=WEB`,
          {
            headers: {
              Authorization: AuthHelper.getAccessToken(),
            },
          },
        );
        store.dispatch({ type: AuthConstants.ON_LOGOUT_ACTION });
        AuthHelper.onRemoveAuthDetails();
        resolve();
      } catch (err) {
        store.dispatch({ type: AuthConstants.ON_LOGOUT_ACTION });
        AuthHelper.onRemoveAuthDetails();
        window.location.href="/";
        resolve();
      }
    });
  }
}
