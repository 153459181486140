import { MetadataResponse, PostResignationBody, PostResignationResponse, ResignationList, SummaryData } from "models/resignation.data";
import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { GetRequestParams } from "./type";

export class ResignationService {
  public getSummary(requestData: GetRequestParams) :
   Promise<SummaryData> | SummaryData {
    const service: ApiService = new ApiService(
      EndPoints.GET_RESIGNATION_SUMMARY_DETAILS,
    );
    return service.get({
      dynamicQueryParams: [{
        toDateSearch: requestData.endDate,
        fromDateSearch: requestData.startDate,
        statusCodeSearch: requestData.statusType,
      }],
    });
  }

  public getDetailList(requestData: GetRequestParams) :
   Promise<ResignationList> | ResignationList {
    const service: ApiService = new ApiService(
      EndPoints.GET_RESIGNATION_DETAILS_LIST,
    );
    return service.get({
      dynamicQueryParams: [{
        searchToDate : requestData.endDate,
        searchFromDate: requestData.startDate,
        separationStatusCodeSearch: requestData.statusType,
      }],
    });
  }

  public approveRejectResignation(
    request: PostResignationBody,
  ): Promise<PostResignationResponse> | PostResignationResponse {
    const service: ApiService = new ApiService(EndPoints.POST_APPROVE_REJECT_RESIGNATION);
    return service.post({}, request);
  }

  public getMetadata() : Promise<MetadataResponse> | MetadataResponse {
    const service: ApiService = new ApiService(
      EndPoints.GET_RESIGNATION_METADATA,
    );
    return service.get({});
  }

}

export default new ResignationService();
