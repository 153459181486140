import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { UserDetails } from "models/profile.data";

export class UserService {
  public getUser(): Promise<UserDetails> | UserDetails {
    const service: ApiService = new ApiService(EndPoints.profileUser);
    return service.get({});
  }
}

const userService = new UserService();
export default userService;
