export const statusList = [
  {
    statusCode: "",
    statusDesc: "All",
  },
  {
    statusCode: "P",
    statusDesc: "Pending",
  },
  {
    statusCode: "A",
    statusDesc: "Authorized",
  },
  {
    statusCode: "R",
    statusDesc: "Rejected",
  },
  {
    statusCode: "S",
    statusDesc: "Sanctioned",
  },
];

export const REJT = "Rejt";
export const AUTH = "Auth";