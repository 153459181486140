export const menuList = [
  {
    key: "Current Month",
    value: "Current Month",
    title: "Current Month",
  },
  {
    key: "Current Quarter",
    value: "Current Quarter",
    title: "Current Quarter",
  },
  {
    key: "Current Year",
    value: "Current Year",
    title: "Current Year",
  },
];
