import { put, takeLatest } from "redux-saga/effects";

import {
  ContactsDetails,
  QualificationDetails,
  WorkExperienceDetails,
  PersonalInformationDetails,
  EmployeeFamilyMemberDetails,
  UserDetails,
} from "../../../../models/profile.data";
import { ProfileActions } from "./actions";
import {
  ContactsService,
  FamilyMemberService,
  PersonalInformationService,
  QualificationsService,
  WorkExperienceService,
  UserService,
} from "../../service";

function* getQualifications(action: any) {
  const { payload } = action;
  try {
    const response: QualificationDetails = yield QualificationsService.getQualifications(
      payload,
    );
    yield put(
      ProfileActions.getQualifications.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      ProfileActions.getQualifications.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* getFamilyMembers(action: any) {
  const { payload } = action;
  try {
    const response: EmployeeFamilyMemberDetails = yield FamilyMemberService.getFamilyMember(
      payload,
    );
    yield put(
      ProfileActions.getFamilyMembers.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      ProfileActions.getFamilyMembers.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* getPersonalInformation(action: any) {
  const { payload } = action;
  try {
    const response: PersonalInformationDetails = yield PersonalInformationService.getPersonalInformation(
      payload,
    );
    yield put(
      ProfileActions.getPersonalInformation.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      ProfileActions.getPersonalInformation.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* getWorkExperience(action: any) {
  const { payload } = action;
  try {
    const response: WorkExperienceDetails = yield WorkExperienceService.getWorkExperience(
      payload,
    );
    yield put(
      ProfileActions.getWorkExperience.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      ProfileActions.getWorkExperience.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* getContacts(action: any) {
  const { payload } = action;
  try {
    const response: ContactsDetails = yield ContactsService.getContacts(
      payload,
    );
    yield put(
      ProfileActions.getContacts.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      ProfileActions.getContacts.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* getUser(action: any) {
  const { payload } = action;
  try {
    const response: UserDetails = yield UserService.getUser();
    yield put(
      ProfileActions.getUser.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      ProfileActions.getContacts.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* ProfileManagementSaga() {
  yield takeLatest(ProfileActions.getUser.started, getUser);
  yield takeLatest(
    ProfileActions.getPersonalInformation.started,
    getPersonalInformation,
  );
  yield takeLatest(ProfileActions.getContacts.started, getContacts);
  yield takeLatest(ProfileActions.getFamilyMembers.started, getFamilyMembers);
  yield takeLatest(ProfileActions.getQualifications.started, getQualifications);
  yield takeLatest(ProfileActions.getWorkExperience.started, getWorkExperience);
}

export default ProfileManagementSaga;
