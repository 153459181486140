import React from "react";
import { Col, Row, Icon } from "antd";
import { associateImage } from "AssetHelper";
import Search from "components/Search";
import { ExistingLineManagerList } from "models/associate.data";
import { style } from "styles/Fonts";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import text from "text";

export default function AssociateHeader(props: {
  handleGridView: () => void;
  gridView: boolean;
  setSearchText: (value: string) => void;
  isLineManager: boolean;
  lineManagerList: ExistingLineManagerList[];
  onLineManagerChange: (value: ExistingLineManagerList) => void;
  selectedLineManager: string;
}) {
  const {
     handleGridView,
     gridView,
     setSearchText,
     isLineManager,
     lineManagerList,
     onLineManagerChange,
     selectedLineManager } = props;

  return (
    <Row
      type="flex"
      align="middle"
      justify="space-between"
      className="p-0"
      id="associate">
      <Col md={{ span: 8 }} className="pb-2 pt-0" id="heading">
        <Row type="flex" align="middle" className="align-items-center">
          <img src={associateImage} className="h-3" alt="associate Icon" />
          &nbsp;<span style={style.large}> Associates</span>
        </Row>
      </Col>
      <Col className="pb-2" id="heading">
        <Row
          justify="space-between"
          type="flex"
          align="middle"
          className="align-items-centre">
          {isLineManager &&
          <SearchableDropdown<ExistingLineManagerList>
            label={text.SELECT_LINE_MANAGER}
            list={lineManagerList || []}
            onSelect={(i) => onLineManagerChange(i as ExistingLineManagerList)}
            optionLableExtractor={(item) => item.existingLineManagerCode}
            optionKeyExtractor={(item) => item.existingLineManagerName}
            value={selectedLineManager}
          />}
          <Col className="ml-0 p-0">
            <Search
              placeholder={"Search Name"}
              data={[]}
              getSearchedValue={setSearchText}
            />
          </Col>
          <Col>
            {gridView && (
              <a
                onClick={handleGridView}
                className="ml-2 pt-2 p-1 bg-white"
                style={{ outline: "none" }}>
                <Icon type="unordered-list" style={{ fontSize: "18px" }} />
              </a>
            )}
            {!gridView && (
              <a
                onClick={handleGridView}
                className="ml-2 pt-2 p-1 bg-white"
                style={{ outline: "none" }}>
                <Icon type="appstore" style={{ fontSize: "18px" }} />
              </a>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
