import React, { useState, useEffect } from "react";
import { Card, Row, Col, Skeleton } from "antd";
import { size } from "styles/Fonts";
import { defaultTitleStyle, valueDescStyle } from "./constant";
import { useOnboardingDetailsList } from "screens/analytics/hooks";
import { queryCache } from "react-query";
import AnalyticsScreenModal from "components/AnalyticsScreenModal/AnalyticsScreenModal";
import { CLMGR_INTERNAL_ROLE } from "screens/analytics/constant";
import { ISingleSummaryItemsProps } from "screens/analytics/type";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { CLI_INTERNAL_ROLE } from "screens/bulkAttendance/constant";

export default function SingleSummaryCard(props: ISingleSummaryItemsProps) {
  const [modalVisible, setModalVisibility] = useState(false);

  const { contextStore } = useSelector((state: ApplicationState) => state);

  const isInternalRoleClient = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === CLI_INTERNAL_ROLE
  )
    ? true
    : false;
  const {
    isFetching: isSummaryLoading,
    data: onboardingAuthorisedAnalytics,
  } = useOnboardingDetailsList(props.cardDetail?.endpoint, {
    toDate: props.toDate,
    fromDate: props.fromDate,
    internalRole: isInternalRoleClient ? CLI_INTERNAL_ROLE :CLMGR_INTERNAL_ROLE,
    queryKey: props.cardDetail?.endpoint,
  });

  const clearQueryCache = () => {
    queryCache.removeQueries(props.cardDetail?.endpoint);
  };

  useEffect(() => {
    return clearQueryCache;
  }, []);

  const defaultValueStyle = {
    fontSize: size.h1,
    fontWeight: 550,
  };

  const onRequestClose = () => {
    setModalVisibility(!modalVisible);
  };

  const getCount = () => {
    return <Row>{onboardingAuthorisedAnalytics?.data.totalCount || 0}
      <span style={valueDescStyle}>{props.cardDetail.desc}</span></Row>;
  };

  const renderContent = () => {
    return (
      <Row
        style={defaultValueStyle}
        className="cursor-pointer"
        onClick={onRequestClose}>
        {isSummaryLoading ? (
          <Skeleton paragraph={{ rows: 0 }} active={true} loading={true} />
        ) : (
            (props.cardDetail.apiCode === "CLI_ANLY_CLIENT_CONTRACT_DET" &&
              onboardingAuthorisedAnalytics?.data.totalCount! > 90
              ? props.clientContractExpiration(false)
              : props.clientContractExpiration(true), getCount()
            )
          )}
      </Row>
    );
  };

  return (
    <>
      <Card className="m-1 w-100 w-md-24 min-vh-15">
        <Row type="flex">
          <Col span={6}>
            <img src={props.cardDetail.icon} width="40" />
          </Col>
          <Col span={18}>
            <Row style={defaultTitleStyle} className="ff-secondary">
              {props.cardDetail.heading}
            </Row>
            {renderContent()}
          </Col>
        </Row>
      </Card>
      {modalVisible && (
        <AnalyticsScreenModal
          title={props.cardDetail.heading}
          tableEndpoint={props.cardDetail.tableEndpoint}
          onItemClick={onRequestClose}
          modalVisible={modalVisible}
          toDate={props.toDate}
          fromDate={props.fromDate}
          apiCode={props.cardDetail.apiCode}
        />
      )}
    </>
  );
}
