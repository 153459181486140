import React from "react";
import { Row, Col, Card } from "antd";
import { style } from "styles/Fonts";
import Loader from "./Loader";

export default function OverviewCard(props: {
  overviewList: {}[];
  colorList: any[];
  isLoading: boolean;
}) {
  const { overviewList, colorList, isLoading } = props;

  return (
    <Card>
      {isLoading ? (
        <Loader />
      ) : (
        <Row type="flex">
          {overviewList.map((row: any, index: number) => (
            <Col
              className="pl-md-9 pr-md-9 pr-0 pl-0 w-100 w-md-auto pt-md-0 pt-2"
              style={{
                borderRight:
                  window.screen.width > 750
                    ? index === overviewList.length - 1
                      ? "none"
                      : "thin solid #ececec"
                    : "",
              }}>
              <Row
                type="flex"
                justify="center"
                style={{ ...style.h1, color: colorList[index] }}>
                {row.value}
              </Row>
              <Row
                type="flex"
                justify="center"
                style={style.xsmall}
                className="c-secondary">
                <span>{row.title}</span>
              </Row>
            </Col>
          ))}
        </Row>
      )}
    </Card>
  );
}
