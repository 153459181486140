
import React from "react";
import { Row, Col, Card } from "antd";
import { style } from "styles/Fonts";
import { ISummaryCard, SummaryCardObj } from "./type";
import Loader from "components/Loader";

export default function TransferSummaryCard(props: ISummaryCard) {

  const { isLoading, data } = props;

  return (
    <Card>
    {isLoading ? (
        <Loader />
    ) : (
        <Row type="flex">
            {data.map((row: SummaryCardObj, index: number) => (
                <Col
                    key={index}
                    className="pl-md-9 pr-md-9 pr-0 pl-0 w-100 w-md-auto pt-md-0 pt-2"
                    style={{
                      borderRight:
                        window.screen.width > 750
                            ? index === data.length - 1
                                ? "none"
                                : "thin solid #ececec"
                            : "",
                    }}
                >
                    <Row
                        type="flex"
                        justify="center"
                        style={{ ...style.h1 }}
                        className={`c-${row.color}`}
                    >
                        {row.value}
                    </Row>
                    <Row
                        type="flex"
                        justify="center"
                        style={style.xsmall}
                        className="c-secondary"
                    >
                        <span>{row.title}</span>
                    </Row>
                </Col>
            ))}
        </Row>
        )}
    </Card>
  );
}
