import { FilterValues } from "./type";

export const initialFilterValues: FilterValues = {
  invoiceTypeCode: "",
  invoiceFromDate: null,
  invoiceToDate: null,
  clientPO: "",
  lineManagerCode: "",
  departmentCode: "",
  statusCode: "",
  isAdvanceFilterVisible: false,
  invoiceTypeCategory: "",
};

export const QBC = "QBC";
export const CLI_INTERNAL_ROLE = "CLI";
export const CLMGR_INTERNAL_ROLE = "CLMGR";

export const fieldKeys = {
  INVOICE_TYPE: "invoiceTypeCode",
  FROM_DATE: "invoiceFromDate",
  TO_DATE: "invoiceToDate",
  CLIENT_PO: "clientPO",
  LINE_MANAGER: "lineManagerCode",
  DEPARTMENT_CODE: "departmentCode",
  STATUS_CODE: "statusCode",
  INVOICE_TYPE_CATEGORY: "invoiceTypeCategory",
};

export const defaultMetadata = {
  clientCode: "",
  clientName: "",
  customDateOutput1: "",
  customDateOutput2: "",
  customIntegerOutput1: 0,
  customIntegerOutput2: 0,
  customNumericOutput1: 0,
  customNumericOutput2: 0,
  customTextOutput1: "",
  customTextOutput2: "",
  customTextOutput3: "",
  customTextOutput4: "",
  invoiceTypeList: [],
  statusList: [],
};

export const defaultAdvancedMetadata = {
  clientCode: "",
  clientName: "",
  customDateOutput1: "",
  customDateOutput2: "",
  customIntegerOutput1: 0,
  customIntegerOutput2: 0,
  customNumericOutput1: 0,
  customNumericOutput2: 0,
  customTextOutput1: "",
  customTextOutput2: "",
  customTextOutput3: "",
  customTextOutput4: "",
  clientPOList: [],
  departmentList: [],
  lineManagerList: [],
};

export const defaultInvoiceDetails = {
  clientCodeOutput: "",
  clientNameOutput: "",
  customDateOutput1: "",
  customDateOutput2: "",
  customIntegerOutput1: 0,
  customIntegerOutput2: 0,
  customNumericOutput1: 0,
  customNumericOutput2: 0,
  customTextOutput1: "",
  customTextOutput2: "",
  customTextOutput3: "",
  customTextOutput4: "",
  invoiceCount: 0,
  invoiceList: [],
};
