export const returnSuffix = (date: string) => {
  const dateModTen = parseInt(date,10) % 10;
  const dateModHundred = parseInt(date,10) % 100;
  if (dateModTen === 1 && dateModHundred !== 11) {
    return "st ";
  }
  if (dateModTen === 2 && dateModHundred !== 12) {
    return "nd ";
  }
  if (dateModTen === 3 && dateModHundred !== 13) {
    return "rd ";
  }
  return "th ";
};
