import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { LineManagerDetailsResponse,
  LineManagerMetadataResponse,
  SaveLineManagerRequestBody,
  SaveLineManagerResponse } from "models/lineManager.data";
import { GetLineManagersAssociateParams } from "./type";

export class LineManagerService {
  public getLineMangerMetadata(internalRole: string) :
   Promise<LineManagerMetadataResponse> | LineManagerMetadataResponse {
    const service: ApiService = new ApiService(
      EndPoints.GET_LINE_MANAGER_METADATA,
    );
    return service.get({
      dynamicQueryParams: [{ internalRole }],
    });
  }

  public getLineMangersAssociateData(params: GetLineManagersAssociateParams) :
   Promise<LineManagerDetailsResponse> | LineManagerDetailsResponse {
    const service: ApiService = new ApiService(
      EndPoints.GET_LINE_MANAGER_ASSOCIATE_DETAILS,
    );
    return service.get({
      dynamicQueryParams: [{
        internalRole: params.internalRole,
        existingLineManagerCode: params.existingLineManagerCode,
      }],
    });
  }

  public postLineManagerData(
    request: SaveLineManagerRequestBody,
  ): Promise<SaveLineManagerResponse> | SaveLineManagerResponse {
    const service: ApiService = new ApiService(
      EndPoints.POST_LINE_MANAGER_DETAILS,
    );
    return service.post({}, request);
  }
}

export default new LineManagerService();
