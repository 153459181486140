import {
  AssociateLeaveDetailsStateType,
  AssociatesLeaveDetails,
  Approverrequest,
  Approverresponse,
  ErrorMsg,
  LeaveApproveResponse,
  LeaveList,
} from "models/leave.data";
import { reducerWithInitialState } from "typescript-fsa-reducers";

import { LeaveActions } from "./actions";

const initialState: AssociateLeaveDetailsStateType = {
  loading: false,
  error: null,
  associatesLeaveDetails: {
    data: {
      ApproverList: [],
      pageIndex: 1,
      pageSize: 1,
      totalSize: 1,
    },
    message: "",
  },
};

const leaveReducer = reducerWithInitialState(initialState)
  .cases(
    [LeaveActions.getAssociatesLeaveDetails.started],
    (state: AssociateLeaveDetailsStateType) => ({
      ...state,
      error: null,
      loading: true,
    }),
  )
  .case(
    LeaveActions.getAssociatesLeaveDetails.done,
    (
      state: AssociateLeaveDetailsStateType,
      payload: { result: AssociatesLeaveDetails },
    ) => ({
      ...state,
      error: null,
      loading: false,
      associatesLeaveDetails: payload.result,
    }),
  )
  .case(
    LeaveActions.getAssociatesLeaveDetails.failed,
    (state: AssociateLeaveDetailsStateType, payload: any) => ({
      ...state,
      error: payload,
      loading: false,
    }),
  )
  .case(
    LeaveActions.getByName,
    (state: AssociateLeaveDetailsStateType, payload: string) => {
      payload = (payload as string).replace(/\s/g, "");
      const regexp = new RegExp(payload as string, "i");
      const data = state.associatesLeaveDetails.data.ApproverList;
      const updatedData =
        payload === ""
          ? data
          : data
          ? data.filter((element: LeaveList) => {
              const name = element.requestorEmployeeName?.replace(/\s/g, "");
              if ((name as string).match(regexp)) {
                return element;
              }
            })
          : [];
      return {
        ...state,
        associatesLeaveDetails: {
          ...state.associatesLeaveDetails,
          data: {
            ...state.associatesLeaveDetails.data,
            ApproverList: updatedData,
          },
        },
      };
    },
  )

  .default(state => {
    return state;
  });

export default leaveReducer;
