import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import {
  EmployeeFamilyMemberDetails,
  getRequestProfileDetails,
} from "models/profile.data";

export class FamilyMemberService {
  public getFamilyMember(
    requestParams: getRequestProfileDetails,
  ): Promise<EmployeeFamilyMemberDetails> | EmployeeFamilyMemberDetails {
    const service: ApiService = new ApiService(EndPoints.profileFamilyMembers);
    return service.get({
      dynamicQueryParams: [{ employeeCode: requestParams.employeeCode }],
    });
  }
}

const familyMemberService = new FamilyMemberService();
export default familyMemberService;
