import React, { useEffect, useState } from "react";
import { Empty, Row } from "antd";
import text from "text";
import { resignationImage } from "AssetHelper";
import { style } from "styles/Fonts";
import { IFilterParams, ListType, SummaryType } from "./type";
import {
  ALL_REQUEST_CODE,
  defaultList,
  defaultMetadata,
  getSummaryValue,
  initialFilterValues,
  summaryCardValue,
} from "./constants";
import {
  EmployeeList,
  EmployerReasonList,
  MetadataResponse,
  PostResignationObj,
  ResignationList,
  SummaryData,
} from "models/resignation.data";
import Loader from "components/Loader";
import ResignationCard from "components/ResignationCard/ResignationCard";
import Filter from "components/ResignationFilter/Filter";
import SummaryCard from "components/ResignationSummaryCard/SummaryCard";
import ResignationService from "./service";
import {
  dateFormat,
  defaultClientEndDate,
  defaultClientStartDate,
  defaultClientLeaveEndDate,
} from "date";
import _ from "lodash";
import { WavierList } from "components/ResignationCard/type";
import { getFilterDate } from "./helper";
import { ModuleType } from "screens/home/store/home/constants";
import { ApplicationState } from "store/RootReducer";
import { useSelector } from "react-redux";

export default function Resignation() {
  const [resignationInfo, setResignationInfo] = useState<ListType>({
    isLoading: false,
    data: defaultList,
    metadata: defaultMetadata,
  });

  const [summaryData, setSummaryData] = useState<SummaryType>({
    data: summaryCardValue,
    isLoading: true,
  });

  const [searchValue, setSearchValue] = useState("");

  const [filterValue, setFilterValue] = useState<IFilterParams>(
    initialFilterValues
  );

  const { contextStore } = useSelector((state: ApplicationState) => {
    return state;
  });

  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);

  useEffect(() => {
    getList({
      startDate: defaultClientStartDate,
      endDate: defaultClientLeaveEndDate,
      statusType: ALL_REQUEST_CODE,
    });
    getSummary({
      startDate: defaultClientStartDate,
      endDate: defaultClientLeaveEndDate,
      statusType: ALL_REQUEST_CODE,
    });
  }, []);

  const getSummary = async (requestedData: IFilterParams) => {
    let summaryCount = summaryCardValue;
    try {
      setSummaryData({
        ...summaryData,
        isLoading: true,
      });
      const { startDate, endDate } = requestedData;
      const response: SummaryData = await ResignationService.getSummary({
        startDate: startDate ? startDate.format(dateFormat) : "",
        endDate: endDate ? endDate.format(dateFormat) : "",
        statusType: ALL_REQUEST_CODE,
      });
      summaryCount = getSummaryValue(response.data);
      setSummaryData({
        isLoading: false,
        data: summaryCount,
      });
    } catch (err) {
    } finally {
      setSummaryData({
        isLoading: false,
        data: summaryCount,
      });
    }
  };

  const getList = async (requestedData: IFilterParams) => {
    let response: ResignationList = defaultList;
    let metadata: MetadataResponse = defaultMetadata;
    try {
      setResignationInfo({
        ...resignationInfo,
        isLoading: true,
      });
      const { startDate, endDate, statusType } = requestedData;
      response = await ResignationService.getDetailList({
        startDate: startDate ? startDate.format(dateFormat) : "",
        endDate: endDate ? endDate.format(dateFormat) : "",
        statusType,
      });
      metadata = await ResignationService.getMetadata();
      setResignationInfo({
        isLoading: false,
        data: response,
        metadata,
      });
    } catch (err) {
    } finally {
      setResignationInfo({
        isLoading: false,
        data: response,
        metadata,
      });
    }
  };

  const onFilterChange = (value: IFilterParams) => {
    const { startDate, endDate } = getFilterDate(value);
    getList({
      startDate,
      endDate,
      statusType: value.statusType,
    });
    getSummary({
      startDate,
      endDate,
      statusType: value.statusType,
    });
    setSearchValue("");
    setFilterValue({
      startDate: value.startDate,
      endDate: value.startDate ? value.endDate : null,
      statusType: value.statusType,
    });
  };

  const onChangeRemarks = (value: string, separationNumber: number) => {
    const updatedAssociateDetails = _.cloneDeep(resignationInfo.data);
    updatedAssociateDetails.data.employeeList
      ?.filter(
        (item: EmployeeList) => item.separationNumber === separationNumber
      )
      .map((item: EmployeeList) => {
        item.relievingRemarks = value;
      });
    setResignationInfo({
      ...resignationInfo,
      data: updatedAssociateDetails,
    });
  };

  const onChangeEmployerReason = (
    value: EmployerReasonList,
    separationNumber: number
  ) => {
    const updatedAssociateDetails = _.cloneDeep(resignationInfo.data);
    updatedAssociateDetails.data.employeeList
      ?.filter(
        (item: EmployeeList) => item.separationNumber === separationNumber
      )
      .map((item: EmployeeList) => {
        item.reason = value.employerReasonCode;
        item.employerReason = value.employerReason;
      });
    setResignationInfo({
      ...resignationInfo,
      data: updatedAssociateDetails,
    });
  };

  const onChangeNoDueConfirmation = (
    value: string,
    separationNumber: number
  ) => {
    const updatedAssociateDetails = _.cloneDeep(resignationInfo.data);
    updatedAssociateDetails.data.employeeList
      ?.filter(
        (item: EmployeeList) => item.separationNumber === separationNumber
      )
      .map((item: EmployeeList) => {
        item.noDueConfirmation = value;
      });
    setResignationInfo({
      ...resignationInfo,
      data: updatedAssociateDetails,
    });
  };

  const onChangeWavier = (value: WavierList, separationNumber: number) => {
    const updatedAssociateDetails = _.cloneDeep(resignationInfo.data);
    updatedAssociateDetails.data.employeeList
      ?.filter(
        (item: EmployeeList) => item.separationNumber === separationNumber
      )
      .map((item: EmployeeList) => {
        item.noticePeriodWaiverFlag = value.wavierDesc;
        item.noticePeriodWaiverFlagCode = value.wavierCode;
      });
    setResignationInfo({
      ...resignationInfo,
      data: updatedAssociateDetails,
    });
  };

  const onChangeApproverComments = (
    value: string,
    separationNumber: number
  ) => {
    const updatedAssociateDetails = _.cloneDeep(resignationInfo.data);
    updatedAssociateDetails.data.employeeList
      ?.filter(
        (item: EmployeeList) => item.separationNumber === separationNumber
      )
      .map((item: EmployeeList) => {
        item.approverComments = value;
      });
    setResignationInfo({
      ...resignationInfo,
      data: updatedAssociateDetails,
    });
  };

  const onModalClose = (separationNumber: number) => {
    const updatedAssociateDetails = _.cloneDeep(resignationInfo.data);
    updatedAssociateDetails.data.employeeList
      ?.filter(
        (item: EmployeeList) => item.separationNumber === separationNumber
      )
      .map((item: EmployeeList) => {
        item.approverComments = "";
        item.reason = "";
        item.employerReasonCode = "";
        item.relievingRemarks = "";
      });
    setResignationInfo({
      ...resignationInfo,
      data: updatedAssociateDetails,
    });
  };

  const onReject = async (data: EmployeeList) => {
    try {
      setIsRejectLoading(true);
      await ResignationService.approveRejectResignation({
        empngOUInput: 0,
        resignationList: [
          {
            actionType: text.REJECT,
            employeeCodeInput: data.employeeCode,
            separationNumberInput: data.separationNumber,
            employerReasonCode: data.reason,
            approverComments: data.approverComments,
            noticePeriodWaiverFlagCode: data.noticePeriodWaiverFlagCode,
          },
        ],
      });
      getList({
        startDate: defaultClientStartDate,
        endDate: defaultClientLeaveEndDate,
        statusType: ALL_REQUEST_CODE,
      });
      getSummary({
        startDate: defaultClientStartDate,
        endDate: defaultClientLeaveEndDate,
        statusType: ALL_REQUEST_CODE,
      });
      setFilterValue(initialFilterValues);
      setSearchValue("");
    } catch {
    } finally {
      setIsRejectLoading(false);
    }
  };

  const onApprove = async (data: EmployeeList) => {
    try {
      setIsApproveLoading(true);
      const resignationList: PostResignationObj = {
        actionType: text.APPROVE,
        employeeCodeInput: data.employeeCode,
        separationNumberInput: data.separationNumber,
        employerReason: data.employerReason,
        employerReasonCode: data.reason,
        relievingRemarks: data.relievingRemarks,
        noticePeriodWaiverFlagCode: data.noticePeriodWaiverFlagCode,
        userDefinedCombo1Code: data.noDueConfirmation,
        userDefinedCombo1Desc: data.noDueConfirmation === "Y" ? "YES" : "NO",
        separationDate: data.separationDate,
        lastAvailableWorkingDate: data.lastAvailableWorkingDate,
      };
      await ResignationService.approveRejectResignation({
        resignationList: [resignationList],
        empngOUInput: 0,
      });
      getList({
        startDate: defaultClientStartDate,
        endDate: defaultClientLeaveEndDate,
        statusType: ALL_REQUEST_CODE,
      });
      getSummary({
        startDate: defaultClientStartDate,
        endDate: defaultClientLeaveEndDate,
        statusType: ALL_REQUEST_CODE,
      });
      setFilterValue(initialFilterValues);
      setSearchValue("");
    } catch {
    } finally {
      setIsApproveLoading(false);
    }
  };

  const getSearchedRecords = () => {
    const associateList = resignationInfo.data.data.employeeList || [];
    if (!searchValue) return associateList;
    return associateList.filter(item => {
      return item.employeeName
        ?.toLowerCase()
        .includes(searchValue.toLowerCase());
    });
  };

  const renderResignationCard = () => {
    return resignationInfo.data.data.employeeList.length !== 0 ? (
      getSearchedRecords().map((item, index) => {
        return (
          <Row className="mt-4" key={index}>
            <ResignationCard
              data={item}
              onApprove={onApprove}
              onChangeWavier={onChangeWavier}
              onChangeEmployerReason={onChangeEmployerReason}
              isApproveLoading={isApproveLoading}
              isRejectLoading={isRejectLoading}
              employerReason={
                resignationInfo.metadata.data.employerReasonsList || []
              }
              onChangeNoDueConfirmation={onChangeNoDueConfirmation}
              onChangeRemarks={onChangeRemarks}
              onChangeApproverComments={onChangeApproverComments}
              onReject={onReject}
              onModalClose={onModalClose}
            />
          </Row>
        );
      })
    ) : (
      <Row className="bg-white w-100">
        {resignationInfo.isLoading ? (
          <Row className="m-9">
            <Loader />
          </Row>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Row>
    );
  };

  if (!contextStore.allowedActivities[ModuleType.RESIGNATION])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;
  return (
    <Row className="p-6">
      <Row className="pb-4 ff-secondary" style={style.large}>
        <img src={resignationImage} className="pr-3 h-6" />
        {text.RESIGNATION}
      </Row>
      <SummaryCard isLoading={summaryData.isLoading} data={summaryData.data} />
      <Filter
        searchName={searchValue}
        filterValues={filterValue}
        setSearchText={setSearchValue}
        onChangeFilters={onFilterChange}
      />
      {resignationInfo.isLoading ? <Loader /> : renderResignationCard()}
    </Row>
  );
}
