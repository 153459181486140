import { UserActivity, Bpc, Component, FeaturePermissionRight, FeatureList } from "models/userActivity.data";
import { roleMappingCli, defaultAllowedActivities } from "./constants";

export const createRoleMapping = (userActivityResponse: UserActivity) => {
  const allowedActivities = { ...defaultAllowedActivities };
  userActivityResponse.data.bpc.forEach((item: Bpc) => {
    item.components.forEach((component: Component) => {
      component.activities.forEach(activity => {
        const activityType = roleMappingCli[activity.id.toLowerCase()];
        if (activityType) allowedActivities[activityType] = true;
      });
    });
  });
  return allowedActivities;
};

export const createRoleMappingForFeaturePermission = (userActivityResponse: FeaturePermissionRight) => {
  const allowedActivities = { ...defaultAllowedActivities };
  userActivityResponse.data.featureList.map((item: FeatureList) => {
    const activityType = roleMappingCli[item.cardCode || ""];
    if(activityType) allowedActivities[activityType] = true;
  })
  return allowedActivities;
};