import React, { useEffect, useState } from "react";
import { Row, Card } from "antd";
import DashboardCard from "components/DashboardCard/DashboardCard";
import {
  CardItem,
  timesheetData,
  employeeInternalCode,
} from "screens/dashboard/constants";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DashboardActions } from "screens/dashboard/actions";
import { getRequestData } from "models/timesheet.data";
import { useHistory } from "react-router-dom";
import { timesheetImage } from "AssetHelper";
import { SummaryResponse, SummaryDetails } from "models/dashboard.data";
import Loader from "./Loader";
import moment from "moment";
import { defaultClientStartDate, defaultClientEndDate, dateFormat } from "date";
import text from "text";

export default function TimesheetCards() {
  const [displayData] = useState(timesheetData);
  const [data, setData] = useState();

  const summary = useSelector((state: any) => {
    return state.dashboardStore;
  }, shallowEqual);

  const dispatch = useDispatch();

  const getTimesheetSummaryData = () => {
    const requestData: getRequestData = {
      internalRole: employeeInternalCode,
      endDate: defaultClientEndDate.format(dateFormat),
      startDate: defaultClientStartDate.format(dateFormat),
    };
    dispatch(DashboardActions.getTimesheetSummary.started(requestData));
  };

  const getTimesheetSummary = () => {
    const updatedTimesheetSummary: any = {};
    summary.dashboardSummary.timesheetSummary.data.map(
      (item: SummaryDetails, index: number) => {
        updatedTimesheetSummary[item.statusCode] = item.recordCount || 0;
      },
    );
    return updatedTimesheetSummary;
  };

  useEffect(() => {
    getTimesheetSummaryData();
  }, []);

  useEffect(() => {
    const updatedSummary = getTimesheetSummary();
    setData(updatedSummary);
  }, [summary.dashboardSummary.timesheetSummary]);

  const history = useHistory();

  const onClickCard = (value: string) => {
    history.push({
      pathname: "/home/attendance",
      search: `status=${value}`,
    });
  };

  const renderCards = (
    title: string,
    value: string,
    icon: string,
    color: string,
    index: number,
  ) => {
    return (
      <div
        className={`card-${title.replace(/ /g, "_")} w-100 w-md-auto`}
        onClick={() => onClickCard(value)}>
        <DashboardCard
          title={title}
          value={data && data[`${value}`]}
          icon={icon}
          valueColor={color}
          hoverable={true}
        />
      </div>
    );
  };

  return (
    <div className={`p-2 'Timesheet'`}>
      <Row className="p-2" type="flex" justify="start">
        <img src={timesheetImage} />
        <h4 className="p-2 my-1 ff-secondary">{text.ATTENDANCE}</h4>
      </Row>
      <Row type="flex" justify="start">
        {summary.timesheetLoading ? (
          <div className="w-50 py-3">
            <Loader />
          </div>
        ) : (
          displayData &&
          displayData.map((cardItem: CardItem, index: number) =>
            renderCards(
              cardItem.title,
              cardItem.value,
              cardItem.icon,
              cardItem.color,
              index,
            ),
          )
        )}
      </Row>
    </div>
  );
}
