export const getColor = (value: string) => {
  switch (value) {
    case "P":
      return "#FBB517";
    case "A":
      return "#02AF9B";
    case "S":
      return "#00b6ef";
    case "R":
      return "#ee2e22";
    default:
      return "red";
  }
};