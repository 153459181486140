import { notification } from "antd";
import { IShowNotification } from "./type";
import text from "text";

export const showNotification = (props: IShowNotification) => {
  const { message, description, type } = props;
  switch(type) {
    case text.SUCCESS:
      notification.success({
        message,
        description,
      });
      break;
    case text.ERROR:
      notification.error({
        message,
        description,
      });
      break;
  }
};
