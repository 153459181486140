import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import {
  QualificationDetails,
  getRequestProfileDetails,
} from "models/profile.data";

export class QualificationsService {
  public getQualifications(
    requestParams: getRequestProfileDetails,
  ): Promise<QualificationDetails> | QualificationDetails {
    const service: ApiService = new ApiService(EndPoints.profileQualifications);
    return service.get({
      dynamicQueryParams: [{ employeeCode: requestParams.employeeCode }],
    });
  }
}

const qualificationsService = new QualificationsService();
export default qualificationsService;
