import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { ContactsDetails, getRequestProfileDetails } from "models/profile.data";

export class ContactsService {
  public getContacts(
    requestParams: getRequestProfileDetails,
  ): Promise<ContactsDetails> | ContactsDetails {
    const service: ApiService = new ApiService(EndPoints.profileContacts);
    return service.get({
      dynamicQueryParams: [{ employeeCode: requestParams.employeeCode }],
    });
  }
}

const contactsService = new ContactsService();
export default contactsService;
