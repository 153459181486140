import { put, takeLatest } from "redux-saga/effects";

import { ReporteesList, Reportees } from "../../models/associate.data";
import { AssociateActions } from "./actions";
import AssociateService from "./service";

function* getReporteesListList(action: any) {
  const { payload } = action;
  try {
    const response: ReporteesList = yield AssociateService.getRepoteesList();
    let updatedRespone:Reportees[] =response.data.map((item,index)=> {item.id=index; return item})
    yield put(
      AssociateActions.getReporteesListList.done({
        params: payload,
        result: updatedRespone,
      }),
    );
  } catch (e) {
    yield put(
      AssociateActions.getReporteesListList.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}
function* AssociateManagementSaga() {
  yield takeLatest(
    AssociateActions.getReporteesListList.started,
    getReporteesListList,
  );
}

export default AssociateManagementSaga;
