import React, { useEffect, useState } from "react";
import { Row } from "antd";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import AssociateList from "../../components/AssociateList/AssociateList";
import {
  ExistingLineManagerList,
  LineManagerDetailsResponse,
  LineManagerMetadataResponse,
  Reportees,
} from "../../models/associate.data";
import { AssociateActions } from "screens/associate/actions";
import Loader from "components/Loader";
import AssociateHeader from "../../components/AssociateHeader/AssociateHeader";
import { ApplicationState } from "store/RootReducer";
import { DetailsType, MetadataTYpe } from "./Type";
import {
  ASSOCIATE_VIEW,
  CLMGR_INTERNAL_ROLE,
  defaultLineManagerMetadata,
  defaultLineManagersAssociateList,
} from "./constant";
import AssociateService from "./service";
import text from "text";
import { getAssociateList } from "./helper";
import { ModuleType } from "screens/home/store/home/constants";
import DownloadExcel from "components/DownloadExcel/DownloadExcel";

export default function Associate() {
  const dispatch = useDispatch();

  const [gridView, setGridView] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [lineManager, setLineManager] = useState(text.ALL);

  const [lineManagerMetadata, setLineMangerMetadata] = useState<MetadataTYpe>({
    isLoading: true,
    metadata: defaultLineManagerMetadata,
  });

  const [
    lineManagerAssociateDetails,
    setLineManagerAssociateDetails,
  ] = useState<DetailsType>({
    isLoading: false,
    details: defaultLineManagersAssociateList,
  });

  const reporteeList: Reportees[] = useSelector((state: any) => {
    return state.associateStore.repoteesListDetails;
  }, shallowEqual);

  const isLoading: boolean = useSelector((state: any) => {
    return state.associateStore.loading;
  }, shallowEqual);

  const { contextStore } = useSelector((state: ApplicationState) => state);

  const handleGridView = () => {
    setGridView(!gridView);
  };

  const getReporteeList = () => {
    if (isLineManagerRole) {
      const associateList: Reportees[] = getAssociateList(
        lineManagerAssociateDetails.details.data.employeeList || []
      );
      if (!searchText) return associateList;
      return associateList.filter(item => {
        return item.employeeName
          ?.toLowerCase()
          .includes(searchText.toLowerCase());
      });
    }
    if (!searchText) return reporteeList;
    return reporteeList.filter(item => {
      return item.employeeName.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  const getLineManagerAssociateList = async (value: string) => {
    let associateList: LineManagerDetailsResponse = defaultLineManagersAssociateList;
    try {
      setLineManagerAssociateDetails({
        isLoading: true,
        details: associateList,
      });
      associateList = await AssociateService.getLineMangersAssociateData({
        internalRole: CLMGR_INTERNAL_ROLE,
        existingLineManagerCode: value,
        customTextInput1: value === text.ALL ? ASSOCIATE_VIEW : "",
      });
      setLineManagerAssociateDetails({
        isLoading: false,
        details: associateList,
      });
    } catch (err) {
    } finally {
      setLineManagerAssociateDetails({
        isLoading: false,
        details: associateList,
      });
    }
  };

  const getLineManagerList = async () => {
    let metadata: LineManagerMetadataResponse = defaultLineManagerMetadata;
    try {
      setLineMangerMetadata({
        isLoading: true,
        metadata,
      });
      metadata = await AssociateService.getLineMangerMetadata({
        internalRole: CLMGR_INTERNAL_ROLE,
        customTextInput1: ASSOCIATE_VIEW,
      });
      setLineMangerMetadata({
        isLoading: false,
        metadata,
      });
    } catch (err) {
    } finally {
      setLineMangerMetadata({
        isLoading: false,
        metadata,
      });
    }
  };

  const onChangeLineManager = async (value: ExistingLineManagerList) => {
    setLineManager(value.existingLineManagerCode as string);
    getLineManagerAssociateList(value.existingLineManagerCode as string);
    setSearchText("");
  };

  const isLineManagerRole = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === CLMGR_INTERNAL_ROLE
  )
    ? true
    : false;

  useEffect(() => {
    isLineManagerRole && getLineManagerList();
    isLineManagerRole
      ? getLineManagerAssociateList(text.ALL)
      : dispatch(AssociateActions.getReporteesListList.started());
  }, []);

  if (!contextStore.allowedActivities[ModuleType.ASSOCIATE])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <Row className="p-6" id="associate">
      {(isLineManagerRole ? (
        lineManagerMetadata.isLoading || lineManagerAssociateDetails.isLoading
      ) : (
        isLoading
      )) ? (
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="min-vh-content">
          <Loader />
        </Row>
      ) : (
        <>
          <AssociateHeader
            gridView={gridView}
            setSearchText={setSearchText}
            handleGridView={handleGridView}
            isLineManager={isLineManagerRole}
            lineManagerList={
              lineManagerMetadata?.metadata.data.existingLineManagerList || []
            }
            onLineManagerChange={onChangeLineManager}
            selectedLineManager={lineManager}
          />
          <Row
            type="flex"
            justify="end">
            <div className="w-100 w-md-auto ">
              <DownloadExcel
                data={getReporteeList()}
                lable={text.DOWNLOAD_EXCEL}
                // @ts-ignore
                column={[{ title: 'Associate Code', key: 'employeeCode', dataIndex: 'employeeCode' },
                { title: 'Associate Name', key: 'employeeName', dataIndex: 'employeeName' },
                { title: 'Email Id', key: 'primaryEmailId', dataIndex: 'primaryEmailId' },
                { title: 'Phone', key: 'primaryMobileNumber', dataIndex: 'primaryMobileNumber' }]}
                isDisable={false}
              />
            </div>
          </Row>
          <AssociateList
            gridView={gridView}
            associateList={getReporteeList()}
          />
        </>
      )}
    </Row>
  );
}
