import React, { useEffect, useState } from "react";
import { Modal, Row, Table, Button } from "antd";
import { queryCache } from "react-query";
import { useOnboardingTableDetailsList } from "screens/analytics/hooks";
import { CLMGR_INTERNAL_ROLE } from "screens/analytics/constant";
import { column } from "screens/analytics/helper";
import { downloadExcel } from "components/DownloadExcel/helper";
import text from "text";
import { IAnalyticsModalProps } from "./type";
import Loader from "components/Loader";
import AnalyticsService from "screens/analytics/service";
import { OnboardingMetaDataDetails } from "models/analytics.data";
import { dataList } from "./helper";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { CLI_INTERNAL_ROLE } from "screens/bulkAttendance/constant";

export default function AnalyticsScreenModal(props: IAnalyticsModalProps) {
  const [isMetaDataLoading, setIsMetaDataLoading] = useState<boolean>(false);
  const [metaDataAnalytics, setMetaDataDetails] = useState<
    OnboardingMetaDataDetails
  >();

  const { contextStore } = useSelector((state: ApplicationState) => state);

  const isInternalRoleClient = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === CLI_INTERNAL_ROLE
  )
    ? true
    : false;
  
  const {
    isFetching: isTableDataLoading,
    data: tableData,
  } = useOnboardingTableDetailsList(props.tableEndpoint, {
    toDate: props.toDate,
    fromDate: props.fromDate,
    internalRole: isInternalRoleClient ? CLI_INTERNAL_ROLE :CLMGR_INTERNAL_ROLE,
    queryKey: props.tableEndpoint,
    clraStatusCodeSearch: props.statusCodeSearch,
  });

  const getMetaDataDetails = async (params: {
    internalRole: string;
    apiCode: string;
    callingFrom: string;
  }) => {
    try {
      setIsMetaDataLoading(true);
      const response = await AnalyticsService.getOnboardingMetaDataAnalyticsList(
        params
      );
      setMetaDataDetails(response);
    } catch (err) {
    } finally {
      setIsMetaDataLoading(false);
    }
  };

  const clearQueryCache = () => {
    queryCache.removeQueries(props.tableEndpoint);
  };

  useEffect(() => {
    getMetaDataDetails({
      internalRole: isInternalRoleClient ? CLI_INTERNAL_ROLE :CLMGR_INTERNAL_ROLE,
      apiCode: props.apiCode,
      callingFrom: "OTHER_API",
    });
    return clearQueryCache;
  }, []);

  const onDownload = () => {
    downloadExcel(
      column(metaDataAnalytics!),
      dataList(metaDataAnalytics!,tableData?.data!),
      "SummaryDetails"
    );
  };

  const renderTable = () => {
    return (
      <Table
        columns={column(metaDataAnalytics!)}
        dataSource={dataList(metaDataAnalytics!,tableData?.data!)}
        rowKey={""}
        scroll={{ x: true }}
        pagination={{
          pageSize: 7,
        }}
        loading={isTableDataLoading}
      />
    );
  };

  const renderFooter = () => (
    <>
      <Button key="cancel" onClick={props.onItemClick}>
        {text.CANCEL}
      </Button>
      <Button
        key="download"
        type="primary"
        onClick={onDownload}
        disabled={!dataList(metaDataAnalytics!,tableData?.data!).length}
      >
        {text.DOWNLOAD_MODAL_TABLE_DATA}
      </Button>,
    </>
  );

  return (
    <Row>
      <Modal
        width={1350}
        title={props.title}
        visible={props.modalVisible}
        onCancel={props.onItemClick}
        footer={renderFooter()}
        bodyStyle={{padding: 0}}
      >
        {(isMetaDataLoading || isTableDataLoading) ?
        <Row className="p-8">
           <Loader />
        </Row> : renderTable()}
      </Modal>
    </Row>
  );
}
