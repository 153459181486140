import React, { useState, useEffect } from "react";
import BulkAttendanceFilter from "../../components/BulkAttendanceFilter/BulkAttendanceFilter";
import BulkTimesheetService from "./service";
import {
  BulkAttendanceMetaData,
  BulkAttendanceList,
  PostAttendanceRequestBody,
} from "models/bulkAttendance.data";
import { Row, Modal } from "antd";
import Loader from "components/Loader";
import {
  MetaDataType,
  BulkAttendanceManipulatedMetaData,
  ProcessPeriodManipulatedList,
  AttendanceManipulatedListType,
  LineManagerManipulatedList,
  EmployeeManipulatedList,
  BulkTimesheetData,
  GetBulkAttendanceParams,
  TableData,
  AttendanceCycleList,
} from "./type";
import {
  manipulateData,
  getActionType,
  getPostRequestParams,
  getInputCategory,
  setCategory,
  getInputCode,
  getKeyOfActionType,
  getUpdatedValue,
} from "./helper";
import { style } from "styles/Fonts";
import {
  tabsMenu,
  actionTypeCodes,
  CLI_INTERNAL_ROLE,
  CLMGR_INTERNAL_ROLE,
} from "./constant";
import BulkTimesheetTab from "components/BulkTimesheetTab/BulkTimesheetTab";
import _ from "lodash";
import text from "text";
import { ModalType } from "components/Modal/type";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import { ApplicationState } from "store/RootReducer";
import { useSelector } from "react-redux";
import { bulkTimesheetImage } from "AssetHelper";
import { showNotification } from "components/ShowNotification/ShowNotification";
import { getDuplicateRecords } from "screens/otherInput/helper";
import { ModuleType } from "screens/home/store/home/constants";

export default function BulkAttendance() {
  const [attendanceList, setAttendanceList] = useState<
    Array<AttendanceCycleList>
  >();
  const [selectedRowKey, setSelectedRowKey] = useState<string[]>([]);
  const [timesheetMetaData, setTimesheetMetaData] = useState<MetaDataType>();
  const [bulkTimesheetData, setBulkTimesheetData] = useState<BulkTimesheetData>(
    {
      isLoading: false,
      details: {
        data: {
          clientCodeOutput: "",
          clientNameOutput: "",
          customDateOutput1: "",
          customDateOutput2: "",
          totalEmployeeCount: 0,
          dayValueList: [],
          empAttendanceList: [],
        },
        message: "",
      },
    }
  );

  const { contextStore } = useSelector((state: ApplicationState) => {
    return state;
  });

  const isInternalRoleClient = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === CLI_INTERNAL_ROLE
  )
    ? true
    : false;

  const [filteredValue, setFilteredValue] = useState<GetBulkAttendanceParams>({
    attendanceKey: 0,
    cycleFromDate: "",
    payrollCalendarCode: "",
    cycleToDate: "",
    payrollCode: "",
    payrollLocationCode: "",
    paysetCode: "",
    processPeriodCode: "",
    processPeriodNumber: "",
    attendanceType: "",
    lineManagerCode: "",
    employeeCodeKey: 0,
    employeeCode: "",
    associateList: [],
    internalRole: isInternalRoleClient
      ? CLI_INTERNAL_ROLE
      : CLMGR_INTERNAL_ROLE,
    payrollAcces: "",
  });
  const [appliedValue, setAppliedValue] = useState<GetBulkAttendanceParams>({
    attendanceKey: 0,
    cycleFromDate: "",
    payrollCalendarCode: "",
    cycleToDate: "",
    payrollCode: "",
    payrollLocationCode: "",
    paysetCode: "",
    processPeriodCode: "",
    processPeriodNumber: "",
    attendanceType: "",
    lineManagerCode: "",
    employeeCodeKey: 0,
    employeeCode: "",
    associateList: [],
    internalRole: isInternalRoleClient
      ? CLI_INTERNAL_ROLE
      : CLMGR_INTERNAL_ROLE,
    payrollAcces: "",
  });
  const [actionType, setActionType] = useState(actionTypeCodes.ALL);
  const [currentPage, setCurrentPage] = useState(1);
  const [savingTimesheet, setSavingTimesheet] = useState({
    saveLoading: false,
    submitLoading: false,
  });
  const [attendanceType, setAttendanceType] = useState("");
  const [selection, setSelection] = useState({
    isLineManagerSelected: isInternalRoleClient ? true : false,
    isPayrollPeriodSelected: false,
  });

  const [associateList, setAssociateList] = useState<
    Array<EmployeeManipulatedList>
  >();
  const [selectedAttendance, setSelectedAttendance] = useState<string>();

  useEffect(() => {
    getBulkTimesheetMetaData();
  }, []);

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const getBulkTimesheetMetaData = async () => {
    let response: BulkAttendanceMetaData;
    let data: BulkAttendanceManipulatedMetaData = {
      data: {
        clientCode: "",
        clientName: "",
        attendanceTypeList: [],
        inputTypeList: [],
        leaveSessionList: [],
        lineManagerList: [],
        processPeriodList: [],
        statusList: [],
        employeeManagerList: {},
      },
      message: "",
    };
    try {
      setTimesheetMetaData({
        isLoading: true,
        metaData: data,
      });
      response = await BulkTimesheetService.getBulkTimesheetMetaData({
        internalRole: isInternalRoleClient
          ? CLI_INTERNAL_ROLE
          : CLMGR_INTERNAL_ROLE,
      });
      data = manipulateData(response);
      setAssociateList(
        getAllEmployeeList(
          data.data.lineManagerList,
          data.data.employeeManagerList
        )
      );
      setTimesheetMetaData({
        isLoading: false,
        metaData: data,
      });
    } catch (err) {
    } finally {
      setTimesheetMetaData({
        isLoading: false,
        metaData: data,
      });
    }
  };

  const getBulkTimesheetData = async (statusCodeSearch: string) => {
    let response: BulkAttendanceList = {
      data: {
        clientCodeOutput: "",
        clientNameOutput: "",
        customDateOutput1: "",
        customDateOutput2: "",
        totalEmployeeCount: 0,
        dayValueList: [],
        empAttendanceList: [],
      },
      message: "",
    };
    try {
      setAttendanceType(filteredValue.attendanceType);
      setAppliedValue({ ...filteredValue });
      setSelectedRowKey([]);
      setBulkTimesheetData({
        isLoading: true,
        details: {
          data: bulkTimesheetData.details.data,
          message: "",
        },
      });
      setCurrentPage(1);
      response = await BulkTimesheetService.getBulkAttendanceList(
        filteredValue,
        statusCodeSearch,
        getCustomTextInput()
      );
      response.data.empAttendanceList = response.data.empAttendanceList.filter(
        item => {
          return !(
            item.payrollStatus === "Processed" &&
            item.statusCode === actionTypeCodes.DRAFT
          );
        }
      );
    } catch (err) {
    } finally {
      setSelectedRowKey([]);
      setBulkTimesheetData({
        isLoading: false,
        details: {
          data: response.data,
          message: response.message,
        },
      });
    }
  };

  const onChangePayrollPeriod = (
    payrollPeriod: ProcessPeriodManipulatedList
  ) => {
    setSelectedAttendance(undefined);
    setSelection({
      ...selection,
      isPayrollPeriodSelected: true,
    });
    setAttendanceList(payrollPeriod.attendanceList);
    setFilteredValue({
      ...filteredValue,
      cycleFromDate: "",
      cycleToDate: "",
      payrollCode: "",
      payrollLocationCode: "",
      paysetCode: "",
      payrollCalendarCode: payrollPeriod.payrollCalendarCode || "",
      processPeriodCode: payrollPeriod.processPeriodDescription || "",
      processPeriodNumber: payrollPeriod.processPeriodNumber?.toString() || "",
      associateList: [],
      employeeCode: "",
      lineManagerCode: "",
    });
  };

  const getAllEmployeeList = (
    lineManagerList: LineManagerManipulatedList[] | undefined,
    employeeManagerList:
      | { [key: string]: EmployeeManipulatedList[] }
      | undefined
  ) => {
    let employeeList: EmployeeManipulatedList[] = [];
    lineManagerList &&
      employeeManagerList &&
      lineManagerList.forEach(item => {
        employeeList = item.lineManagerCode
          ? employeeList.concat(employeeManagerList[item.lineManagerCode])
          : employeeList.concat(employeeManagerList[""]);
      });
    return employeeList;
  };

  const onChangeLineManager = (
    lineManager: LineManagerManipulatedList | undefined
  ) => {
    if (lineManager) {
      setSelection({
        ...selection,
        isLineManagerSelected: true,
      });
      setAssociateList(
        timesheetMetaData?.metaData.data.employeeManagerList[
          lineManager.lineManagerCode || ""
        ]
      );
      setFilteredValue({
        ...filteredValue,
        associateList: [],
        employeeCode: "",
        lineManagerCode: lineManager.lineManagerCode || "",
      });
    } else {
      setSelection({
        ...selection,
        isLineManagerSelected: false,
      });
      setAssociateList(
        getAllEmployeeList(
          timesheetMetaData?.metaData.data.lineManagerList,
          timesheetMetaData?.metaData.data.employeeManagerList
        )
      );
      setFilteredValue({
        ...filteredValue,
        associateList: [],
        employeeCode: "",
        lineManagerCode: "",
      });
    }
  };

  const onChangeAttendanceCycle = (
    attendanceCycle: AttendanceCycleList,
    key?: number
  ) => {
    setSelectedAttendance(attendanceCycle.attendanceCycle);
    setFilteredValue({
      ...filteredValue,
      attendanceKey: key || 0,
      payrollAcces: attendanceCycle.customACListTextOutput1 || "",
      cycleFromDate: attendanceCycle.cycleFromDate || "",
      cycleToDate: attendanceCycle.cycleToDate || "",
      payrollCode: attendanceCycle.payrollCode || "",
      payrollLocationCode: attendanceCycle.payrollLocationCode || "",
      paysetCode: attendanceCycle.paysetCode || "",
      associateList: [],
      employeeCode: "",
      lineManagerCode: "",
    });
  };

  const onChangeAttendanceType = (
    attendanceType: AttendanceManipulatedListType
  ) => {
    setFilteredValue({
      ...filteredValue,
      attendanceType: attendanceType.attendanceTypeCode || "",
    });
  };

  const onChangeAssociateList = (data: string[]) => {
    setFilteredValue({
      ...filteredValue,
      associateList: data,
      employeeCode: data.length ? "multi" : "",
    });
  };

  const getCustomTextInput = () => {
    if (filteredValue.associateList)
      return filteredValue.associateList.join(";");
    else return "";
  };

  const getDetailsDisabled = () => {
    let shouldDisable: boolean = false;
    if (
      filteredValue.payrollCode === "" ||
      filteredValue.cycleFromDate === "" ||
      filteredValue.attendanceType === ""
    ) {
      shouldDisable = true;
    }
    return shouldDisable;
  };

  const onTabChange = (activeKey: string) => {
    setBulkTimesheetData({
      isLoading: false,
      details: {
        data: {
          clientCodeOutput: "",
          clientNameOutput: "",
          customDateOutput1: "",
          customDateOutput2: "",
          totalEmployeeCount: 0,
          dayValueList: [],
          empAttendanceList: [],
        },
        message: "",
      },
    });
    setCurrentPage(1);
    setSelectedRowKey([]);
    const type = getActionType(activeKey);
    setActionType(type || actionTypeCodes.ALL);
    getBulkTimesheetData(type as string);
  };

  const onChangeRowSelection = (value: any, selectedRowsData: TableData[]) => {
    setSelectedRowKey(value);
  };

  const onChangeData = (
    dropdownValue: string,
    inputValue: string,
    key: number,
    row: TableData
  ) => {
    const newTimesheetList = _.cloneDeep(bulkTimesheetData.details);
    newTimesheetList.data.empAttendanceList
      .filter(item => item.slNo === row.slNo)
      .map((item, index) => {
        const record: any = item;
        record[`day${key}InputValue`] = dropdownValue;
        record[`day${key}InputCategory`] = getInputCategory(
          dropdownValue,
          timesheetMetaData?.metaData
        );
        filteredValue.attendanceType === "H" &&
          (record[`day${key}InputUnits`] = inputValue);
      });
    setBulkTimesheetData({
      isLoading: false,
      details: newTimesheetList,
    });
  };

  const onUpload = (value: any) => {
    if (value) {
      value.map((item: any, index: number) => {
        item.slNo === null && item.employeeCode === null
          ? value.splice(index)
          : [...new Array(31)].forEach((item1, index) => {
              item[`day${index + 1}InputValue`] = getInputCode(
                item[`day${index + 1}InputValue`] || "",
                timesheetMetaData?.metaData.data.inputTypeList || []
              );
            });
      });
      const updatedRecords = getUpdatedValue(value);
      if(updatedRecords.length){
      const employee = getDuplicateRecords(updatedRecords);
      if (employee.length) {
        showNotification({
          message: text.WARNING,
          description: `${employee} ${text.DUPLICATE_ENTRY_MESSAGE}`,
          type: text.ERROR,
        });
      } else {
        const newTimesheetList = _.cloneDeep(bulkTimesheetData.details);
        newTimesheetList.data.empAttendanceList.splice(0);
        newTimesheetList.data.empAttendanceList = value;
        setBulkTimesheetData({
          isLoading: false,
          details: newTimesheetList,
        });
        showNotification({
          message: text.SUCCESS,
          description: text.UPLOAD_EXCEL_MESSAGE,
          type: text.SUCCESS,
        });
      }
    }else{
      showNotification({
        message: text.WARNING,
        description: "Please enter valid file",
        type: text.ERROR,
      });
    }
    }
  };

  const onSubmit = async (action: string) => {
    setSavingTimesheet({
      saveLoading: action === text.SAVE ? true : false,
      submitLoading: action === text.SUBMIT ? true : false,
    });
    const employeeList = setCategory(
      getPostRequestParams(
        selectedRowKey,
        actionType,
        bulkTimesheetData.details
      ),
      timesheetMetaData?.metaData
    );
    const postAttendanceRequestParams: PostAttendanceRequestBody = {
      actionType: action,
      attTypeCodeSearch: appliedValue.attendanceType,
      cycleFromDate: appliedValue.cycleFromDate,
      cycleToDate: appliedValue.cycleToDate,
      internalRole: appliedValue.internalRole,
      payrollCalendarCode: appliedValue.payrollCalendarCode,
      payrollCode: appliedValue.payrollCode,
      payrollLocationCode: appliedValue.payrollLocationCode,
      paysetCode: appliedValue.paysetCode,
      processPeriodCode: appliedValue.processPeriodCode,
      processPeriodNumber: Number(appliedValue.processPeriodNumber),
      EmpAttendanceInputList: employeeList,
    };
    let response;
    try {
      response = await BulkTimesheetService.postAttendanceData(
        postAttendanceRequestParams
      );
      setSelectedRowKey([]);
      getBulkTimesheetData(actionType);
      response.data.EmpAttendanceOutputList[0].errorRemarks
        ? ModalPopUp({
            type: ModalType.error,
            title: text.ERROR,
            description: `${response.data.EmpAttendanceOutputList[0].errorRemarks}`,
          })
        : ModalPopUp({
            type: ModalType.success,
            title: text.SUCCESS,
            description:
              action === text.SUBMIT
                ? `${text.SUBMIT_SUCCESSFULLY}`
                : `${text.SAVE_SUCCESSFULLY}`,
          });
      !response.data.EmpAttendanceOutputList[0].errorRemarks &&
        getBulkTimesheetData(actionType);
    } catch (err) {
    } finally {
      setSavingTimesheet({
        saveLoading: false,
        submitLoading: false,
      });
    }
  };

  if (!contextStore.allowedActivities[ModuleType.BULK_TIMESHEET])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <Row>
      {timesheetMetaData?.isLoading ? (
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="min-vh-content">
          <Loader />
        </Row>
      ) : (
        <Row className="p-5">
          <Row style={style.large} className="ff-secondary">
            <img src={bulkTimesheetImage} className="pr-3 h-6" />
            <span>{text.BULK_TIMESHEET}</span>
          </Row>
          <BulkAttendanceFilter
            list={timesheetMetaData?.metaData?.data}
            onPayRollPeriodChange={onChangePayrollPeriod}
            attendanceCycleList={attendanceList || []}
            onAttendanceCycleChange={onChangeAttendanceCycle}
            onAttendanceTypeChange={onChangeAttendanceType}
            onLineManagerChange={onChangeLineManager}
            associateList={associateList || []}
            onAssociateChange={onChangeAssociateList}
            onGetDetails={onTabChange}
            disabledButton={getDetailsDisabled()}
            statusType={actionType}
            isLineManager={isInternalRoleClient}
            selectedAttendanceCycle={selectedAttendance}
            selection={selection}
            selectedValues={filteredValue}
          />
          <BulkTimesheetTab
            selectedTab={getKeyOfActionType(actionType)}
            tabMenu={tabsMenu}
            detailList={bulkTimesheetData.details.data}
            attendanceType={attendanceType}
            inputType={timesheetMetaData?.metaData.data.inputTypeList || []}
            listLoading={bulkTimesheetData.isLoading}
            onTabChange={onTabChange}
            actionType={actionType}
            selectedRowKey={selectedRowKey}
            onChangeSelectedRow={onChangeRowSelection}
            onChangeData={onChangeData}
            tabDisabled={
              bulkTimesheetData.details.data.dayValueList.length === 0
            }
            onUpload={onUpload}
            onSubmit={onSubmit}
            currentPage={currentPage}
            onPageChange={changePage}
            saveTimesheet={savingTimesheet}
            payrollAccess={appliedValue.payrollAcces}
          />
        </Row>
      )}
    </Row>
  );
}
