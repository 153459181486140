import { AuthHelper } from "helpers";
import React, { useEffect, useState } from "react";
import { EndPoints } from "store/model/ApiConfig.data";
import { ICustomPdf } from "./type";
import axios from "axios";
import Loader from "components/Loader";
import { Row } from "antd";

function CustomPdf(props: ICustomPdf) {
  const { file, relativePath } = props;
  const [showPdf, setShowPdf] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPdfUrl();
  }, []);

  const getPdfUrl = () => {
    const headerValues = {
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      Authorization: AuthHelper.getAccessToken(),
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
    };
    if (!AuthHelper.getAccessToken() || file === null) {
      return;
    }
    const uri = `${process.env.REACT_APP_BASE_URL}/${EndPoints.CORE_UTILITY_DOWNLOAD_FILE}?relativepath=${relativePath}&fileId=${file}`;
    setIsLoading(true);
    axios
      .get(uri, { headers: headerValues, responseType: "blob" })
      .then(res => {
        const blob = new Blob([res.data], {
          type: "application/pdf",
        });
        const objectURL = URL.createObjectURL(blob);
        setShowPdf(objectURL);
        setIsLoading(false);
      })
      .catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Row
      className="min-vh-content"
      type="flex"
      justify="center"
      align="middle"
    >
    {isLoading ? <Loader /> : (
    <embed
      src={showPdf}
      type="application/pdf"
      width="100%"
      height="400px"
    />)
    }
    </Row>
  );
}

export default CustomPdf;
