import { EndPoints } from "../store/model/ApiConfig.data";
import ApiService from "../service/RootApiService";
import { AuthRequest, AuthResponse } from "../store/model/Auth.data";

export class AuthService {
  public login(request: AuthRequest): Promise<AuthResponse> | AuthResponse {
    const service: ApiService = new ApiService(EndPoints.Auth);
    return service.post({}, request);
  }
}

const authService = new AuthService();
export default authService;
