import React, { useState, useEffect, Fragment } from "react";
import { Row, Table, Modal, Button, Empty, Tabs, Menu, Dropdown, Icon  } from "antd";
import {
  ExpenseFilterInterface,
  TableData,
  PopUP,
  BulkPopUP,
} from "screens/reimbursement/type";
import {
  AssociatesReimbursementDetails,
  ApproveExpense,
} from "models/reimbursement.data";
import { column, colors, manipulateData, summary } from "./Helper";
import OverviewCard from "components/OverviewCard";
import { style } from "styles/Fonts";
import { reimbursementImage } from "AssetHelper";
import { ReimbursementActions } from "./actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DashboardActions } from "screens/dashboard/actions";
import Loader from "components/Loader";
import { RejectPopUp } from "components/RejectPopUp";
import Filters from "components/Filters/Filters";
import { createPostObject } from "./objectFactory";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import ReimbursementService from "./ReimbursementService";
import { FilterInterface, FilterType } from "components/Filter/type";
import text from "text";
import { getRequestData } from "models/timesheet.data";
import { ModalType } from "components/Modal/type";
import { ModuleType } from "screens/home/store/home/constants";
import { requestDataForItem, ItemDetails } from "models/itemDetails.data";
import { getPDFURI } from "common/Utils.tsx/Download";
import { getRelativePathForAssociate } from "common/Utils.tsx/GetRelativePath";
import { defaultClientStartDate, defaultClientEndDate, dateFormat } from "date";
import { IFilterParams } from "components/Filters/Filters";
import moment from "moment";
import DownloadExcel from "components/DownloadExcel/DownloadExcel";

export default function Reimbursement(props: any) {
  const selectedStatus = props.location.search?.split("status=")[1];
  const [reason, setReason] = useState();
  const [filterValue, setFilterValues] = useState<IFilterParams>({
    startDate: undefined,
    endDate: undefined,
    status: selectedStatus,
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingModalMsg, setLoadingModalMsg] = useState("");
  const [actionTakenSate, setActionTaken] = useState<string>();
  const [selectedRowKey, setSelectedRowKey] = useState<number[]>([]);
  const [selectedRow, setSelectedRow] = useState<TableData[]>([]);
  const [limit, setLimit] = useState(5);
  const [popup, setPopup] = useState<PopUP>({
    visible: false,
    action: "",
    data: {},
  });

  const [bulkPopup, setBulkPopup] = useState<BulkPopUP>({
    visible: false,
    action: "",
    index: 0,
  });

  const { dashboardStore, reimbursementStore, contextStore } = useSelector(
    (state: any) => {
      return state;
    },
    shallowEqual
  );

  useEffect(() => {
    getAssociatesData({
      expenseDateFromSearch: defaultClientStartDate.format(dateFormat),
      expenseDateToSearch: defaultClientEndDate.format(dateFormat),
      statusCodeSearch: filterValue.status || "All",
    });
    getReimbursementSummaryData({
      startDate: defaultClientStartDate.format(dateFormat),
      endDate: defaultClientEndDate.format(dateFormat),
      internalRole: "APR",
    });
  }, []);

  const dispatch = useDispatch();

  const getAssociatesData = (filter: ExpenseFilterInterface) => {
    dispatch(
      ReimbursementActions.getAssociatesReimbursementDetails.started(filter)
    );
  };

  const getReimbursementSummaryData = (requestData: getRequestData) => {
    dispatch(DashboardActions.getReimbursementSummary.started(requestData));
  };

  const approveReimbrsement = async (payload: ApproveExpense[]) => {
    try {
      setLoadingModalMsg(
        `${payload[0].Actiontype.toUpperCase()} ${text.REIMBURSEMENT.toUpperCase()}...`
      );
      setShowLoadingModal(true);
      const response = await ReimbursementService.approveExpense(payload);
      onReimbursementSuccess(payload);
      // dataRender(filterValue as FilterInterface);
      onFilterChange(filterValue);
      setSelectedRowKey([]);
    } catch (err) {
      setShowLoadingModal(false);
    } finally {
      setShowLoadingModal(false);
    }
  };

  const getItemDetails = async (index: number) => {
    const reimbursementDetails: AssociatesReimbursementDetails =
      reimbursementStore.Approverdetails.data.ApproverDetails[index];

    let payload: requestDataForItem = {
      employeeCodeGet: reimbursementDetails.Employeecode as string,
      requestNumber: reimbursementDetails.Requestnumber as string,
    };
    try {
      setLoadingModalMsg(text.LOADING_DOWNLOAD);
      setShowLoadingModal(true);
      const response: ItemDetails = await ReimbursementService.getReimbursementDetails(
        payload
      );
      if (response.data.identifierList[0].Customfieldsgridoutput1) {
        await getPDFURI(
          response.data.identifierList[0].Customfieldsgridoutput1 as string,
          getRelativePathForAssociate(
            contextStore.relativePath.data,
            text.REIMBURSEMENT_RELATIVEPATH_KEY
          )
        ).then(response => {
          if (response !== 200) {
            ModalPopUp({
              type: ModalType.error,
              title: text.ALERT.toUpperCase(),
              description: text.NOT_ABLE_TO_DOWNLOAD,
            });
          }
        });
      } else {
        ModalPopUp({
          type: ModalType.error,
          title: text.ALERT.toUpperCase(),
          description: text.NO_FILE,
        });
      }
    } catch (err) {
    } finally {
      setShowLoadingModal(false);
    }
  };

  const onReimbursementSuccess = (apiRes: ApproveExpense[]) => {
    const { Actiontype } = apiRes[0];
    const reimburesmentOrReimbursements =
      apiRes.length > 1 ? text.REMBUIRSEMENTS : text.REIMBURSEMENT;
    setShowLoadingModal(false);
    if (Actiontype === text.REJECT) {
      ModalPopUp({
        type: ModalType.info,
        title: text.REJECTED,
        description: `${text.REJECTED_MESSAGE} ${reimburesmentOrReimbursements}`,
      });
    } else {
      ModalPopUp({
        type: ModalType.success,
        title: text.APPROVED,
        description: `${text.APPROVED_MESSAGE} ${reimburesmentOrReimbursements}`,
      });
    }
  };

  const getReimbursementData = () => {
    const { ApproverDetails } = reimbursementStore.Approverdetails.data;
    if (searchValue !== "") {
      return ApproverDetails.filter((item: AssociatesReimbursementDetails) => {
        const searchField =
          (item.Employeename &&
            typeof item.Employeename === "string" &&
            item.Employeename.toLowerCase()) ||
          "";
        return searchField.indexOf(searchValue.toLowerCase().trim()) > -1;
      });
    }
    if (
      ApproverDetails.length !== 0 &&
      ApproverDetails[0].Employeecode === null
    ) {
      return [];
    }
    return ApproverDetails;
  };

  const approveAction = (value: string, data: TableData) => {
    const postData = createPostObject(value, data, reason);
    approveReimbrsement(postData);
  };

  const rejectAction = (value: string, data: TableData, reason: string) => {
    const postData = createPostObject(value, data, reason);
    approveReimbrsement(postData);
    setPopup({ visible: false, action: "", data: 0 });
  };

  const rejectionMessage = (value: string, data: TableData) => {
    setPopup({ visible: true, action: value, data });
  };
  const onConfirmAction = (value: string, data: TableData) => {
    if (data !== undefined) {
      if (value === text.APPROVE) {
        approveAction(value, data);
      } else {
        rejectionMessage(value, data);
      }
    }
  };

  const actionTaken = (value: string, data: TableData) => {
    setActionTaken(value);
    const description = `You want to ${value} this expense`;
    ModalPopUp({
      type: ModalType.confirm,
      title: text.ARE_YOU_SURE,
      description,
      onSubmit: onConfirmAction,
      value,
      data,
    });
  };

  // const dataRender = (value: FilterInterface) => {
  //   setSelectedRowKey([]);
  //   setCurrentPage(1);
  //   setFilterValues(value);
  //   getAssociatesData({
  //     expenseDateFromSearch: value.startDate,
  //     expenseDateToSearch: value.endDate,
  //     statusCodeSearch: value.status,
  //   });
  //   const requestData = {
  //     internalRole: text.APR,
  //     endDate: value.endDate,
  //     startDate: value.startDate,
  //   };
  //   getReimbursementSummaryData(requestData);
  // };

  const getSearchValue = (value: string) => {
    setSelectedRowKey([]);
    setSearchValue(value);
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const OnChangeRowSelection = (value: any, selectedRowData: TableData[]) => {
    setSelectedRow(selectedRowData);
    setSelectedRowKey(value);
  };

  const rowSelection = () => {
    return {
      selectedRowKeys: selectedRowKey,
      onChange: OnChangeRowSelection,
      getCheckboxProps: (record: any) => ({
        disabled: record.status !== "Pending",
      }),
    };
  };

  const bulkApproveAction = (value: string, index: number) => {
    const postData: ApproveExpense[] = [];
    for (const row of selectedRow) {
      postData.push(createPostObject(value, row, reason)[0]);
    }
    approveReimbrsement(postData);
  };

  const bulkRejectAction = (value: string, index: number, reason: string) => {
    const postData: ApproveExpense[] = [];
    for (const row of selectedRow) {
      postData.push(createPostObject(value, row, reason)[0]);
    }
    approveReimbrsement(postData);
    setBulkPopup({ visible: false, action: "", index: 0 });
  };

  const bulkRejectionMessage = (value: string, index: number) => {
    setBulkPopup({ visible: true, action: value, index });
  };
  const onConfirmBulkAction = (value: string, index: number | undefined) => {
    if (index !== undefined) {
      if (value === text.APPROVE) {
        bulkApproveAction(value, index);
      } else {
        bulkRejectionMessage(value, index);
      }
    }
  };

  const bulkActionTaken = (value: string, index: number | undefined) => {
    setActionTaken(value);
    const description = `You want to ${value} this expense`;
    ModalPopUp({
      type: ModalType.confirm,
      title: text.ARE_YOU_SURE,
      description,
      onSubmit: onConfirmBulkAction,
      value,
      data: index,
    });
  };

  const getRequestData = (filters: IFilterParams) => {
    let filterStartDate = filters.startDate;
    let filterEndDate = filters.endDate;
    if (filterStartDate && filterEndDate) {
      if (filterStartDate > filterEndDate) {
        filterEndDate = filterStartDate.endOf("year");
      }
    } else if (filterStartDate && !filterEndDate) {
      if (filterStartDate > defaultClientEndDate) {
        filterEndDate = filterStartDate.endOf("year");
      }
    }
    if (filterStartDate) {
      filterStartDate = filterStartDate.startOf("day");
    }
    if (filterEndDate) {
      filterEndDate = filterEndDate.endOf("day");
    }
    const startDate = (filterStartDate || defaultClientStartDate).format(
      dateFormat
    );
    const endDate = (filterEndDate || defaultClientEndDate).format(dateFormat);
    return { startDate, endDate };
  };

  const onFilterChange = (filters: IFilterParams) => {
    const { startDate, endDate } = getRequestData(filters);
    getAssociatesData({
      expenseDateFromSearch: startDate,
      expenseDateToSearch: endDate,
      statusCodeSearch: filters.status || "All",
    });
    getReimbursementSummaryData({
      startDate,
      endDate,
      internalRole: "APR",
    });
    setSelectedRowKey([]);
    setCurrentPage(1);
    setSearchValue("");
    setFilterValues({
      startDate: filters.startDate,
      endDate: filters.endDate,
      status: filters.status || "All",
    });
  };
  const pageLimit = [5, 10, 15, 20, 25, 30];
  const limitList = (
    <Menu
      onClick={(e: any) => {
        setLimit(e.key)
      }}>
      {pageLimit.map((item: number) => (
        <Menu.Item key={item}>{item}</Menu.Item>
      ))}
    </Menu>
  );
  if (!contextStore.allowedActivities[ModuleType.REIMBURSEMENT])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <Fragment>
      <RejectPopUp
        visible={popup.visible}
        onReject={rejectAction}
        value={popup.action}
        data={popup.data}
        onCancel={() => {
          setPopup({ visible: false, action: "", data: {} });
        }}
      />
      <RejectPopUp
        visible={bulkPopup.visible}
        onReject={bulkRejectAction}
        value={bulkPopup.action}
        data={bulkPopup.index}
        onCancel={() => {
          setBulkPopup({ visible: false, action: "", index: 0 });
        }}
      />
      <Modal visible={showLoadingModal} footer={null} closable={false}>
        <Loader />
        <Row className="c-primary" type="flex" justify="center">
          {loadingModalMsg}
        </Row>
      </Modal>
      <Row className="p-6">
        <Row className="pb-2" id="heading">
          <img src={reimbursementImage} className="h-6" />
          &nbsp;
          <span style={style.large} className="ff-secondary">
            {" "}
            {text.REIMBURSEMENT}
          </span>
        </Row>
        <Row className="pb-0 pb-md-6" id="overviewCard">
          <OverviewCard
            overviewList={summary(
              dashboardStore.dashboardSummary.reimbursementSummary.data
            )}
            colorList={colors}
            isLoading={dashboardStore.loading}
          />
        </Row>
        <Filters
          filterValues={filterValue}
          searchName={searchValue}
          onChangeFilters={onFilterChange}
          onSearchText={setSearchValue}
          maxDate={moment()}
        />
        <Row id="table">
          {reimbursementStore.loading ? (
            <Loader />
          ) : reimbursementStore.Approverdetails.data.ApproverDetails ? (
            <div>
              {selectedRowKey.length > 0 && (
                <Row className="py-5 w-100" type="flex" justify="end">
                  <Button
                    className="bg-success c-white px-1 mx-2 w-10"
                    onClick={() => bulkActionTaken(text.APPROVE, 0)}>
                    {text.APPROVE}
                  </Button>
                  <Button
                    title={text.REJECT}
                    className="bg-primary c-white px-1 mx-2 w-10"
                    onClick={() => bulkActionTaken(text.REJECT, 0)}>
                    {text.REJECT}
                  </Button>
                </Row>
              )}
              <Row type="flex" align="middle" justify="end" className="pl-2 pb-2">
                <Row type="flex" justify="end">
                  <Row type="flex" justify="start">
                    <div className="mt-3">Records:</div>
                    <div className="w-100 w-md-auto m-2">
                      <Dropdown overlay={limitList}>
                        <Button>
                          {limit}
                          <Icon type="down" />
                        </Button>
                      </Dropdown>
                    </div>
                  </Row>
                  <div className="w-100 w-md-auto ">
                    <DownloadExcel
                    data={reimbursementStore.Approverdetails.data.ApproverDetails.length
                      ? manipulateData(getReimbursementData())
                      : [].filter((val,i)=>i<limit)}
                      lable={text.DOWNLOAD_EXCEL}
                      // @ts-ignore
                      column={column(actionTaken, getItemDetails)}
                      isDisable={false}
                    />
                  </div>
                </Row>
              </Row>
              <Table
                // @ts-ignore
                columns={column(actionTaken, getItemDetails)}
                dataSource={
                  reimbursementStore.Approverdetails.data.ApproverDetails.length
                    ? manipulateData(getReimbursementData())
                    : []
                }
                scroll={{ x: true }}
                pagination={{
                  pageSize: limit,
                  current: currentPage,
                  onChange: changePage,
                }}
                rowSelection={rowSelection()}
              />
            </div>
          ) : (
            <Row className="bg-white w-100">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          )}
        </Row>
      </Row>
    </Fragment>
  );
}
