import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import {
  AssociatesTimesheetDetails,
  getRequestData,
  Authrejectrequest,
  AuthrejectResponse,
} from "models/timesheet.data";
import { TimesheetFilterInterface } from "./type";

export class TimesheetService {
  public getAssociateTimesheet(
    params: TimesheetFilterInterface,
  ): Promise<AssociatesTimesheetDetails> | AssociatesTimesheetDetails {
    const service: ApiService = new ApiService(EndPoints.timesheet);
    return service.get({ dynamicQueryParams: [{ ...params }] });
  }
  public approveTimesheet(
    request: Authrejectrequest[],
  ): Promise<AssociatesTimesheetDetails> | AssociatesTimesheetDetails {
    const service: ApiService = new ApiService(EndPoints.approveTimesheet);
    return service.post({}, request);
  }
}

const timesheetService = new TimesheetService();
export default timesheetService;
