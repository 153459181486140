export const menu = [
  {
    key: "Attendance",
    value: "Attendance",
    title: "Attendance",
  },
  {
    key: "Leave",
    value: "Leave",
    title: "Leave",
  },
  {
    key: "Leave Balance",
    value: "Leave Balance",
    title: "Leave Balance",
  },
  {
    key: "Reimbursement",
    value: "Reimbursement",
    title: "Reimbursement",
  },
  {
    key: "Advance Salary",
    value: "Advance Salary",
    title: "Advance Salary",
  },
];

export const dateFormat = "YYYY-MM-DD hh:mm";
export const CLMGR_ROLE = "CLMGR";
export const CLI_ROLE = "CLI";
export const leaveBalanceDateFormat = "DD-MM-YYYY";