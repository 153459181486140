import { defaultClientLeaveEndDate, defaultClientLeaveStartDate } from "date";
import { IFilterParams } from "./type";

export const getFilterDate = (filters: IFilterParams) => {
  let filterStartDate = filters.startDate;
  let filterEndDate = filters.endDate;
  if (filterStartDate && filterEndDate) {
    if (filterStartDate > filterEndDate) {
      const tempFliterStartDate = filterStartDate.clone();
      filterEndDate = tempFliterStartDate.endOf("year");
    }
  } else if (filterStartDate && !filterEndDate) {
    if (filterStartDate > defaultClientLeaveEndDate) {
      const tempFliterStartDate = filterStartDate.clone();
      filterEndDate = tempFliterStartDate.endOf("year");
    }
  }
  if (filterStartDate) {
    filterStartDate = filterStartDate.startOf("day");
  }
  if (filterEndDate) {
    filterEndDate = filterEndDate.endOf("day");
  }
  const startDate = filterStartDate || defaultClientLeaveStartDate;
  const endDate = filterEndDate || defaultClientLeaveEndDate;
  return { startDate, endDate };
};