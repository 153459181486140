
import React, { useState } from "react";
import { Row, DatePicker, Button, Modal } from "antd";
import { ITransferRequestFilter } from "./type";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import { StatusList } from "models/transferRequest.data";
import text from "text";
import Search from "components/Search";
import moment, { Moment } from "moment";
import { blueAddImage } from "AssetHelper";

export default function TransferRequestFilter(props: ITransferRequestFilter) {

  const {
    data,
    filterValues,
    onChangeFilters,
    searchValue,
    setSearchText,
    currentTab,
  } = props;

  const onStatusChange = (value: StatusList) => {
    onChangeFilters({ ...props.filterValues, statusType: value.statusCode });
  };

  const onStartDateChange = (date: Moment | null, dateString: string) => {
    onChangeFilters({ ...filterValues, startDate: date});
  };

  const onEndDateChange = (date: Moment | null, dateString: string) => {
    onChangeFilters({ ...filterValues, endDate: date});
  };

  const isStartDateDisabled = (date: Moment | null) => {
    if (date && filterValues.endDate && date > filterValues.endDate) return true;
    return false;
  };

  const isEndDateDisabled = (date: Moment | null) => {
    if (date && filterValues.startDate && date < filterValues.startDate) return true;
    return false;
  };

  const [newDesignationModal, setnewDesignationModal] = useState(false)
  return (
    <Row type="flex" className="my-2" justify="space-between" align="middle">
        <Search
          placeholder={text.SEARCH}
          data={[]}
          width={200}
          getSearchedValue={setSearchText}
          disabled={!(currentTab === text.REQUESTED_TRANSFERS)}
          value={searchValue}
        />
        <SearchableDropdown<StatusList>
            label={text.STATUS_TYPE}
            list={data}
            onSelect={(e) => onStatusChange(e as StatusList)}
            optionLableExtractor={(item) => item.statusDescription}
            optionKeyExtractor={(item) => item.statusCode}
            value={filterValues.statusType}
            isDisabled={!(currentTab === text.REQUESTED_TRANSFERS)}
        />
        <Row type="flex" align="middle">
            <div className="w-100 w-md-auto mr-2"> {text.START_DATE} :</div>
            <DatePicker
                value={
                    filterValues.startDate
                    ? moment(filterValues.startDate)
                    : undefined
                }
                onChange={onStartDateChange}
                disabledDate={isStartDateDisabled}
                disabled={!(currentTab === text.REQUESTED_TRANSFERS)}
                format={"DD-MM-YYYY"}
            />
        </Row>
        <Row type="flex" align="middle">
            <div className="w-100 w-md-auto mr-2">{text.END_DATE} :</div>
            <DatePicker
                value={
                filterValues.endDate
                    ? moment(filterValues.endDate)
                    : undefined
                }
                onChange={onEndDateChange}
                disabledDate={isEndDateDisabled}
                disabled={!filterValues.startDate || !(currentTab === text.REQUESTED_TRANSFERS)}
                format={"DD-MM-YYYY"}
            />
        </Row>
        <Button style={{ border: "1px solid #00b6ef" }} onClick={() => { setnewDesignationModal(true) }}>
        <img src={blueAddImage} className="h-3" style={{ paddingBottom: "2px" }} />
        {text.ADD_NEW_DESIGNATION}
        </Button>
        <Modal
          width={300}
          title={'Contact Support'}
          visible={newDesignationModal}
          onCancel={() =>setnewDesignationModal(false)}
          footer={""}
        >
          <div style={{height:"100px"}}>For adding a New Designation please share the details with us at: 7625003333 DSD.india@adecco.com</div>
        </Modal>
     
    </Row>
  );
}
