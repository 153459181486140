import text from "text";
import { ITabMenu } from "./type";

export const CREATE = "CREATE";
export const EDIT = "EDIT";
export const EMPLOYEE_CODE_SEARCH = "MULTI";
export const CLI_INTERNAL_ROLE = "CLI";
export const CLMGR_INTERNAL_ROLE = "CLMGR";
export const NOT_ALLOWED_TO_SUBMIT = "NALWD";

export const actionTypeCodes = {
  PROCESSED: "PRO",
  INVOICED: "INV",
  REQUESTED: "REQ",
  ADD_NEW: "ANEW",
};

export const tabsMenu: ITabMenu[] = [
  {
    key: "1",
    tabName: text.ADD_NEW,
    isDisabled: false,
    dataKey: text.NEW_COUNT,
  },
  {
    key: "2",
    tabName: text.REQUESTED,
    isDisabled: false,
    dataKey: text.REQUESTED_COUNT,
  },
  {
    key: "3",
    tabName: text.PROCESSED,
    isDisabled: false,
    dataKey: text.PROCESSED_COUNT,
  },
  {
    key: "4",
    tabName: text.INVOICED,
    isDisabled: false,
    dataKey: text.INVOICED_COUNT,
  },
];
