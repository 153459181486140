export const statusList = [
    {
        statusCode: "ALRQ",
        statusDesc: "All Request",
    },
    {
        statusCode: "ACTD",
        statusDesc: "Action Taken",
    },
    {
        statusCode: "PEND",
        statusDesc: "Pending",
    },
];