import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "../src/fonts/houschkarounded-bold-webfont.ttf";
import "../src/fonts/houschkarounded-demibold-webfont.ttf";
import "../src/fonts/HouschkaRounded.ttf";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { TourProvider,useTour } from '@reactour/tour';

const steps = [ { selector: '.myDashboard', content: "View overall associate details for the month", },
{ selector: '.myTaskboard', content: "Take action on the requests received from associates", },
{ selector: '.leave', content: "Take/View Action on the leave requests received from associates", },
{ selector: '.attendance', content: "Take/View Action on the attendance requests received from associates", },
{ selector: '.reimbursement', content: "Take/View Action on the reimbursement requests received from associates", },
{ selector: '.bulkTimesheet', content: "Provide monthly timesheet for the associate", },
{ selector: '.otherInput', content: "Provide non-recurring inputs for payroll", },
{ selector: '.netPay', content: "Take/View action on the net pay received for the month", },
{ selector: '.invoice', content: "View/Download invoices", },
{ selector: '.lineManager', content: "Change Line manager of the associate", },
{ selector: '.associate', content: "View associates details", },
{ selector: '.transfer', content: "Raise transfer request to Adecco", },
{ selector: '.resignation', content: "Take/View Action on the resignation requests received from associates", },
{ selector: '.advanceSalary', content: "Take/View Action on the Advance requests received from associates", },
{ selector: '.profile', content: "View Profile and perform login/logout", },];

ReactDOM.render( <TourProvider steps={steps}><App /></TourProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
