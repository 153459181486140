import {
  AssociatesReimbursementDetails,
  ApproveExpense,
} from "models/reimbursement.data";
import { TableData } from "./type";

export function createPostObject(
  value: string,
  data: TableData,
  reason?: string,
) {
  const requestData: ApproveExpense[] = [
    {
      Actiontype: value,
      Requesteremployeecode: data.Employeecode as string,
      Requestnumber: data.Requestnumber as string,
      Rejectionreason: reason,
    },
  ];
  return requestData;
}
