import actionCreatorFactory from "typescript-fsa";
import { AuthRequest, AuthResponse } from "../model/Auth.data";

const actionCreator = actionCreatorFactory("AUTH");

export enum AuthActionTypes {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",
}

export interface AuthAction {
  type: AuthActionTypes;
  payload: AuthRequest | AuthResponse | Error;
}

export class AuthActions {
  public static loginRequest(request: AuthRequest): AuthAction {
    return { type: AuthActionTypes.LOGIN_REQUEST, payload: request };
  }

  public static loginSuccess(response: AuthResponse): AuthAction {
    return { type: AuthActionTypes.LOGIN_SUCCESS, payload: response };
  }

  public static loginError(error: Error): AuthAction {
    return { type: AuthActionTypes.LOGIN_ERROR, payload: error };
  }

  public static checkUserValidity = actionCreator.async<
    undefined,
    undefined,
    Error
  >("VALID_USER");

  public static logoutUserAction = actionCreator.async<
    undefined,
    undefined,
    Error
  >("LOGOUT_USER");
}
