import React from "react";
import { IBulkAttendanceFilterProps } from "./type";
import { Row, Col, Button } from "antd";
import {
  GET_DETAILS_BUTTON_COLOR,
  GET_DETAILS_DISABLED_BUTTON,
} from "./constants";
import text from "text";
import _ from "lodash";
import { AttendanceCycleList, AttendanceManipulatedListType, EmployeeManipulatedList, LineManagerManipulatedList, ProcessPeriodManipulatedList } from "screens/bulkAttendance/type";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";

export default function BulkAttendanceFilter(
  props: IBulkAttendanceFilterProps
) {
  const {
    list,
    onPayRollPeriodChange,
    attendanceCycleList,
    onAttendanceCycleChange,
    onAttendanceTypeChange,
    onLineManagerChange,
    associateList,
    onAssociateChange,
    onGetDetails,
    disabledButton,
    statusType,
    isLineManager,
    selectedAttendanceCycle,
    selection,
    selectedValues
  } = props;

  function getList<T>(objectList: T[], key: string) {
    /**
     * takes object list, ex => objectList = [{name: "name1"}, {name: "name2"}]
     * takes key, ex => key = "name"
     * returns list, ex => returnValue = ["name1", "name2"]
     */
    const returnList: string[] = [];
    //@ts-ignore
    objectList.forEach((item: T) => returnList.push(item[key] ? item[key] : item));
    return returnList;
  }
  return (
    <Row type="flex" className="my-2">
     <Col  md={20} sm={20} xs={20}>
        <Col className="mr-3 my-2" md={7} sm={20} xs={20}>
          <SearchableDropdown<ProcessPeriodManipulatedList>
            label={text.PAYROLL_PERIOD}
            list={list?.processPeriodList ? list?.processPeriodList : []}
            onSelect={(i) => onPayRollPeriodChange(i as ProcessPeriodManipulatedList)}
            optionLableExtractor={(item: ProcessPeriodManipulatedList) => item.processPeriodDescription}
            optionKeyExtractor={(item: ProcessPeriodManipulatedList) => item.processPeriodCode}
            value={selectedValues.processPeriodCode}
            labelclassName="w-100 w-md-auto my-2 mr-8"
          />
        </Col>
        <Col className="mr-3 my-2"  md={7} sm={20} xs={20}>
          <SearchableDropdown<AttendanceCycleList>
            label={text.ATTENDANCE_CYCLE}
            list={attendanceCycleList ? attendanceCycleList : []}
            onSelect={(i) => onAttendanceCycleChange(i as AttendanceCycleList)}
            optionKeyExtractor={(item: AttendanceCycleList) => item.paysetCode}
            value={selectedAttendanceCycle as string}
            optionLableExtractor={(item: AttendanceCycleList) => item.attendanceCycle}
            isDisabled={!selection.isPayrollPeriodSelected}
            labelclassName="w-100 w-md-auto my-2 mr-8"
          />
        </Col>
        <Col className="mr-3 my-2" md={7} sm={20} xs={20}>
          <SearchableDropdown<AttendanceManipulatedListType>
            label={text.ATTENDANCE_TYPE}
            list={list?.attendanceTypeList ? list?.attendanceTypeList : []}
            onSelect={(i) => onAttendanceTypeChange(i as AttendanceManipulatedListType)}
            optionKeyExtractor={(item: AttendanceManipulatedListType) => item.attendanceTypeCode}
            optionLableExtractor={(item: AttendanceManipulatedListType) => item.attendanceTypeDesc}
            value={selectedValues.attendanceType}
            labelclassName="w-100 w-md-auto my-2 mr-8"
          />
        </Col>
        {!isLineManager && (
          <Col className="mr-3 my-2" md={7} sm={20} xs={20}>
            <SearchableDropdown<LineManagerManipulatedList>
              label={text.LINE_MANAGER}
              list={list?.lineManagerList ? list?.lineManagerList : []}
              onSelect={(i) => onLineManagerChange(i as LineManagerManipulatedList)}
              optionKeyExtractor={(item: LineManagerManipulatedList) => item.lineManagerCode}
              optionLableExtractor={(item: LineManagerManipulatedList) => item.lineManagerName}
              value={selectedValues.lineManagerCode}
              allowClear
              labelclassName="w-100 w-md-auto my-2 mr-8"
              isDisabled={!selection.isPayrollPeriodSelected}
            />
          </Col>
        )}
        <Col className="mr-3 my-2" md={7} sm={20} xs={20} >
          <SearchableDropdown<EmployeeManipulatedList>
            label={text.ASSOCIATE}
            labelclassName="w-100 w-md-auto my-2 mr-11"
            list={associateList ? associateList : []}
            onSelect={(i) => onAssociateChange(getList<EmployeeManipulatedList>(i as EmployeeManipulatedList[], "employeeCode"))}
            optionKeyExtractor={(item: EmployeeManipulatedList) => item.employeeCode}
            optionLableExtractor={(item: EmployeeManipulatedList) => item.employeeName}
            isMultiple
            value={getList(selectedValues.associateList, "employeeCode")}
            allowClear
            isDisabled={!selection.isLineManagerSelected}
            
          />
        </Col>
      </Col>
      <Col  md={4} sm={20} xs={20}>
        <Button
         type="primary"
          className="c-white"
          onClick={() => {
            onGetDetails("1");
        }}
          disabled={disabledButton}
          style={{
            backgroundColor: disabledButton
              ? GET_DETAILS_DISABLED_BUTTON
              : GET_DETAILS_BUTTON_COLOR,
            borderColor: disabledButton
              ? GET_DETAILS_DISABLED_BUTTON
              : GET_DETAILS_BUTTON_COLOR,
              marginTop:"44.9px",
             }}>
         {!selection.isLineManagerSelected?text.GET_DETAILS:'Apply Filter'}   
          {/* {text.GET_DETAILS} */}
        </Button>
      </Col>
    </Row>
  );
}
