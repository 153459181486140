import call from "./assets/Images/call.svg";
import phone from "./assets/Images/phone.svg";
import home from "./assets/Images/home.svg";
import leave from "./assets/Images/leave.svg";
import timesheet from "./assets/Images/timesheet.svg";
import reimbursement from "./assets/Images/reimbursement.svg";
import associate from "./assets/Images/associates.svg";
import raiseTicket from "./assets/Images/raiseATicket.svg";
import mandates from "./assets/Images/mandates.svg";
import transfer from "./assets/Images/transfer.svg";
import mail from "./assets/Images/mail.svg";
import invoice from "./assets/Images/invoice.svg";
import actionTaken from "./assets/Images/action_taken.svg";
import pending from "./assets/Images/pending.svg";
import reject from "./assets/Images/reject.svg";
import exception from "./assets/Images/exception.svg";
import gridView from "./assets/Images/grid_view.svg";
import filter from "./assets/Images/filter.svg";
import dashboardTotal from "./assets/Images/dashboard_total.svg";
import dashboardTotalActions from "./assets/Images/dashboard_total_actions.svg";
import dashboardException from "./assets/Images/dashboard_exception.svg";
import dashboardPending from "./assets/Images/dashboard_pending.svg";
import dashboardApprove from "./assets/Images/dashboard_approve.svg";
import dashboardTaken from "./assets/Images/dashboard_taken.svg";
import dashboardReject from "./assets/Images/dashboardReject.svg";
import homePageBg from "./assets/Images/homePageBg.png";
import adeccoLogoImage from "./assets/Images/adeccoLogo.svg";
import homePageFullScreenBG from "./assets/Images/homePageFullScreenBG.png";
import downloadFileImage from "./assets/Images/download_file.svg";
import uploadFileImage from "./assets/Images/upload_file.svg";
import otherInputIcon from "./assets/Images/otherInputIcon.svg";
import uploadIcon from "./assets/Images/uploadIcon.svg";
import bulkTimesheetIcon from "./assets/Images/bulkTimesheetIcon.svg";
import pdfImage from "./assets/Images/pdfImage.svg";
import lineManagerIcon from "./assets/Images/lineManager.svg";
import addIcon from "./assets/Images/addIcon.svg";
import crossIcon from "./assets/Images/crossIcon.svg";
import analyticsBGVIcon from "./assets/Images/analytics_bgv.svg";
import analyticsOnboardingInitiatedIcon from "./assets/Images/analyticsOnboardingInitiated.svg";
import analyticsOnboardingCompletedIcon from "./assets/Images/analyticsOnboardingCompleted.svg";
import analyticsTotalExpectedToJoinIcon from "./assets/Images/analyticsTotalExpectedToJoin.svg";
import analyticsJoinedIcon from "./assets/Images/analytics_joined.svg";
import netPayIcon from "./assets/Images/netpay.svg";
import refreshIcon from "./assets/Images/refresh.svg";
import redDotIcon from "./assets/Images/red_dot.svg";
import whiteDotIcon from "./assets/Images/white_dot.svg";
import previewIcon from "./assets/Images/previewImage.svg";
import myDashboardIcon from "./assets/Images/myDashboard.svg";
import contractExpiredIcon from "./assets/Images/contractExpired.svg";
import CLRAIcon from "./assets/Images/clra.svg";
import addedIcon from "./assets/Images/added.svg";
import attendanceIcon from "./assets/Images/attendanceIcon.svg";
import netpayIcon from "./assets/Images/netpayIcon.svg";
import payrollProcessedIcon from "./assets/Images/payroll_processed.svg";
import resignedIcon from "./assets/Images/resigned.svg";
import invoiceIcon from "./assets/Images/invoiceIcon.svg";
import resignationIcon from "./assets/Images/resignation.svg";
import transferRequestIcon from "./assets/Images/transferRequest.svg";
import blueAddIcon from "./assets/Images/blueAddIcon.svg";
import salaryIcon from "./assets/Images/salary.svg";
import downArrow from "./assets/Images/downArrow.svg";
import upArrow from "./assets/Images/upArrow.svg";
import watsapp from "./assets/Images/whatsapp.svg";
import whatsapp from "./assets/Images/icons8-whatsapp (2).svg";

export const callImage = call;
export const phoneImage = phone;
export const homeImage = home;
export const leaveImage = leave;
export const timesheetImage = timesheet;
export const reimbursementImage = reimbursement;
export const associateImage = associate;
export const raiseTicketImage = raiseTicket;
export const mandatesImage = mandates;
export const transferImage = transfer;
export const mailImage = mail;
export const invoceImage = invoice;
export const actionTakenImage = actionTaken;
export const pendingImage = pending;
export const rejectImage = reject;
export const exceptionImage = exception;
export const gridViewImage = gridView;
export const filterImage = filter;
export const dashboardTotalImage = dashboardTotal;
export const dashboardTotalActionsImage = dashboardTotalActions;
export const dashboardExceptionImage = dashboardException;
export const dashboardPendingImage = dashboardPending;
export const dashboardApproveImage = dashboardApprove;
export const dashboardTakenImage = dashboardTaken;
export const dashboardRejectImage = dashboardReject;
export const homeBackground = homePageBg;
export const adeccoLogo = adeccoLogoImage;
export const homeScreenImage = homePageFullScreenBG;
export const downloadFile = downloadFileImage;
export const downloadFileIcon = downloadFileImage;
export const uploadFile = uploadFileImage;
export const otherInputImage = otherInputIcon;
export const uploadFileIcon = uploadIcon;
export const bulkTimesheetImage = bulkTimesheetIcon;
export const PDFImage = pdfImage;
export const lineManagerImage = lineManagerIcon;
export const addImage = addIcon;
export const crossImage = crossIcon;
export const analyticsBGVImage = analyticsBGVIcon;
export const analyticsOnboardingInitiatedImage = analyticsOnboardingInitiatedIcon;
export const analyticsOnboardingCompletedImage = analyticsOnboardingCompletedIcon;
export const analyticsTotalExpectedToJoinImage = analyticsTotalExpectedToJoinIcon;
export const analyticsJoinedImage = analyticsJoinedIcon;
export const netPayImage = netPayIcon;
export const refreshImage = refreshIcon;
export const redDotImage = redDotIcon;
export const whiteDotImage = whiteDotIcon;
export const previewImage = previewIcon;
export const myDashboardImage = myDashboardIcon;
export const analyticsContractExpiredImage = contractExpiredIcon;
export const analyticsCLRAImage = CLRAIcon;
export const attendanceImage = attendanceIcon;
export const invoiceImage = invoiceIcon;
export const payrollProcessedImage = payrollProcessedIcon;
export const netpayImage = netpayIcon;
export const resignedImage = resignedIcon;
export const addedImage = addedIcon;
export const blueAddImage = blueAddIcon;
export const resignationImage = resignationIcon;
export const transferRequestImage = transferRequestIcon;
export const salaryImage = salaryIcon;
export const downArrowIcon = downArrow;
export const upArrowIcon = upArrow;
export const watsappIcon = watsapp;
export const whatsappIcon = whatsapp;