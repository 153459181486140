import { SummaryCardObj } from "components/TransferSummaryCard/type";
import { OfferProcessAplicableList, SummaryList } from "models/transferRequest.data";
import text from "text";
import { ITabMenu, TableData } from "./type";

export const defaultMetadataValue = {
  data: {
    clientCodeOutput: "",
    clientNameOutput: "",
    customDateOutput1: "",
    customDateOutput2: "",
    customIntegerOutput1: 0,
    customIntegerOutput2: 0,
    customNumericOutput1: 0,
    customNumericOutput2: 0,
    customTextOutput1: "",
    customTextOutput2: "",
    customTextOutput3: "",
    customTextOutput4: "",
    batchIDList: [],
    bloodGroupList: [],
    compensationUOMList: [],
    contactTypelist: [],
    countryList: [],
    currencyList: [],
    departmentList: [],
    employeeTypeList: [],
    flagList: [],
    genderList: [],
    identificationTypeList: [],
    institutionList: [],
    jobList: [],
    maritalStatusList: [],
    nationalityList: [],
    onboardingDocumentList: [],
    pfApplicabilityList: [],
    positionList: [],
    qualificationList: [],
    skillTypeList: [],
    staffingTypeList: [],
    statusList: [],
    storeBranchList: [],
    titleList: [],
  },
  message: "",
};

export const defaultDetailList = {
  data: {
    clientCodeOutput: "",
    clientNameOutput: "",
    customDateOutput1: "",
    customDateOutput2: "",
    customIntegerOutput1: 0,
    customIntegerOutput2: 0,
    customNumericOutput1: 0,
    customNumericOutput2: 0,
    customTextOutput1: "",
    customTextOutput2: "",
    customTextOutput3: "",
    customTextOutput4: "",
    candidateList: [],
  },
  message: "",
};

export const initialFilterValues = {
  statusType: "",
  startDate: null,
  endDate: null,
};

export const summaryValue: SummaryCardObj[] = [
  {
    title: text.NEW_PROFILE,
    value: 0,
    color: "warning",
  },
  {
    title: text.TRANSFER_ONBOARDING_INITIATED,
    value: 0,
    color: "info",
  },
  {
    title: text.TRANSFER_ONBOARDING_COMPLETED,
    value: 0,
    color: "black",
  },
  {
    title: text.JOINED,
    value: 0,
    color: "success",
  },
];

export const getSummaryValue = (data: SummaryList[]) => {
  if (data.length) {
    summaryValue[0].value = data.filter(
      element => element.statusCode === "NP",
    )[0].recordCount;
    summaryValue[1].value = data.filter(
      element => element.statusCode === "OI",
    )[0].recordCount;
    summaryValue[2].value = data.filter(
      element => element.statusCode === "QCC",
    )[0].recordCount;
    summaryValue[3].value = data.filter(
      element => element.statusCode === "JOIND",
    )[0].recordCount;
  }
  return summaryValue;
};

export const tabsMenu: ITabMenu[] = [
  {
    key: "1",
    tabName: text.RAISE_NEW_REQUEST,
  },
  {
    key: "2",
    tabName: text.NEW_PROFILE,
  },
  {
    key: "3",
    tabName: text.REQUESTED_TRANSFERS,
  },
];

export const defaultTableData: TableData = {
  id: 0,
  applicantId: "",
  firstName: "",
  lastName: "",
  position: "",
  phone: "",
  address: "",
  aadharNo: "",
  dateOfBirth: "",
  title: "",
  email: "",
  gender: "",
  storeBranch: "",
  country: "",
  state: "",
  city: "",
  pfApplicability: "",
  skillType: "",
  sBu: "",
  expectedDOJ: "",
  requiredBy: "",
  compensationUOM: "",
  clientCompensation: 0,
  clientCompensationCurrency: "",
  offerClientApproval: "",
  offerProcessApplicable: "",
  contactType: "",
  department: "",
  contractStartDate: "",
  contractEndDate: "",
  statusCode: "",
  batchId: "",
  RFHId: "",
};

export const offerProcessApplicable: OfferProcessAplicableList[] = [
  {
    code: "YES",
    value: "Yes",
  },
  {
    code: "NO",
    value: "No",
  },
];

export const CLMGR_INTERNAL_ROLE = "CLMGR";