import { reducerWithInitialState } from "typescript-fsa-reducers";

import { TimesheetActions } from "./actions";
import {
  AssociateTimesheetDetailsStateType,
  AssociatesTimesheetDetails,
  AuthrejectResponse,
  ErrorMsg,
} from "../../models/timesheet.data";

const initialState: AssociateTimesheetDetailsStateType = {
  loading: false,
  error: null,
  associatesTimsheetDetails: {
    data: [],
    message: "",
  },
  approveTimesheet: {
    data: [],
    message: "",
  },
};

const timesheetReducer = reducerWithInitialState(initialState)
  .cases(
    [TimesheetActions.getAssociatesTimesheetDetails.started],
    (state: AssociateTimesheetDetailsStateType) => ({
      ...state,
      error: null,
      loading: true,
    }),
  )
  .case(
    TimesheetActions.getAssociatesTimesheetDetails.done,
    (
      state: AssociateTimesheetDetailsStateType,
      payload: { result: AssociatesTimesheetDetails },
    ) => ({
      ...state,
      error: null,
      loading: false,
      associatesTimsheetDetails: {
        data: payload.result.data,
        message: payload.result.message,
      },
    }),
  )
  .case(
    TimesheetActions.getAssociatesTimesheetDetails.failed,
    (state: AssociateTimesheetDetailsStateType, payload: any) => ({
      ...state,
      error: payload,
      loading: false,
    }),
  )
  .default(state => {
    return state;
  });

export default timesheetReducer;
