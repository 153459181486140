import React, { useState, useEffect } from "react";
import { Row, Empty, Modal } from "antd";
import { style } from "styles/Fonts";
import { netPayImage } from "AssetHelper";
import text from "text";
import {
  MetaDataType,
  NetPayDetailsType,
  NetPayProcessDetailsType,
  NetPayEmployeeDetailsType,
  PopUP,
  NetPayPostDetailsType,
} from "./type";
import NetPayService from "./service";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import {
  CLI_INTERNAL_ROLE,
  CLMGR_INTERNAL_ROLE,
  initialFilteredValue,
  popUpInitialValue,
  initialValue,
  metaDataInitialValue,
  postInitialValue,
  LoaderType,
  NET_PAY_MAIN_SCREEN,
  processDetailsInitialValue,
} from "./constant";
import Loader from "components/Loader";
import NetPayFilter from "components/NetPayFilter/NetPayFilter";
import { NetPaySelectedFilters } from "components/NetPayFilter/type";
import NetPayDetails from "components/NetPayDetails/NetPayDetails";
import NetPayEmployeeDetails from "components/NetPayEmployeeDetails/NetPayEmployeeDetails";
import {
  NetPayEmployeeList,
  NetPayPostRequestBody,
  NetPayPerEmployeeBreakdown,
} from "models/netpay.data";
import _ from "lodash";
import { RejectPopUp } from "components/RejectPopUp";
import { getPostRequestParams } from "./helper";
import { showNotification } from "components/ShowNotification/ShowNotification";
import { downloadExcel } from "components/DownloadExcel/helper";
import {
  columnForExcel,
  columns,
} from "components/NetPayEmployeeDetails/helper";
import moment from "moment";
import { ModuleType } from "screens/home/store/home/constants";

export default function NetPay() {
  const { contextStore } = useSelector((state: ApplicationState) => {
    return state;
  });

  const isInternalRoleClient = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === CLI_INTERNAL_ROLE
  )
    ? true
    : false;

  const [filterValues, setFilterValues] = useState<NetPaySelectedFilters>(
    initialFilteredValue
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKey, setSelectedRowKey] = useState<string[]>([]);
  const [popup, setPopup] = useState<PopUP>(popUpInitialValue);
  const [currentNetPay, setCurrentNetPay] = useState<number>();
  const [downloadNetPay, setNetPayDownload] = useState(false);
  const [netPayMetaData, setNetPayMetaData] = useState<MetaDataType>(
    metaDataInitialValue
  );
  const [netPayDetails, setNetPayDetails] = useState<NetPayDetailsType>(
    initialValue
  );
  const [netPayProcessDetails, setNetPayProcessDetails] = useState<
    NetPayProcessDetailsType
  >(processDetailsInitialValue);
  const [netPayEmployeeDetails, setNetPayEmployeeDetails] = useState<
    NetPayEmployeeDetailsType
  >(initialValue);
  const [netPayPostDetails, setNetPayPostDetails] = useState<
    NetPayPostDetailsType
  >(postInitialValue);

  useEffect(() => {
    getMetaDataDetails();
  }, []);

  const getMetaDataDetails = async () => {
    try {
      setNetPayMetaData({
        ...netPayMetaData,
        isLoading: true,
      });
      const response = await NetPayService.getNetPayMetaData({
        internalRole: isInternalRoleClient
          ? CLI_INTERNAL_ROLE
          : CLMGR_INTERNAL_ROLE,
      });
      setNetPayMetaData({
        isLoading: false,
        metaData: response,
      });
    } catch (err) {
      setNetPayMetaData({
        ...netPayMetaData,
        isLoading: false,
      });
    } finally {
    }
  };

  const onGetDetails = async (filteredValue: NetPaySelectedFilters) => {
    setFilterValues(filteredValue);
    try {
      setSelectedRowKey([]);
      setNetPayDetails({
        ...netPayDetails,
        isLoading: true,
      });
      const response = await NetPayService.getNetPayDetails({
        clientCode: netPayMetaData.metaData.data.clientCode || "",
        internalRole: isInternalRoleClient
          ? CLI_INTERNAL_ROLE
          : CLMGR_INTERNAL_ROLE,
        netPayTypeCodeSearch: filteredValue.netPayType,
        processPeriodCodeSearch: filteredValue.payrollPeriod,
        payrollCalendarCodeSearch: filteredValue.payrollCalendarCode,
        processPeriodNumberSearch: filteredValue.processPeriodNumber,
        statusCodeSearch: filteredValue.statusType,
      });
      response.data.netPayRegisterList = response.data.netPayRegisterList?.map(
        (item, index: number) => {
          item.showEmployeeDetails = false;
          item.id = index;
          return item;
        }
      );
      setNetPayDetails({
        isLoading: false,
        details: response,
      });
      if (response.data.netPayRegisterList?.length) {
        let reference: { id: number; refer: string }[] = [];
        response.data.netPayRegisterList.map(item => {
          reference.push({
            id: item.id || 0,
            refer: item.referenceNumber || "",
          });
        });
        getProcessDetails(filteredValue, reference);
      }
    } catch (err) {
      setNetPayDetails({
        ...netPayDetails,
        isLoading: false,
      });
    } finally {
    }
  };

  const getProcessDetails = async (
    filteredValues: NetPaySelectedFilters,
    refernces: { id: number; refer: string }[]
  ) => {
    try {
      setNetPayProcessDetails({
        ...netPayProcessDetails,
        isLoading: true,
      });
      for (let i = 0; i < refernces.length; i++) {
        let netPayProcess = _.cloneDeep(netPayProcessDetails.details);
        netPayProcessDetails.details[refernces[i].id] = {
          data: {
            loading: true,
          },
          message: "",
        };
        const response = await NetPayService.getNetPayProcessDetails({
          clientCode: netPayMetaData.metaData.data.clientCode || "",
          internalRole: isInternalRoleClient
            ? CLI_INTERNAL_ROLE
            : CLMGR_INTERNAL_ROLE,
          netPayTypeCodeSearch: filteredValues.netPayType,
          processPeriodCodeSearch: filteredValues.payrollPeriod,
          payrollCalendarCodeSearch: filteredValues.payrollCalendarCode,
          processPeriodNumberSearch: filteredValues.processPeriodNumber,
          referenceNumberSearch: refernces[i].refer,
        });
        netPayProcess = _.cloneDeep(netPayProcessDetails.details);
        netPayProcessDetails.details[refernces[i].id] = response;
        setNetPayProcessDetails({
          ...netPayProcessDetails,
          isLoading: false,
        });
      }
    } catch (err) {
      setNetPayProcessDetails({
        ...netPayProcessDetails,
        isLoading: false,
      });
    } finally {
    }
    return true;
  };

  const getEmployeeDetails = async (
    refernceNumber: string,
    filteredValues: NetPaySelectedFilters
  ) => {
    try {
      setSelectedRowKey([]);
      setNetPayEmployeeDetails({
        details: {
          data: {},
          message: "",
        },
        isLoading: true,
      });
      const response = await NetPayService.getNetPayEmployeeDetails({
        clientCode: netPayMetaData.metaData.data.clientCode || "",
        internalRole: isInternalRoleClient
          ? CLI_INTERNAL_ROLE
          : CLMGR_INTERNAL_ROLE,
        netPayTypeCodeSearch: filteredValues.netPayType,
        processPeriodCodeSearch: filteredValues.payrollPeriod,
        payrollCalendarCodeSearch: filteredValues.payrollCalendarCode,
        processPeriodNumberSearch: filteredValues.processPeriodNumber,
        referenceNumberSearch: refernceNumber,
      });
      setNetPayEmployeeDetails({
        isLoading: false,
        details: response,
      });
      changePage(1);
    } catch (err) {
      setNetPayEmployeeDetails({
        details: {
          data: {},
          message: "",
        },
        isLoading: false,
      });
    } finally {
    }
  };

  const onDownloadExcel = async (index: number) => {
    try {
      setNetPayDownload(true);
      const responsePerEmployee: NetPayPerEmployeeBreakdown = await NetPayService.getNetPayPerEmployeeBreakdown(
        {
          clientCode: netPayMetaData.metaData.data.clientCode || "",
          internalRole: isInternalRoleClient
            ? CLI_INTERNAL_ROLE
            : CLMGR_INTERNAL_ROLE,
          netPayTypeCodeSearch: filterValues.netPayType,
          processPeriodCodeSearch: filterValues.payrollPeriod,
          payrollCalendarCodeSearch: filterValues.payrollCalendarCode,
          processPeriodNumberSearch: filterValues.processPeriodNumber,
          referenceNumberSearch:
            netPayDetails.details.data.netPayRegisterList![index]
              .referenceNumber || "",
        }
      );
      responsePerEmployee.data.employeeDetailsList = responsePerEmployee.data.employeeDetailsList?.map(
        item => {
          item.dateOfBirth = moment(item.dateOfBirth).format("DD-MM-YYYY");
          item.dateOfJoining = moment(item.dateOfJoining).format("DD-MM-YYYY");
          item.dateofSeparation = moment(item.dateofSeparation).format(
            "DD-MM-YYYY"
          );
          item.paidDate = moment(item.paidDate).format("DD-MM-YYYY");
          item.invoiceDate = moment(item.invoiceDate).format("DD-MM-YYYY");
          return item;
        }
      );
      downloadExcel(
        columns(responsePerEmployee),
        responsePerEmployee.data.employeeDetailsList,
        undefined
      );
      setNetPayDownload(false);
    } catch (err) {
      setNetPayDownload(false);
    } finally {
    }
  };

  const onRefresh = (index: number) => {
    const refernce = [
      {
        id: index,
        refer:
          netPayDetails.details.data.netPayRegisterList![index]
            .referenceNumber || "",
      },
    ];
    getProcessDetails(filterValues, refernce);
  };

  const onViewMore = (identifier: number) => {
    if (netPayDetails.details) {
      const newEmployeeList = _.cloneDeep(netPayDetails.details);
      if (currentNetPay !== identifier) {
        newEmployeeList.data.netPayRegisterList?.map(item => {
          item.showEmployeeDetails = false;
        });
      }
      newEmployeeList.data.netPayRegisterList
        ?.filter((item, index) => index === identifier)
        .map(item => {
          item.showEmployeeDetails = !item.showEmployeeDetails;
        });
      setNetPayDetails({
        ...netPayDetails,
        details: newEmployeeList,
      });
    }
    !netPayDetails.details.data.netPayRegisterList![identifier]
      .showEmployeeDetails &&
      getEmployeeDetails(
        netPayDetails.details.data.netPayRegisterList![identifier]
          .referenceNumber || "",
        filterValues
      );
    setCurrentNetPay(identifier);
  };

  const onChangeRowSelection = (
    value: any,
    selectedRowsData: NetPayEmployeeList[]
  ) => {
    setSelectedRowKey(value);
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const onRemarksChange = (value: string, row: NetPayEmployeeList) => {
    if (netPayEmployeeDetails.details) {
      const newEmployeeList = _.cloneDeep(netPayEmployeeDetails.details);
      newEmployeeList.data.employeeList
        ?.filter(item => item.employeeCode === row.employeeCode)
        .map(item => {
          item.referenceRemarks = value;
        });
      setNetPayEmployeeDetails({
        ...netPayEmployeeDetails,
        details: newEmployeeList,
      });
    }
  };

  const onApprove = async (requestBody: NetPayPostRequestBody) => {
    try {
      setNetPayPostDetails({
        ...netPayPostDetails,
        isLoading: true,
      });
      const response = await NetPayService.AuthRejectNetPayEmployeeDetails(
        requestBody
      );
      setNetPayPostDetails({
        ...netPayPostDetails,
        isLoading: false,
        details: response,
      });
      changePage(1);
      setSelectedRowKey([]);
      showNotification({
        message: text.SUCCESS,
        description: text.SUBMIT_SUCCESSFULLY,
        type: text.SUCCESS,
      });
      onGetDetails(filterValues);
      setNetPayPostDetails({
        ...netPayPostDetails,
        isLoading: false,
      });
    } catch (err) {
      setNetPayPostDetails({
        ...netPayPostDetails,
        isLoading: false,
      });
    } finally {
    }
  };

  const actionTaken = (
    value: string,
    data: string,
    reason: string,
    index: number
  ) => {
    setPopup({
      visible: false,
      action: "",
      data: { screenCalling: "", index: index },
    });
    setNetPayPostDetails({
      ...netPayPostDetails,
      isLoading: true,
      loaderType: !netPayPostDetails.loaderType
        ? LoaderType.REJECT_SELECTED
        : netPayPostDetails.loaderType,
    });
    const referenceList = getPostRequestParams(
      selectedRowKey,
      data,
      netPayDetails.details.data.netPayRegisterList![index].referenceNumber ||
        "",
      netPayEmployeeDetails.details.data.employeeList || []
    );
    const requestBody = {
      actionType: value,
      callingFromScreen: data,
      clientCode: netPayMetaData.metaData.data.clientCode || "",
      internalRole: isInternalRoleClient
        ? CLI_INTERNAL_ROLE
        : CLMGR_INTERNAL_ROLE,
      netPayTypeCode: filterValues.netPayType,
      overallRemarks: reason,
      payrollCalendarCode: filterValues.payrollCalendarCode,
      processPeriodCode: filterValues.payrollPeriod,
      processPeriodNumber: parseInt(filterValues.processPeriodNumber),
      referenceDetailsarray: referenceList,
    };
    onApprove(requestBody);
  };

  const onActionTaken = (
    action: string,
    screenCalling: string,
    index: number
  ) => {
    if (action === text.APPROVE_ITEM) {
      actionTaken(action, screenCalling, "", index);
      setNetPayPostDetails({
        ...netPayPostDetails,
        isLoading: true,
        loaderType:
          screenCalling === NET_PAY_MAIN_SCREEN
            ? LoaderType.APPROVE_SEELCTED
            : LoaderType.APPROVE_ALL,
      });
    } else {
      setNetPayPostDetails({
        ...netPayPostDetails,
        loaderType:
          screenCalling === NET_PAY_MAIN_SCREEN
            ? LoaderType.REJECT_SELECTED
            : LoaderType.REJECT_ALL,
      });
      screenCalling === NET_PAY_MAIN_SCREEN
        ? actionTaken(action, screenCalling, "", index)
        : setPopup({
            visible: true,
            action: text.REJECT,
            data: {
              screenCalling,
              index,
            },
          });
    }
  };

  if (!contextStore.allowedActivities[ModuleType.NETPAY])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  const onReject = (values: string, data: any, reason: string) => {
    actionTaken(values, data.screenCalling, reason, 0);
  };

  return (
    <Row>
      <RejectPopUp
        visible={popup.visible}
        onReject={onReject}
        value={popup.action}
        data={popup.data}
        onCancel={() => {
          setPopup({
            visible: false,
            action: "",
            data: { screenCalling: "", index: 0 },
          });
        }}
      />
      {netPayMetaData?.isLoading ? (
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="min-vh-content">
          <Loader />
        </Row>
      ) : (
        <Row className="p-6">
          <Modal visible={downloadNetPay} footer={null} closable={false}>
            <Loader />
            <Row className="c-primary" type="flex" justify="center">
              {text.DOWNLOADING}
            </Row>
          </Modal>
          <Row className="pb-2 ff-secondary" style={style.large} type="flex">
            <Row>
              <img src={netPayImage} className="pr-3 h-6" />
            </Row>
            <Row>{text.NET_PAY}</Row>
          </Row>
          <Row className="pb-4">
            <NetPayFilter
              dataList={netPayMetaData.metaData.data}
              onGetDetails={onGetDetails}
            />
          </Row>
          {netPayDetails.isLoading ? (
            <Row className=" bg-white w-100 py-9">
              <Loader />
            </Row>
          ) : netPayDetails.details.data.netPayRegisterList?.length ? (
            netPayDetails.details.data.netPayRegisterList.map((item, index) => {
              return (
                <Row className="bg-white p-3 mb-3" key={item.id}>
                  <NetPayDetails
                    data={netPayDetails.details.data.netPayRegisterList![index]}
                    processDescription={
                      netPayProcessDetails.details[index]
                        ? netPayProcessDetails.details[index].data
                            .processStatusDescription
                        : ""
                    }
                    onRefresh={onRefresh}
                    refreshing={
                      netPayProcessDetails.details[index]
                        ? netPayProcessDetails.details[index].data.loading ||
                          false
                        : false
                    }
                    onViewMore={onViewMore}
                    showEmployeeDetails={
                      netPayDetails.details.data.netPayRegisterList![index]
                        .showEmployeeDetails || false
                    }
                    index={index}
                  />
                  {item.showEmployeeDetails && (
                    <NetPayEmployeeDetails
                      employeeList={
                        netPayEmployeeDetails.details.data.employeeList || []
                      }
                      loadingData={netPayEmployeeDetails.isLoading}
                      selectedData={selectedRowKey}
                      onChangeRowSelection={onChangeRowSelection}
                      currentPage={currentPage}
                      onPageChange={changePage}
                      actionTaken={onActionTaken}
                      processRemarks={
                        netPayProcessDetails.details[index]
                          ? netPayProcessDetails.details[index].data
                              .processRemarks || "N/A"
                          : text.NOT_APPLICABLE
                      }
                      onRemarksChange={onRemarksChange}
                      processStage={
                        netPayDetails.details.data.netPayRegisterList![index]
                          .statusCode || ""
                      }
                      savingData={{
                        loaderType: netPayPostDetails.loaderType || undefined,
                        loading: netPayPostDetails.isLoading,
                      }}
                      index={index}
                      onDownloadExcel={onDownloadExcel}
                    />
                  )}
                </Row>
              );
            })
          ) : (
            <Row className="bg-white w-100">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          )}
        </Row>
      )}
    </Row>
  );
}
