import React, { useEffect, useState } from "react";
import { Row, Card } from "antd";
import DashboardCard from "components/DashboardCard/DashboardCard";
import { CardItem, reimbursementData } from "screens/dashboard/constants";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DashboardActions } from "screens/dashboard/actions";
import { useHistory } from "react-router-dom";
import { reimbursementImage } from "AssetHelper";
import { SummaryResponse, SummaryDetails } from "models/dashboard.data";
import Reimbursement from "screens/reimbursement/Reimbursement";
import Loader from "./Loader";
import { defaultClientEndDate, defaultClientStartDate, dateFormat } from "date";

export default function ReimbursementCards() {
  const [displayData] = useState(reimbursementData);
  const [data, setData] = useState();

  const summary = useSelector((state: any) => {
    return state.dashboardStore;
  }, shallowEqual);

  const dispatch = useDispatch();

  const getReimbursementSummaryData = () => {
    const requestData = {
      internalRole: "APR",
      endDate: defaultClientEndDate.format(dateFormat),
      startDate: defaultClientStartDate.format(dateFormat),
    };
    dispatch(DashboardActions.getReimbursementSummary.started(requestData));
  };

  const getReimbursementSummary = () => {
    const updatedReimbursementSummary: any = {};
    summary.dashboardSummary.reimbursementSummary.data.map(
      (item: SummaryDetails, index: number) => {
        updatedReimbursementSummary[item.statusCode] = item.recordCount || 0;
      },
    );
    return updatedReimbursementSummary;
  };

  useEffect(() => {
    getReimbursementSummaryData();
  }, []);

  useEffect(() => {
    const updatedSummary = getReimbursementSummary();
    setData(updatedSummary);
  }, [summary.dashboardSummary.reimbursementSummary]);

  const history = useHistory();

  const onClickCard = (value: string) => {
    history.push({
      pathname: "/home/reimbursement",
      search: `status=${value}`,
    });
  };

  const renderCards = (
    title: string,
    value: string,
    icon: string,
    color: string,
    index: number,
  ) => {
    return (
      <div
        className={`card-${title.replace(/ /g, "_")}  w-100 w-md-auto`}
        onClick={() => onClickCard(value)}>
        <DashboardCard
          title={title}
          value={data && data[`${value}`]}
          icon={icon}
          valueColor={color}
          hoverable={true}
        />
      </div>
    );
  };

  return (
    <div className={`p-2 Reimbursement`}>
      <Row className="p-2" type="flex" justify="start">
        <img src={reimbursementImage} />
        <h4 className="p-2 my-1 ff-secondary">{"Reimbursement"}</h4>
      </Row>
      <Row type="flex" justify="start">
        {summary.reimbursementLoading ? (
          <div className="w-50 py-3">
            <Loader />
          </div>
        ) : (
          displayData.map((cardItem: CardItem, index: number) =>
            renderCards(
              cardItem.title,
              cardItem.value,
              cardItem.icon,
              cardItem.color,
              index,
            ),
          )
        )}
      </Row>
    </div>
  );
}
