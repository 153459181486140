import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import {
  NetPayMetaDataDetails,
  NetPayDetails,
  NetPayProcessDetails,
  NetPayEmployeeDetails,
  NetPayPostRequestBody,
  NetPayPostResponse,
  NetPayPerEmployeeBreakdown,
} from "models/netpay.data";
import {
  NetPayMetaDataParms,
  NetPayDetailsParams,
  NetPayProcessDetailsParams,
} from "./type";

export class NetPayService {
  public getNetPayMetaData(
    requestData: NetPayMetaDataParms
  ): Promise<NetPayMetaDataDetails> | NetPayMetaDataDetails {
    const service: ApiService = new ApiService(EndPoints.GET_NET_PAY_METADATA);
    return service.get({
      dynamicQueryParams: [{ internalRole: requestData.internalRole }],
    });
  }
  public getNetPayDetails(
    requestData: NetPayDetailsParams
  ): Promise<NetPayDetails> | NetPayDetails {
    const service: ApiService = new ApiService(EndPoints.GET_NET_PAY_DETAILS);
    return service.get({
      dynamicQueryParams: [
        { clientCode: requestData.clientCode },
        { internalRole: requestData.internalRole },
        { netPayTypeCodeSearch: requestData.netPayTypeCodeSearch },
        { processPeriodNumberSearch: requestData.processPeriodNumberSearch },
        { payrollCalendarCodeSearch: requestData.payrollCalendarCodeSearch },
        { processPeriodCodeSearch: requestData.processPeriodCodeSearch },
        { statusCodeSearch: requestData.statusCodeSearch },
      ],
    });
  }
  public getNetPayProcessDetails(
    requestData: NetPayProcessDetailsParams
  ): Promise<NetPayProcessDetails> | NetPayProcessDetails {
    const service: ApiService = new ApiService(
      EndPoints.GET_NET_PAY_PROCESS_DETAILS
    );
    return service.get({
      dynamicQueryParams: [
        { clientCode: requestData.clientCode },
        { internalRole: requestData.internalRole },
        { netPayTypeCodeSearch: requestData.netPayTypeCodeSearch },
        { processPeriodNumberSearch: requestData.processPeriodNumberSearch },
        { payrollCalendarCodeSearch: requestData.payrollCalendarCodeSearch },
        { processPeriodCodeSearch: requestData.processPeriodCodeSearch },
        { referenceNumberSearch: requestData.referenceNumberSearch },
      ],
    });
  }
  public getNetPayEmployeeDetails(
    requestData: NetPayProcessDetailsParams
  ): Promise<NetPayEmployeeDetails> | NetPayEmployeeDetails {
    const service: ApiService = new ApiService(
      EndPoints.GET_NET_PAY_EMPLOYEE_DETAILS
    );
    return service.get({
      dynamicQueryParams: [
        { clientCode: requestData.clientCode },
        { internalRole: requestData.internalRole },
        { netPayTypeCode: requestData.netPayTypeCodeSearch },
        { processPeriodNumber: requestData.processPeriodNumberSearch },
        { payrollCalendarCode: requestData.payrollCalendarCodeSearch },
        { processPeriodCode: requestData.processPeriodCodeSearch },
        { referenceNumber: requestData.referenceNumberSearch },
      ],
    });
  }
  public AuthRejectNetPayEmployeeDetails(
    requestData: NetPayPostRequestBody
  ): Promise<NetPayPostResponse> | NetPayPostResponse {
    const service: ApiService = new ApiService(
      EndPoints.AUTH_REJECT_NET_PAY_DETAILS
    );
    return service.post({}, requestData);
  }
  public getNetPayPerEmployeeBreakdown(
    requestData: NetPayProcessDetailsParams
  ): Promise<NetPayPerEmployeeBreakdown> | NetPayPerEmployeeBreakdown {
    const service: ApiService = new ApiService(
      EndPoints.GET_NET_PAT_PER_EMPLOYEE_BREAKDOWN,
    );
    return service.get({
      dynamicQueryParams: [
        { clientCodeInput: requestData.clientCode },
        { internalRole: requestData.internalRole },
        { netPayTypeCodeInput: requestData.netPayTypeCodeSearch },
        { processPeriodNumberInput: requestData.processPeriodNumberSearch },
        { payrollCalendarCodeInput: requestData.payrollCalendarCodeSearch },
        { processPeriodCodeInput: requestData.processPeriodCodeSearch },
        { referenceNumberInput: requestData.referenceNumberSearch },
      ],
    });
  }
}

export default new NetPayService();
