import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { AssociatesLeaveDetails, Approverrequest } from "models/leave.data";
import { FiltersInterface } from "./type";

export class LeaveService {
  public getAssociateLeaves(
    params: FiltersInterface,
  ): Promise<AssociatesLeaveDetails> | AssociatesLeaveDetails {
    const service: ApiService = new ApiService(EndPoints.leave);
    return service.get({
      dynamicQueryParams: [
        { fromDateSearch: params.fromDateSearch },
        { toDateSearch: params.toDateSearch },
        { statusCodeSearch: params.statusCodeSearch },
      ],
    });
  }
  public approveLeave(
    request: Approverrequest[],
  ): Promise<AssociatesLeaveDetails> | AssociatesLeaveDetails {
    const service: ApiService = new ApiService(EndPoints.approveleave);
    return service.post({}, request);
  }
}

const leaveService = new LeaveService();
export default leaveService;
