/**
 * Defines HTTP content type supported by APIs.
 */
export enum HttpContentType {
  Json = "application/json",
  File = "multipart/form-data",
}

/**
 * Static end-points for all APIs. In case endpoint has dynamic values,
 * pass it in query object while calling API-SERVICE and it will be
 * appended at the end.
 */
export enum EndPoints {
  Auth = "login",
  EndSession = "connect/endsession",
  ErModelJsonData = "api/get-ermodel",
  ErData = "api/ermodel",
  ProjectMetaDataApi = "api/project-details",
  leave = "leave/approver/list",
  approveleave = "leave/approver",
  timesheet = "timesheet/attendance",
  repotees = "employee/Reportees",
  profileUser = "employee/userprofile",
  profileContacts = "employeeprofile/contact/informationlist",
  profileQualifications = "employeeprofile/qualification/list",
  profileWorkExperience = "employeeprofile/workexperience/list",
  profileFamilyMembers = "employeeprofile/family/informationlist",
  profilePersonalInformation = "employeeprofile/personalinformation",
  leaveSummary = "leave/summarydisplay",
  timesheetSummary = "timesheet/attendance/summarydisplay",
  reimbursementSummary = "expense/summarydisplay",
  leaveReport = "leave/reports/leavedetailslist",
  leaveBalanceReport = "leave/reports/leavebalanceslist",
  attendanceReport = "timesheet/attendance/reports/attendancedetailslist",
  reimbursementReport = "expense/reports/expensesdetailslist",
  advanceSalaryReport = "loans/loanreport",
  approveTimesheet = "timesheet/approver",
  reimbursement = "expense/approver",
  approveExpense = "expense/approver",
  context = "user-config-ramco-india/me/contexts",
  fetchToken = "FetchAccessToken",
  CORE_UTILITY_DOWNLOAD_FILE = "core-utilityservices/file",
  RELATIVE_PATH_EMPLOYEE = "employee/document/relativepath",
  USER_ACTIVITIES = "user-config-ramco-india/me/activities",
  ITEM_DETAILS = "expense/detailsbyidentifier",
  BULK_TIMESHEET_METADATA = "bulkpayrollinputs/attendance/metadata",
  BULK_TIMESHEET_DATA = "bulkpayrollinputs/attendance/list",
  POST_BULK_ATTENDANCE_DATA = "bulkpayrollinputs/attendance",
  GET_BULK_PAYROLL_METADATA = "bulkpayrollinputs/nonrecurringelements/metadata",
  GET_BULK_PAYROLL_DATA = "bulkpayrollinputs/nonrecurringelements/list",
  POST_BULK_PAYROLL_DATA = "bulkpayrollinputs/nonrecurringelements",
  GET_ONBOARDING_ANALYTICS_METADATA = "onboardinganalytics/metadata",
  GET_ONBOARDING_ANALYTICS_AUTHORISED_SUMMARY = "onboardinganalytics/bgvcompletion/summary",
  GET_ONBOARDING_ANALYTICS_PENDING_SUMMARY = "onboardinganalytics/bgvprogress/summary",
  GET_ONBOARDING_ANALYTICS_AUTHORISED_CANDIDATE_DETAILS = "onboardinganalytics/bgvcompletion/candidatedetails",
  GET_ONBOARDING_ANALYTICS_PENDING_CANDIDATE_DETAILS = "onboardinganalytics/bgvprogress/candidatedetails",
  GET_INVOICES_METADATA = "invoicemanagement/invoicedetails/metadata",
  GET_INVOICES_ADVANCED_METADATA = "invoicemanagement/invoicedetails/filtercriteria",
  GET_INVOICES_LIST = "invoicemanagement/invoicedetails/list",
  GET_INVOICES_DOCUMENTS = "invoicemanagement/invoicedetails/documents",
  GET_LINE_MANAGER_METADATA = "employmentinformation/supervisorupdation/metadata",
  GET_LINE_MANAGER_ASSOCIATE_DETAILS = "/employmentinformation/supervisorupdation/list",
  POST_LINE_MANAGER_DETAILS = "employmentinformation/supervisorupdation",
  GET_ONBOARDING_ANALYTICS_JOINED_SUMMARY = "onboardinganalytics/candidatejoining/summary",
  GET_ONBOARDING_ANALYTICS_JOINED_TABLE_DETAILS = "onboardinganalytics/candidatejoining/candidatedetails",
  GET_ONBOARDING_ANALYTICS_EXPECTED_TO_JOIN_SUMMARY = "onboardinganalytics/newjoineeexpectation/summary",
  GET_ONBOARDING_ANALYTICS_EXPECTED_TO_JOIN_TABLE_DETAILS = "onboardinganalytics/newjoineeexpectation/candidatedetails",
  GET_ONBOARDING_ANALYTICS_INITIATED_SUMMARY = "onboardinganalytics/onboardinginitiation/summary",
  GET_ONBOARDING_ANALYTICS_INITIATED_TABLE_DETAILS = "onboardinganalytics/onboardinginitiation/candidatedetails",
  GET_ONBOARDING_ANALYTICS_COMPLETED_SUMMARY = "onboardinganalytics/onboardingcompletion/summary",
  GET_ONBOARDING_ANALYTICS_COMPLETED_TABLE_DETAILS = "onboardinganalytics/onboardingcompletion/candidatedetails",
  GET_ASSOCIATE_ANALYTICS_CONTRACT_EXPIRED_SUMMARY = "clientanalytics/associatecontractexpiration/summary",
  GET_ASSOCIATE_ANALYTICS_CONTRACT_EXPIRED_TABLE_DETAILS = "clientanalytics/associatecontractexpiration/employeedetails",
  GET_CLIENT_ANALYTICS_CONTRACT_EXPIRED_SUMMARY = "clientanalytics/clientcontract/summary",
  GET_CLIENT_ANALYTICS_CONTRACT_EXPIRED_TABLE_DETAILS = "clientanalytics/clientcontract/contractdetails",
  GET_CLIENT_ANALYTICS_CONTRACT_EXPIRED_NEXT_MONTH_SUMMARY = "clientanalytics/associatecontractexpiration/summary",
  GET_CLIENT_ANALYTICS_CONTRACT_EXPIRED_NEXT_MONTH_TABLE_DETAILS = "clientanalytics/associatecontractexpiration/employeedetails",
  GET_CLIENT_ANALYTICS_CLRA_NOT_AVAILABLE_SUMMARY = "clientanalytics/clralicense/summary",
  GET_CLIENT_ANALYTICS_CLRA_NOT_AVAILABLE_TABLE_DETAILS = "clientanalytics/clralicense/licensedetails",
  GET_NET_PAY_METADATA = "invoicemanagement/netpayregister/metadata",
  GET_NET_PAY_DETAILS = "invoicemanagement/netpayregister/list",
  GET_NET_PAY_PROCESS_DETAILS = "invoicemanagement/netpayregister/status",
  GET_NET_PAY_EMPLOYEE_DETAILS = "invoicemanagement/netpayregister/employeedetails",
  GET_NET_PAT_PER_EMPLOYEE_BREAKDOWN = "invoicemanagement/netpayregister/netpaydetails",
  AUTH_REJECT_NET_PAY_DETAILS = "invoicemanagement/netpayregister/approvals",
  GET_TRANSFER_REQUEST_LIST = "/candidatetransfers/transferrequests/list",
  GET_TRANSFER_METADATA = "candidatetransfers/transferrequests/metadata",
  GET_TRANSFER_SUMMARY_DETAILS = "candidatetransfers/transferrequests/summary",
  POST_CANDIDATE_TRANSFER = "candidatetransfers/transferrequests",
  GET_ANALYTICS_PAYOUT_SUMMARY = "payrollanalytics/employeepayout/summary",
  GET_ANALYTICS_PAYOUT_TABLE_DETAILS = "payrollanalytics/employeepayout/employeedetails",
  GET_ANALYTICS_PAYROLL_PROCESSED_SUMMARY = "payrollanalytics/payrollprocess/summary",
  GET_ANALYTICS_PAYROLL_PROCESSED_TABLE_DETAILS = "payrollanalytics/payrollprocess/employeedetails",
  GET_ANALYTICS_INVOICE_SUMMARY = "invoiceanalytics/invoicegeneration/summary",
  GET_ANALYTICS_INVOICE_TABLE_DETAILS = "invoiceanalytics/invoicegeneration/invoicedetails",
  GET_ANALYTICS_NET_PAY_SUMMARY = "invoiceanalytics/netpaygeneration/summary",
  GET_ANALYTICS_NET_PAY_TABLE_DETAILS = "invoiceanalytics/netpaygeneration/employeedetails",
  GET_ANALYTICS_ATTENDANCE_SUMMARY = "timesheetanalytics/attendancereceipt/summary",
  GET_ANALYTICS_ATTENDANCE_TABLE_DETAILS = "timesheetanalytics/attendancereceipt/employeedetails",
  GET_ANALYTICS_ASSOCIATE_TOTAL_HEAD_COUNT_SUMMARY = "employeeanalytics/headcount/summary",
  GET_ANALYTICS_ASSOCIATE_TOTAL_HEAD_COUNT_DETAILS = "employeeanalytics/headcount/employeedetails",
  GET_ANALYTICS_ASSOCIATE_NEW_ADDITION_SUMMARY = "employeeanalytics/employeeaddition/summary",
  GET_ANALYTICS_ASSOCIATE_NEW_ADDITION_DETAILS = "employeeanalytics/employeeaddition/employeedetails",
  GET_ANALYTICS_ASSOCIATE_CONTRACT_EXPIRED_SUMMARY = "employeeanalytics/contractexpiry/summary",
  GET_ANALYTICS_ASSOCIATE_CONTRACT_EXPIRED_DETAILS = "employeeanalytics/contractexpiry/contractdetails",
  GET_ANALYTICS_ASSOCIATE_RESIGNED_SUMMARY = "separationanalytics/resignation/summary",
  GET_ANALYTICS_ASSOCIATE_RESIGNED_DETAILS = "separationanalytics/resignation/employeedetails",
  GET_RESIGNATION_SUMMARY_DETAILS = "employeemovements/separation/approver/summary",
  GET_RESIGNATION_DETAILS_LIST = "employeemovements/separation/approver/list",
  POST_APPROVE_REJECT_RESIGNATION = "employeemovements/separation/approver",
  GET_RESIGNATION_METADATA = "employeemovements/separation/metadata",
  FEATURE_ACCESS_PERMISSION = "configurationManagement/userfeatures/list",
  GET_LOAN_SUMMARY_DETAILS = "loans/approversummarydetails",
  GET_LOAN_DETAILS_LIST = "loans/authrejtPrerequisites",
  POST_AUTH_REJ_LOAN = "loans/authLoans",
}

interface Query {
  [paramName: string]: string | number | boolean | null;
}

/**
 * Adds values dynamically to Api Endpoints
 * Use @param DynamicRoute to append urls like "baseUrl/staticEndpoint/{routeParam1}/{routeParam2}/..."
 * Use @param dynamicQueryParams to append urls like "baseUrl/staticEndpoint?{name1}={value1}&{name2}={value2}..." *
 */
export interface DynamicQueryPath {
  readonly dynamicRoute?: string[] | undefined | null;
  readonly dynamicQueryParams?: Query[] | undefined | null;
}
