import React, { useState, useEffect } from "react";
import { Input, Row, Icon } from "antd";

export default function Search(props: {
  placeholder: string;
  getSearchedValue: (searchedValue: any) => void;
  width?: number;
  data: any[];
  value?: string;
  disabled?: boolean;
}) {
  const { placeholder, getSearchedValue, data, width } = props;
  const [searchedValue, setSearchedValue] = useState(Array<any>());

  const handleSearch = (value: any) => {
    getSearchedValue(value.currentTarget.value);
  };

  useEffect(() => {
    setSearchedValue(data);
  }, [searchedValue, data]);

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className={width ? "" : "m-2"}>
        {props.value || props.value === "" ? <Input
          placeholder={placeholder}
          onPressEnter={handleSearch}
          prefix={<Icon type="search" />}
          onInput={event => {
            handleSearch(event);
          }}
          value={props.value}
          disabled={props.disabled}
          style={{ width: width ? width : "auto" }}
        />:
        <Input
          placeholder={placeholder}
          onPressEnter={handleSearch}
          prefix={<Icon type="search" />}
          onInput={event => {
            handleSearch(event);
          }}
          style={{ width: width ? width : "auto" }}
          disabled={props.disabled}
      /> }
    </Row>
  );
}
