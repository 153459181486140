import React from "react";
import text from "text";
import { IUploadDoc } from "./type";
import { parseExcelToJson } from "./helper";
import { noop } from "lodash";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import { ModalType } from "components/Modal/type";

const UploadDoc = (props: IUploadDoc) => {
  const {icon, iconSize, lable, onUpload, isDisable, type, column} = props;
  const fileInputRef = React.useRef<any>();
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const onFileUpload=(e:React.ChangeEvent<HTMLInputElement>) => {
    if(type === text.EXCEL)
    {
      const fileType = e.target.files![0].name.split(".");
      if(fileType[fileType.length-1] === "xlsx" || fileType[fileType.length-1] === "xls") {
        const file = e.target.files![0];
        const dataToFit = props.dataToFit ? props.dataToFit : {list: [], key: ""};
        parseExcelToJson(file, onUpload, column!, dataToFit.list, dataToFit.key);
        e.target.value = "";
      } else{
        ModalPopUp({
          type: ModalType.info,
          title: text.WARNING,
          description: text.PLEASE_SELECT_EXCEL_SHEET ,
        });
      }
    }
    else
      onUpload(e.target.files);
  };

  return (
    <div
      onClick={isDisable ? noop : handleClick}
      className={isDisable ? "m-2 cursor-not-allowed" : "m-2 cursor-pointer"}
    >
      <input
        ref={fileInputRef}
        className="d-none"
        type="file"
        onChange={onFileUpload}
      />
      <img src={icon} className={`h-${iconSize}`} alt="uplaod icon" />
      <span className="c-black ml-2 align-middle">{lable}</span>
    </div>
  );
};

export default UploadDoc;
