export const defaultLineManagerMetadata = {
  data: {
    clientCode: "",
    clientName: "",
    customDateOutput1: "",
    customDateOutput2: "",
    customIntegerOutput1: 0,
    customIntegerOutput2: 0,
    customNumericOutput1: 0,
    customNumericOutput2: 0,
    customTextOutput1: "",
    customTextOutput2: "",
    customTextOutput3: "",
    customTextOutput4: "",
    existingLineManagerList: [],
    modificationOptionList: [],
    newLineManagerList: [],
  },
  message: "",
};

export const defaultLineManagersAssociateList = {
  data: {
    clientCode: "",
    clientName: "",
    customDateOutput1: "",
    customDateOutput2: "",
    customIntegerOutput1: 0,
    customIntegerOutput2: 0,
    customNumericOutput1: 0,
    customNumericOutput2: 0,
    customTextOutput1: "",
    customTextOutput2: "",
    customTextOutput3: "",
    customTextOutput4: "",
    employeeCount: 0,
    employeeList: [],
  },
  message: "",
};

export const CLMGR_INTERNAL_ROLE = "CLMGR";
export const ASSOCIATE_VIEW = "ASCVIEW";