import React from "react";
import { Row, DatePicker, Button, Col } from "antd";
import { IAnalyticsFilterProps } from "./type";
import text from "text";
import moment, { Moment } from "moment";
import DropDown from "components/Dropdown";
import { menuList } from "./constant";
const { MonthPicker } = DatePicker;

export default function AnalyticsFilter(props: IAnalyticsFilterProps) {
  const handleMenuClick = (value: string) => {
    props.filterDetails?.reportFilterList.map(item =>
      item.filterByDescription === value
        ? props.onDateChange(
            { fromDate: moment(item.fromDate), toDate: moment(item.toDate) },
            item.filterByCode,
          )
        : "",
    );
  };

  const isStartDateDisabled = (date: Moment | null) => {
    return date &&
      moment(props.selectedDateFilters.toDate) &&
      date > moment(props.selectedDateFilters.toDate)
      ? true
      : false;
  };

  const isEndDateDisabled = (date: Moment | null) => {
    return props.selectedFilterType.length > 0 ||
      (props.selectedDateFilters.fromDate &&
        date &&
        date < moment(props.selectedDateFilters.fromDate))
      ? true
      : false;
  };

  return (
    <Row className={`p-2`} type="flex" justify="end">
      <Col className="w-auto w-md-15" style={{paddingLeft:'0px', paddingRight:'1rem'}}>
        <DropDown
          menuList={menuList}
          dropdownText={text.SELECT}
          getSelectedValue={handleMenuClick}
        />
      </Col>
  <Col className="mt-3"><b>{"OR"}</b></Col>
      <MonthPicker
        className="p-2"
        onChange={(date: moment.Moment | null) =>
          props.onDateChange({ fromDate: date, toDate: null }, "")
        }
        placeholder={text.START_MONTH}
        disabledDate={isStartDateDisabled}
        value={
          props.selectedFilterType.length > 0
            ? null
            : moment(props.selectedDateFilters.fromDate)
        }
        allowClear={false}
      />
      <MonthPicker
        className="p-2"
        onChange={(date: moment.Moment | null) =>
          props.onDateChange(
            {
              fromDate: moment(props.selectedDateFilters.fromDate),
              toDate: date,
            },
            ""
          )
        }
        placeholder={text.END_MONTH}
        disabledDate={isEndDateDisabled}
        value={
          props.selectedFilterType.length > 0
            ? null
            : moment(props.selectedDateFilters.toDate)
        }
        allowClear={false}
      />
    </Row>
  );
}
