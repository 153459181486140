import { Button, Icon, Row, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import DropDown from "components/Dropdown";
import { Attendancedetails } from "models/timesheet.data";
import { TableData } from "./type";
import moment, { Moment } from "moment";
import { returnSuffix } from "common/Utils.tsx/Utils";
import { SummaryDetails } from "models/dashboard.data";
import text from "text";
import { dateCodes } from "date";

const actions: any[] = [
  {
    key: text.APPROVE,
    value: text.APPROVE,
    status: text.AUTHORIZED,
  },
  {
    key: text.REJECT,
    value: text.REJECT,
    status: text.REJECTED,
  },
];

const durationToTime = (Outtime: Moment, Intime: Moment) => {
  const difference = moment(Outtime).diff(moment(Intime));
  const hourDuration = moment.utc(difference).format("HH");
  const minDuration = moment.utc(difference).format("mm");
  if (hourDuration !== "00") {
    if (hourDuration === "01" && minDuration === "00") {
      return `${hourDuration}:${minDuration} hour`;
    }
    return `${hourDuration}:${minDuration} hours`;
  }
  return `${minDuration} mins`;
};

export function manipulateData(data: Attendancedetails[]) {
  const manipulatedData: TableData[] = [];
  data.map((innerRow: Attendancedetails, innerIndex: number) => {
    const helperArray: TableData = {
      key: 0,
      associateName: "",
      planned: 0,
      totalHoursClocked: "",
      break: 0,
      exception: "",
      intime: "",
      outtime: "",
      status: "",
      action: "",
      bookedDate: "",
      Employeecode: "",
      Attendanceidentification: 0,
      locationDescription: "",
      attachmentFile: {
        logInImage: "",
        logOutImage: "",
      },
      isGeoTagged: false
    };
    const intime = moment(innerRow.Intime?.toString())
      .format("DD MMM YY, h:mm a")
      .split(" ");
    const outtime = moment(innerRow.Outtime)
      .format("DD MMM YY, h:mm a")
      .split(" ");
    const getInTime =
      intime[0] +
      returnSuffix(intime[0]) +
      intime[1].slice(0, 3).toUpperCase() +
      "'" +
      intime[2] +
      intime[3] +
      intime[4];

    const getOutTime =
      outtime[0] +
      returnSuffix(outtime[0]) +
      outtime[1].slice(0, 3).toUpperCase() +
      "'" +
      outtime[2] +
      outtime[3] +
      outtime[4];

    const difference = moment(innerRow.Outtime).diff(moment(innerRow.Intime));
    const duration = durationToTime(
      moment(innerRow.Outtime),
      moment(innerRow.Intime)
    );
    helperArray.key = innerIndex;
    helperArray.intime = getInTime;
    helperArray.outtime = getOutTime;
    helperArray.associateName = innerRow.customOutput1 as string;
    helperArray.planned = innerRow.plannedHours as number;
    helperArray.totalHoursClocked = duration as string;
    helperArray.break = innerRow.breakHours as number;
    helperArray.exception = innerRow.Rejectionreason as string;
    helperArray.status = innerRow.Status as string;
    helperArray.bookedDate = innerRow.bookedDate?.toString() as string;
    helperArray.Employeecode = innerRow.Employeecode as string;
    helperArray.Attendanceidentification = innerRow.Attendanceidentification as number;
    helperArray.locationDescription = innerRow.locationDescription as string;
    helperArray.attachmentFile = {
      logInImage: innerRow.attachmentFileName1 as string,
      logOutImage: innerRow.attachmentFileName2 as string,
    }
    helperArray.isGeoTagged = innerRow.geoTaggedFlag === "Y" ? true : false;
    manipulatedData.push(helperArray);
  });
  return manipulatedData;
}

const getActions = (action: any) => {
  const actionList = actions.filter(item => {
    return item.status !== action;
  });
  return actionList;
};

const isActionAlreadyTaken = (action: string) => {
  let isActionTaken = false;
  for (let i = 0; i < actions.length; i++) {
    if (actions[i].status === action) {
      isActionTaken = true;
    }
  }
  return isActionTaken;
};

export function column(
  actionTaken: (value: string, data: TableData) => void,
  showImage: (logInImage: string, logOutImage: string) => void,
  isDaily: boolean
  ) {
  const fixed = window.innerWidth > 600 ? "right" : "";
  if(isDaily)
    return [
      {
        title: text.EMPLOYEE_CODE,
        key: "Employeecode",
        dataIndex: "Employeecode",
        align: "center",
        render: (employeeCode: string) => {
          return (
            <Row type="flex" justify="center">
              {employeeCode}
            </Row>
          );
        },
      },
      {
        title: text.EMPLOYEE_NAME,
        key: "associateName",
        dataIndex: "associateName",
        render: (associateName: string) => {
          return (
            <Row type="flex" justify="start">
              {associateName}
            </Row>
          );
        },
      },
      {
        title: text.DATE,
        key: "bookedDate",
        dataIndex: "bookedDate",
        align: "center",
        render: (bookedDate: string) => {
          return (
            <Row type="flex" justify="center">
              {bookedDate &&
                moment(bookedDate).format(dateCodes.DATE_MONTH_YEAR)}
            </Row>
          );
        },
      },
      {
        title: text.REMARKS,
        key: "exception",
        dataIndex: "exception",
        align: "center",
        render: (exception: string) => {
          if (exception != null) {
            return (
              <Row type="flex" justify="center">
                <Tooltip placement="topLeft" title={exception}>
                  <Icon type="exclamation-circle" className="pl-3 c-secondary" />
                </Tooltip>
              </Row>
            );
          } else {
            return (
              <Row type="flex" justify="center">
                <Icon type="minus" className="pl-3 c-secondary" />
              </Row>
            );
          }
        },
      },
      {
        title: text.STATUS,
        key: "status",
        dataIndex: "status",
        align: "center",
        render: (status: string) => {
          let colour = "#00b6ef";
          if (status === text.PENDING) {
            colour = "#fbb517";
          } else if (status === text.AUTHORIZED) {
            status = text.APPROVED;
            colour = "#02af9b";
          } else if (status === text.REJECTED) {
            colour = "#ee2e22";
          }
          return (
            <Row type="flex" justify="center">
              <Text style={{ color: colour }}>{status}</Text>
            </Row>
          );
        },
      },
      {
        title: text.ACTION,
        key: "action",
        dataIndex: "action",
        align: "center",
        fixed,
        render: (action: string, row: any, index: number) => {
          if (row.status === text.PENDING) {
            action = text.SELECT;
          } else if (row.status === text.AUTHORIZED) {
            action = text.APPROVED;
          } else if (row.status === text.REJECTED) {
            action = text.REJECTED;
          } else if (row.status) {
            action = row.status;
          } else {
            action = text.SELECT;
          }
          return (
            <Row type="flex" justify="start">
              <DropDown
                actionAlreadyTaken={row.status !== text.PENDING}
                menuList={getActions(row.status)}
                getSelectedValue={args => actionTaken(args, row)}
                dropdownText={action}
                index={index}
              />
            </Row>
          );
        },
      },
    ]
  return [
    {
      title: text.EMPLOYEE_CODE,
      key: "Employeecode",
      dataIndex: "Employeecode",
      align: "center",
      render: (employeeCode: string) => {
        return (
          <Row type="flex" justify="center">
            {employeeCode}
          </Row>
        );
      },
    },
    {
      title: text.EMPLOYEE_NAME,
      key: "associateName",
      dataIndex: "associateName",
      render: (associateName: string) => {
        return (
          <Row type="flex" justify="start">
            {associateName}
          </Row>
        );
      },
    },
    {
      title: text.IN_TIME,
      key: "intime",
      dataIndex: "intime",
      align: "center",
      render: (intime: number) => {
        return (
          <Row type="flex" justify="center">
            {intime &&
              intime
                .toString()
                .split(",")
                .join(", ")}
          </Row>
        );
      },
    },
    {
      title: text.OUT_TIME,
      key: "outtime",
      dataIndex: "outtime",
      align: "center",
      render: (outtime: number) => {
        return (
          <Row type="flex" justify="center">
            {outtime &&
              outtime
                .toString()
                .split(",")
                .join(", ")}
          </Row>
        );
      },
    },
    {
      title: text.LOCATION,
      key: "locationDescription",
      dataIndex: "locationDescription",
      align: "center",
      render: (locationDescription: string, row: TableData) => {
        return row.isGeoTagged ? (
          <Row type="flex" justify="center">
            {locationDescription}
          </Row>
        ) : "-";
      },
    },
    {
      title: text.IMAGE,
      key: "attachmentFile",
      dataIndex: "attachmentFile",
      align: "center",
      render: (attachmentFile: any, row: TableData) => {
        return row.isGeoTagged ? (
          <Row type="flex" justify="center">
            <Button onClick={() => showImage(attachmentFile.logInImage, attachmentFile.logOutImage)}>
              {text.VIEW_IMAGE}
            </Button>
          </Row>
        ) : "-"
      },
    },
    {
      title: text.TOTAL_HOURS_CLOCKED,
      key: "totalHoursClocked",
      dataIndex: "totalHoursClocked",
      align: "center",
      render: (totalHoursClocked: number) => {
        return (
          <Row type="flex" justify="center">
            {totalHoursClocked}
          </Row>
        );
      },
    },
    {
      title: text.BREAK_IN_MINS,
      key: "break_min",
      dataIndex: "break",
      align: "center",
      render: (break_min: number) => {
        return (
          <Row type="flex" justify="center">
            {break_min}
          </Row>
        );
      },
    },
    {
      title: text.REMARKS,
      key: "exception",
      dataIndex: "exception",
      align: "center",
      render: (exception: string) => {
        if (exception != null) {
          return (
            <Row type="flex" justify="start">
              <Tooltip placement="topLeft" title={exception}>
                <Icon type="exclamation-circle" className="pl-3 c-secondary" />
              </Tooltip>
            </Row>
          );
        } else {
          return (
            <Row type="flex" justify="start">
              <Icon type="minus" className="pl-3 c-secondary" />
            </Row>
          );
        }
      },
    },
    {
      title: text.STATUS,
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (status: string) => {
        let colour = "#00b6ef";
        if (status === text.PENDING) {
          colour = "#fbb517";
        } else if (status === text.AUTHORIZED) {
          status = text.APPROVED;
          colour = "#02af9b";
        } else if (status === text.REJECTED) {
          colour = "#ee2e22";
        }
        return (
          <Row type="flex" justify="center">
            <Text style={{ color: colour }}>{status}</Text>
          </Row>
        );
      },
    },
    {
      title: text.ACTION,
      key: "action",
      dataIndex: "action",
      align: "center",
      fixed,
      render: (action: string, row: any, index: number) => {
        if (row.status === text.PENDING) {
          action = text.SELECT;
        } else if (row.status === text.AUTHORIZED) {
          action = text.APPROVED;
        } else if (row.status === text.REJECTED) {
          action = text.REJECTED;
        } else if (row.status) {
          action = row.status;
        } else {
          action = text.SELECT;
        }
        return (
          <Row type="flex" justify="start">
            <DropDown
              actionAlreadyTaken={row.status !== text.PENDING}
              menuList={getActions(row.status)}
              getSelectedValue={args => actionTaken(args, row)}
              dropdownText={action}
              index={index}
            />
          </Row>
        );
      },
    },
  ];
}

export function summary(data: SummaryDetails[]) {
  if (data.length !== 0) {
    overviewData[0].value = data.filter(
      element => element.statusCode === text.ALL
    )[0].recordCount;
    overviewData[1].value = data.filter(
      element => element.statusCode === text.PENDING_CODE
    )[0].recordCount;
    overviewData[2].value = data.filter(
      element => element.statusCode === text.ACTION_TAKEN_CODE
    )[0].recordCount;
  }
  return overviewData;
}

export const overviewData: any = [
  {
    title: text.TOTAL_REQUESTS,
    value: 0,
  },
  {
    title: text.ACTION_PENDING,
    value: 0,
  },
  {
    title: text.ACTION_TAKEN,
    value: 0,
  },
];
export const colors: string[] = ["#00b6ef", "#fbb517", "#02af9b"];
