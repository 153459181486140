import React, { useEffect } from "react";
import { Col, Row } from "antd";
import LeaveCards from "components/LeavesCards";
import TimesheetCards from "components/TimesheetCards";
import ReimbursementCards from "components/ReimbursementCards";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { ModuleType } from "screens/home/store/home/constants";
import DashboardScreenDownloadReport from "components/DashboardScreenDownloadReport/DashboardScreenDownloadReport";
import { ContextAction } from "screens/home/store";
import { ContextActions } from "screens/home/store/home/actions";

export default function Dashboard(props: any) {
  const dispatch = useDispatch();
  const { contextStore } = useSelector((state: ApplicationState) => state);
  useEffect(() => {
    dispatch(ContextActions.fetchUserActivities.started());
  },[]);
  return (
    <Row type="flex" justify="space-around">
      <Col xs={{ span: 24 }} lg={{ span: 15 }}>
        <div className="p-2" id="section">
          {contextStore.allowedActivities[ModuleType.LEAVE] && <LeaveCards />}
          {contextStore.allowedActivities[ModuleType.ATTENDANCE] && (
            <TimesheetCards />
          )}
          {contextStore.allowedActivities[ModuleType.REIMBURSEMENT] && (
            <ReimbursementCards />
          )}
        </div>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 9 }}>
      {contextStore.allowedActivities[ModuleType.REPROTS] &&<DashboardScreenDownloadReport />}
      </Col>
    </Row>
  );
}
