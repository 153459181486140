import { WavierList } from "./type";

export const wavierList: WavierList[] = [
  {
    wavierDesc: "Full Wavier",
    wavierCode: "Y",
  },
  {
    wavierDesc: "None",
    wavierCode: "N",
  },
];

export const statusList = [
  {
    statusCode: "P",
    statusDesc: "Pending",
  },
  {
    statusCode: "A",
    statusDesc: "Authorized",
  },
  {
    statusCode: "WD",
    statusDesc: "Withdrawn",
  },
  {
    statusCode: "R",
    statusDesc: "Rejected",
  },
];