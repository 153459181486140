import * as XLSX from "xlsx";

export const parseExcelToJson = (
  file: File,
  writeObj: (obj: any) => void,
  column: {key: string, dataIndex: string}[],
  dataToFit: any[], 
  key: string,
) => {
  const reader = new FileReader();
  reader.onload = (e:any) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, {type: 'array'});
    const first_sheet_name = workbook.SheetNames[0];
    const sheet = workbook.Sheets[first_sheet_name];
    const obj = XLSX.utils.sheet_to_json(sheet, {defval: null, raw: false});
    const newObj = fitObject(column, obj);
    if(dataToFit && key) {
      const finalObj = copyValuesOfObjectList(newObj, dataToFit, key);
      writeObj(finalObj);
    }
    else
      writeObj(newObj);
  };
  reader.readAsArrayBuffer(file);
};

const copyValuesOfObjectList = (fromList: any[], toList: any[], key: string) => {
  const newList: any[] = [];
  fromList.map((fromObj: any) => {
    const toObj = toList.find((item) => item[key] === fromObj[key]);
    const temp = {...toObj, ...fromObj};
    newList.push(temp);
  });
  return newList;
};

const fitObject = (column: {key: string, dataIndex: string}[], data: any) => {
  const finalObj: any[] = [];
  data.map((eachData: any) => {
    const temp: any = {};
    column.map((columnObj: any) => {
      temp[columnObj.dataIndex] = eachData[columnObj.key];
    });
    finalObj.push(temp);
  });
  return finalObj;
};
