import React from "react";
import { Row, Button, Skeleton, Tooltip } from "antd";
import { INetPayDetailsProps } from "./type";
import { statusColor } from "./helper";
import { style } from "styles/Fonts";
import { refreshImage, upArrowIcon, downArrowIcon } from "AssetHelper";
import text from "text";
import moment from "moment";

export default function NetPayDetails(props: INetPayDetailsProps) {
  const {
    data,
    onRefresh,
    processDescription,
    refreshing,
    onViewMore,
    showEmployeeDetails,
    index,
  } = props;

  const viewMore = () => {
    onViewMore(index);
  };

  const refresh = () => {
    onRefresh(index);
  };
  return (
    <Row>
      <Row type="flex" className="justify-content-between pb-3">
        <span className="ff-secondary" style={style.medium}>
          {text.DETAILS}
        </span>
        <Row type="flex">
          <span className="pr-2 ff-primary">{text.STATUS}:</span>
          <Row
            style={{
              color: statusColor(data.statusCode || ""),
              border: `1px solid ${statusColor(data.statusCode || "")}`,
            }}
            className="px-1">
            {data.statusDescription}
          </Row>
          <Tooltip placement="bottom" title={"Refresh"}>
            <Button
              style={{ border: "1px solid	#D3D3D3", borderRadius: 0 }}
              className="px-0 h-5 mx-2"
              onClick={refresh}>
              <img src={refreshImage} className="px-1 pb-1 h-3" />
            </Button>
          </Tooltip>
          <Button
            style={{ border: "1px solid	#D3D3D3", borderRadius: 0 }}
            className="px-0 h-5"
            onClick={viewMore}>
            <img
              src={showEmployeeDetails ? upArrowIcon : downArrowIcon}
              className="px-1 pb-1 h-2"
            />
          </Button>
        </Row>
      </Row>
      <Row
        type="flex"
        className="justify-content-between pt-3"
        style={{ borderTop: "1px solid #D3D3D3", wordBreak: "break-word" }}>
        <Row className=" w-md-15 w-sm-100">
          <Row className="pb-1" style={style.xsmall}>
            {text.REFERENCE_NUMBER}
          </Row>
          <Row
            style={{ border: "1px solid #D3D3D3", wordBreak: "break-word" }}
            className="p-1">
            {data.referenceNumber}
          </Row>
        </Row>
        <Row className="w-md-15 w-sm-100">
          <Row className="pb-1" style={style.xsmall}>
            {text.NET_PAY_DATE}
          </Row>
          <Row
            style={{ border: "1px solid #D3D3D3", wordBreak: "break-word" }}
            className="p-1">
            {moment(data.netPayDate).format("Do MMM YYYY")}
          </Row>
        </Row>
        <Row className="w-md-15 w-sm-100">
          <Row className="pb-1" style={style.xsmall}>
            {text.NET_PAY_VALUE}
          </Row>
          <Row
            style={{ border: "1px solid #D3D3D3", wordBreak: "break-word" }}
            className="p-1">
            {data.netPayValue}
          </Row>
        </Row>
        <Row className="w-md-15 w-sm-100">
          <Row className="pb-1" style={style.xsmall}>
            {text.EMPLOYEE_COUNT}
          </Row>
          <Row
            style={{ border: "1px solid #D3D3D3", wordBreak: "break-word" }}
            className="p-1">
            {data.employeeCount}
          </Row>
        </Row>
        <Row className="w-md-15 w-sm-100">
          <Row className="pb-1" style={style.xsmall}>
            {text.PROCESS_STATUS_DESCRIPTION}
          </Row>
          {refreshing ? (
            <Skeleton paragraph={{ rows: 0 }} active={true} loading={true} />
          ) : (
            <Row
              style={{ border: "1px solid #999", wordBreak: "break-word" }}
              className={"p-1"}>
              {processDescription || "N/A"}
            </Row>
          )}
        </Row>
      </Row>
    </Row>
  );
}
