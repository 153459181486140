import text from "text";
import React from "react";
import { Row, Input } from "antd";
import { NetPayEmployeeList, NetPayPerEmployeeBreakdown } from "models/netpay.data";
import { statusColor, statusDescription } from "components/NetPayDetails/helper";

export const columnForExcel = () => {
  const dataColumns = [
    {
      key: "Employee Code",
      dataIndex: "employeeCode",
    },
    {
      key: "Emloyee Name",
      dataIndex: "employeeName",
    },
    {
      key: "Department Code",
      dataIndex: "departmentCode",
    },
    {
      key: "Dopartment Description",
      dataIndex: "departmentDescription",
    },
    {
      key: "Client PO Number",
      dataIndex: "clientPONumber",
    },
    {
      key: "Paid Days",
      dataIndex: "paidDays",
    },
    {
      key: "CTC Value",
      dataIndex: "ctcValue",
    },
  ];
  return dataColumns;
};

export const columns = (dataList: NetPayPerEmployeeBreakdown) => {
  let dataColumns : Array<{key: string, dataIndex: string}> = [
    {
      key: "Employee Code",
      dataIndex: "employeeCode",
    },
    {
      key: "Employee Name",
      dataIndex: "employeeName",
    },
    {
      key: "Department",
      dataIndex: "department",
    },
    {
      key: "State",
      dataIndex: "ptState",
    },
    {
      key: "Work Location",
      dataIndex: "workLocation",
    },
    {
      key: "Client Name",
      dataIndex: "clientName",
    },
    {
      key: "Payment Mode",
      dataIndex: "paymentMode",
    },
    {
      key: "Bank",
      dataIndex: "bankName",
    },
    {
      key: "Bank A/C No",
      dataIndex: "accountNumber",
    },
    {
      key: "ESI No",
      dataIndex: "esiNumber",
    },
    {
      key: "PAN Number",
      dataIndex: "panNumber",
    },
    {
      key: "Age of Employee",
      dataIndex: "age",
    },
    {
      key: "Gender",
      dataIndex: "gender",
    },
    {
      key: "Designation",
      dataIndex: "designation",
    },
    {
      key: "Cost Center Code",
      dataIndex: "costCenterCode",
    },
    {
      key: "CC Description",
      dataIndex: "costCenter",
    },
    {
      key: "Client Code",
      dataIndex: "clientCode",
    },
    {
      key: "UAN Number",
      dataIndex: "uanNumber",
    },
    {
      key: "Date of Joining",
      dataIndex: "dateOfJoining"
    },
    {
      key: "Separation date",
      dataIndex: "dateofSeparation",
    },
    {
      key: "PF Number",
      dataIndex: "pfNumber",
    },
    {
      key: "Payable Days",
      dataIndex: "paidDays",
    },
  ]
  dataList.data.parameterList?.map((item) => {
    dataColumns.push({key: item.parameterCaption || "", dataIndex: item.parameterName || ""})
  })
  dataColumns.push({key: "CTC Value", dataIndex: "ctcValue"});
  dataColumns.push({key: "Service Fee", dataIndex: "serviceFee"});
  dataColumns.push({key: "Sub Total", dataIndex: "subTotal"});
  dataColumns.push({key: "GST Value", dataIndex: "gstValue"});
  dataColumns.push({key: "Invoice", dataIndex: "invoiceTotal"});
  return dataColumns;
}

export function column(
  onRemarksChange: (value: string, row: NetPayEmployeeList) => void,
  processStage: string
) {
  const fixed = window.innerWidth > 600 ? "right" : "";
  return [
    {
      title: "Employee Code",
      key: "employeeCode",
      dataIndex: "employeeCode",
      render: (employeeCode: string) => {
        return (
          <Row type="flex" justify="start">
            {employeeCode}
          </Row>
        );
      },
    },
    {
      title: "Employee Name",
      key: "employeeName",
      dataIndex: "employeeName",
      render: (employeeName: string) => {
        return (
          <Row type="flex" justify="start">
            {employeeName}
          </Row>
        );
      },
    },
    {
      title: "Department Code",
      key: "departmentCode",
      dataIndex: "departmentCode",
      render: (departmentCode: string) => {
        return (
          <Row type="flex" justify="start">
            {departmentCode}
          </Row>
        );
      },
    },
    {
      title: "Department Description",
      key: "departmentDescription",
      dataIndex: "departmentDescription",
      // align:"center",
      render: (departmentDescription: string) => {
        return (
          <Row type="flex" justify="start">
            {departmentDescription}
          </Row>
        );
      },
    },
    {
      title: "Client PO Number",
      key: "clientPONumber",
      dataIndex: "clientPONumber",
      // align:"center",
      render: (clientPONumber: string) => {
        return (
          <Row type="flex" justify="start">
            {clientPONumber || "N/A"}
          </Row>
        );
      },
    },
    {
      title: "Paid Days",
      key: "paidDays",
      dataIndex: "paidDays",
      // align:"center",
      render: (paidDays: string) => {
        return (
          <Row type="flex" justify="center">
            {paidDays}
          </Row>
        );
      },
    },
    {
      title: "CTC Values",
      key: "ctcValue",
      dataIndex: "ctcValue",
      // align:"center",
      render: (ctcValue: string) => {
        return (
          <Row type="flex" justify="center">
            {ctcValue}
          </Row>
        );
      },
    },
    {
      title: "Status",
      key: "statusCodeEmployee",
      dataIndex: "statusCodeEmployee",
      // align:"center",
      render: (statusCodeEmployee: string) => {
        return (
          <Row type="flex" justify="center" style={{color: statusColor(statusCodeEmployee)}}>
            {statusDescription(statusCodeEmployee)}
          </Row>
        );
      },
    },
    {
      title: "Remarks",
      key: "referenceRemarks",
      dataIndex: "referenceRemarks",
      // align:"center",
      render: (referenceRemarks: string, row: NetPayEmployeeList) => {
        return (
          <Input
            value={referenceRemarks}
            disabled={processStage !== "FR"}
            onChange={e => {
              onRemarksChange(e.target.value, row);
            }}
          />
        );
      },
    },
  ];
}
