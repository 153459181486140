import actionCreatorFactory from "typescript-fsa";
import {
  AssociatesLeaveDetails,
  Approverresponse,
  Approverrequest,
  ErrorMsg,
  LeaveApproveResponse,
} from "models/leave.data";
import { FiltersInterface } from "screens/leave/type";

const actionCreator = actionCreatorFactory("LEAVE");

export class LeaveActions {
  public static getAssociatesLeaveDetails = actionCreator.async<
    FiltersInterface,
    AssociatesLeaveDetails,
    Error
  >("GET_ASSOCIATES_LEAVE_DETAILS");
  public static getByName = actionCreator<string>("GET_BY_NAME");
}
