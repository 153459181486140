import { saveAs } from "file-saver";
import * as ExcelJs from "exceljs";

export const downloadExcel = (
  column: any,
  dataSource: any,
  fileName: string | undefined | null,
  row: any,
  validationList: {col: string, validation: {}}[],
  inputList: Array<string>,
  positionList?: string[],
  genderList?: string[],
  storeBranchList?: string[],
  countryList?: string[],
  skillTypeList?: string[],
  pfApplicabilityList?: string[],
  compensationUOMList?: string[],
  processApplicabilityList?: string[],
  departmentList?: string[],
) => {
  const workbook = new ExcelJs.Workbook();
  const worksheet = workbook.addWorksheet("My Sheet");
  worksheet.columns = column;
  row.map((item: any) => {
    worksheet.addRow(item);
  });
  inputList.forEach((item, index) => {
    worksheet.getCell(`CD${index+100}`).value = item;
  });
  positionList?.forEach((item, index) => {
    worksheet.getCell(`EF${index+100}`).value = item;
  });
  genderList?.forEach((item, index) => {
    worksheet.getCell(`GH${index+100}`).value = item;
  });
  countryList?.forEach((item, index) => {
    worksheet.getCell(`IJ${index+100}`).value = item;
  });
  storeBranchList?.forEach((item, index) => {
    worksheet.getCell(`KL${index+100}`).value = item;
  });
  skillTypeList?.forEach((item, index) => {
    worksheet.getCell(`MN${index+100}`).value = item;
  });
  pfApplicabilityList?.forEach((item, index) => {
    worksheet.getCell(`QR${index+100}`).value = item;
  });
  compensationUOMList?.forEach((item, index) => {
    worksheet.getCell(`UV${index+100}`).value = item;
  });
  processApplicabilityList?.forEach((item, index) => {
    worksheet.getCell(`YZ${index+100}`).value = item;
  });
  departmentList?.forEach((item, index) => {
    worksheet.getCell(`AB${index+100}`).value = item;
  });
  validationList.map((item: any) => {
    for (var i = 2; i <= row.length+100; i++) {
      worksheet.getCell(`${item.col}${i}`).dataValidation = item.validation;
    }
  });
  workbook.xlsx.writeBuffer().then(data => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "dataSheet.xlsx");
  });
};

export const getExcelParsabelObj = (column: any, data: any) => {
  let excelObjList: any[] = [];
  data.map((eachData: any) => {
    let excelObj: any = {};
    column.map((columnObj: any) => {
      excelObj[columnObj.key] = eachData[columnObj.dataIndex];
    });
    excelObjList.push(excelObj);
  });
  return excelObjList;
};
