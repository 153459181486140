import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import {
  WorkExperienceDetails,
  getRequestProfileDetails,
} from "models/profile.data";

export class WorkExperienceService {
  public getWorkExperience(
    requestParams: getRequestProfileDetails,
  ): Promise<WorkExperienceDetails> | WorkExperienceDetails {
    const service: ApiService = new ApiService(EndPoints.profileWorkExperience);
    return service.get({
      dynamicQueryParams: [{ employeeCode: requestParams.employeeCode }],
    });
  }
}

const workExperienceService = new WorkExperienceService();
export default workExperienceService;
