import { Row, Select } from 'antd'
import React from 'react'
import { SearchableDropdownProps } from './type';

const { Option } = Select;

function SearchableDropdown<T>(props: SearchableDropdownProps<T>) {
    const { 
        allowClear,
        isDisabled,
        isMultiple,
        label,
        optionLableExtractor,
        list,
        maxTagCount,
        onSelect,
        placeholder,
        value,
        optionKeyExtractor,
        width,
        dropdownWidth,
        fullWidth,
        labelclassName
    } = props;

    const setSelectedValue = (value: string | string[]) => {
        if(isMultiple) {
            // @ts-ignore
            const selectedItems = list.filter((item) => value.includes(optionKeyExtractor(item)));
            onSelect(selectedItems)
        }
        else {
            const selectedItems = list?.filter((item) => value === optionKeyExtractor(item));
            onSelect(selectedItems? selectedItems[0] : undefined);
        }
    }

    return (
        <Row type="flex" className="m-md-0 my-1 mr-1">
          {label && labelclassName ?
<div className={labelclassName?labelclassName:'w-100 w-md-auto my-2 mr-2'}>{label} :</div>
:
           label? <div className="w-100 w-md-auto my-2 mr-2">{label?label+'':''}</div>:''
}
            <Select
                mode={isMultiple ? "multiple" : "default"}
                showSearch={list && list.length > 10}
                optionFilterProp="children"
                allowClear={allowClear}
                style={{ width: width ? width : 200}}
                maxTagCount={maxTagCount ? maxTagCount : 0}
                onChange={setSelectedValue}
                value={value ? value : undefined}
                disabled={isDisabled}
                placeholder={placeholder ? placeholder : "Select"}
                dropdownStyle={{ minWidth: dropdownWidth ? dropdownWidth : 200 }}
                // getPopupContainer={t => t.parentNode as HTMLElement}
                className={fullWidth ? `w-${fullWidth}`: ""}
            >
                {list?.map((item : T) => (
                    <Option value={optionKeyExtractor(item)} key={optionKeyExtractor(item)}>
                        {optionLableExtractor(item)}
                    </Option>
                ))}
            </Select>
        </Row>
    )
}

export default SearchableDropdown
