import React from "react";
import { Row, Table, Button } from "antd";
import text from "text";
import DownloadExcel from "components/DownloadExcel/DownloadExcel";
import { INetPayEmployeeDetailsProps } from "./type";
import { style } from "styles/Fonts";
import { columnForExcel, column } from "./helper";
import {
  LoaderType,
  NET_PAY_DETAILS_SCREEN,
  NET_PAY_MAIN_SCREEN,
} from "screens/netPay/constant";

export default function NetPayEmployeeDetails(
  props: INetPayEmployeeDetailsProps
) {
  const {
    employeeList,
    loadingData,
    selectedData,
    onChangeRowSelection,
    currentPage,
    onPageChange,
    actionTaken,
    processRemarks,
    onRemarksChange,
    savingData,
    index,
    processStage,
    onDownloadExcel
  } = props;

  const rowSelection = () => {
    return {
      selectedRowKeys: selectedData,
      onChange: onChangeRowSelection,
    };
  };

  const allActionTakenButtonDisabled = () => {
    if (
      processStage !== "FR" ||
      savingData.loading ||
      employeeList.length === 0
    )
      return true;
    return false;
  };

  const selectedActionTakenButtonDisabled = () => {
    if (
      selectedData.length === 0 ||
      processStage !== "FR" ||
      savingData.loading ||
      employeeList.length === 0
    )
      return true;
    return false;
  };

  const onRejectAll = () => {
    actionTaken(text.REJECT_ITEM, NET_PAY_DETAILS_SCREEN, index);
  };

  const onRejectSelected = () => {
    actionTaken(text.REJECT_ITEM, NET_PAY_MAIN_SCREEN, index);
  };

  const onApproveAll = () => {
    actionTaken(text.APPROVE_ITEM, NET_PAY_DETAILS_SCREEN, index);
  };

  const onApproveSelected = () => {
    actionTaken(text.APPROVE_ITEM, NET_PAY_MAIN_SCREEN, index);
  };

  const downloadExcel = () => {
    onDownloadExcel(index);

  }

  return (
    <Row>
      <Row type="flex" className="pt-4">
        <Row className="w-80">
          <Row className="pb-1 ff-secondary c-disabled" style={style.xsmall}>
            {text.PROCESS_REMARKS}
          </Row>
          <Row
            style={{ border: "1px solid #999", wordBreak: "break-word" }}
            className="p-1">
            {processRemarks}
          </Row>
        </Row>
        <Row type="flex" className="justify-content-end w-20">
          <Row className="w-75">
            <Button
              className={allActionTakenButtonDisabled() ? "c-white mb-1 w-100 bg-disabledGreen" :"bg-success mb-1 c-white w-100"}
              style={{ wordBreak: "break-word" }}
              onClick={onApproveAll}
              loading={
                savingData.loading &&
                savingData.loaderType === LoaderType.APPROVE_ALL
              }
              disabled={allActionTakenButtonDisabled()}>
              {text.APPROVE_ALL}
            </Button>
          </Row>
          <Row className="w-75">
            <Button
              className={
                allActionTakenButtonDisabled()
                  ? "c-white px-3 w-100 bg-disabledRed"
                  : "bg-primary c-white px-3 w-100"
              }
              onClick={onRejectAll}
              style={{ wordBreak: "break-word" }}
              loading={
                savingData.loading &&
                savingData.loaderType === LoaderType.REJECT_ALL
              }
              disabled={allActionTakenButtonDisabled()}>
              {text.REJECT_ALL}
            </Button>
          </Row>
        </Row>
      </Row>
      <Row style={{ borderTop: "1px solid #D3D3D3" }} className="my-4"></Row>
      <Row type="flex" justify="space-between">
        <span className="ff-secondary align-middle" style={style.medium}>
          {text.EMPLOYEE_DETAILS}
        </span>
        <Row>
          <DownloadExcel
          onClick={downloadExcel}
            lable={"Download Net Pay Register"}
            column={columnForExcel()}
            isDisable={employeeList.length && processStage !== "RJ" ? false : true}
          />
        </Row>
      </Row>
      <Table
        columns={column(onRemarksChange, processStage)}
        rowSelection={processStage== "FR" ?  rowSelection() : undefined}
        dataSource={employeeList}
        rowKey="employeeCode"
        scroll={{ x: true }}
        pagination={{
          pageSize: 5,
          current: currentPage,
          onChange: onPageChange,
        }}
        loading={loadingData}
      />
      {employeeList.length && (
        <Row type="flex" justify="end" className="flex-row">
          {/* <Button
            className={selectedActionTakenButtonDisabled()? "c-white mx-3 w-15 bg-disabledGreen" : "bg-success c-white mx-3 w-15"}
            onClick={onApproveSelected}
            disabled={selectedActionTakenButtonDisabled()}
            style={{ wordBreak: "break-word" }}
            loading={
              savingData.loading &&
              savingData.loaderType === LoaderType.APPROVE_SEELCTED
            }>
            {text.APPROVE}
          </Button> */}
          <Button
            className={
              selectedActionTakenButtonDisabled()
                ? "c-white px-3 w-15 bg-disabledRed"
                : "bg-primary c-white px-3 w-15"
            }
            onClick={onRejectSelected}
            disabled={selectedActionTakenButtonDisabled()}
            style={{ wordBreak: "break-word" }}
            loading={
              savingData.loading &&
              savingData.loaderType === undefined
            }>
            {text.REJECT}
          </Button>
        </Row>
      )}
    </Row>
  );
}
