import { SummaryCardObj } from "components/ResignationSummaryCard/type";
import { SummaryList } from "models/resignation.data";
import text from "text";

export const defaultList = {
  data: {
    customDateOutput1: "",
    customDateOutput2: "",
    customIntegerOutput1: 0,
    customIntegerOutput2: 0,
    customNumericOutput1: 0,
    customNumericOutput2: 0,
    customTextOutput1: "",
    customTextOutput2: "",
    customTextOutput3: "",
    customTextOutput4: "",
    employeeList: [],
  },
  message: "",
};

export const defaultMetadata = {
  data: {
    customDateOutput1: "",
    customDateOutput2: "",
    customIntegerOutput1: 0,
    customIntegerOutput2: 0,
    customNumericOutput1: 0,
    customNumericOutput2: 0,
    customTextOutput1: "",
    customTextOutput2: "",
    customTextOutput3: "",
    customTextOutput4: "",
    employerReasonsList: [],
    separationFlagList: [],
    statusList: [],
  },
  message: "",
};

export const initialFilterValues = {
  statusType: "",
  startDate: null,
  endDate: null,
};

export const summaryCardValue: SummaryCardObj[] = [
  {
    title: text.TOTAL_REQUESTS,
    value: 0,
    color: "info",
  },
  {
    title: text.ACTION_PENDING,
    value: 0,
    color: "warning",
  },
  {
    title: text.ACTION_TAKEN,
    value: 0,
    color: "success",
  },
];

export const getSummaryValue = (data: SummaryList[]) => {
  if (data.length) {
    summaryCardValue[0].value = data.filter(
            element => element.statusCode === "ALRQ",
        )[0].recordCount;
    summaryCardValue[1].value = data.filter(
            element => element.statusCode === "PEND",
        )[0].recordCount;
    summaryCardValue[2].value = data.filter(
            element => element.statusCode === "ACTD",
        )[0].recordCount;
  }
  return summaryCardValue;
};

export const ALL_REQUEST_CODE = "ALRQ";