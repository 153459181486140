import React, { useEffect, useState } from "react";
import { Row } from "antd";
import AnalyticsSectionCards from "components/AnalyticsSectionCards";
import { analyticsSectionDetails } from "./constant";
import { CLMGR_INTERNAL_ROLE } from "screens/analytics/constant";
import AnalyticsFilter from "components/AnalyticsScreenFilter/AnalyticsScreenFilters";
import Loader from "components/Loader";
import moment from "moment";
import { DateFilters } from "./type";
import AnalyticsService from "./service";
import {
  OnboardingMetaDataDetails,
  ReportFilterList,
} from "models/analytics.data";
import text from "text";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { CLI_INTERNAL_ROLE } from "screens/bulkAttendance/constant";

export default function Analytics() {
  const [dateFilters, setDateFilters] = useState<DateFilters>({
    toDate: "",
    fromDate: "",
  });

  const [selectedFilter, setSelectedFilter] = useState<string>("CMONT");
  const [isMetaDataLoading, setIsMetaDataLoading] = useState<boolean>(true);
  const [metaDataAnalytics, setMetaDataDetails] = useState<
    OnboardingMetaDataDetails
  >();

  const { contextStore } = useSelector((state: ApplicationState) => state);

  const lastday = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const isInternalRoleClient = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === CLI_INTERNAL_ROLE
  )
    ? true
    : false;

  const getMetaDataDetails = async (params: {
    internalRole: string;
    apiCode: string;
    callingFrom: string;
  }) => {
    try {
      setIsMetaDataLoading(true);
      const response = await AnalyticsService.getOnboardingMetaDataAnalyticsList(
        params
      );
      setMetaDataDetails(response);
      response.data.reportFilterList.map(item => {
        if (item.filterDefaultFlag === "Y") {
          setSelectedFilter(item.filterByCode);
          setDateFilters({ fromDate: item.fromDate, toDate: item.toDate });
        }
      });
    } catch (err) {
    } finally {
      setIsMetaDataLoading(false);
    }
  };

  useEffect(() => {
    getMetaDataDetails({
      internalRole: isInternalRoleClient ? CLI_INTERNAL_ROLE : CLMGR_INTERNAL_ROLE,
      apiCode: "",
      callingFrom: "",
    });
  }, []);

  const onDateChange = (
    dateFilter: {
      fromDate: moment.Moment | null;
      toDate: moment.Moment | null;
    },
    filterCode: string
  ) => {
    if (dateFilter.toDate === null) {
      const year = dateFilter.fromDate?.year();
      const month = dateFilter.fromDate?.month();
      const monthStr = month! < 9 ? `0${month! + 1}` : `${month! + 1}`;
      const manipulatedEndDate = `${year}-${monthStr}-${String(
        lastday(year!, month!)
      )}`;
      setDateFilters({
        fromDate: `${year}-${monthStr}-01`,
        toDate: manipulatedEndDate,
      });
      setSelectedFilter("");
    } else if (filterCode.length === 0 && dateFilter.toDate !== null) {
      const year = dateFilter.toDate?.year();
      const month = dateFilter.toDate?.month();
      const monthStr = month! < 9 ? `0${month! + 1}` : `${month! + 1}`;
      const manipulatedEndDate = `${year}-${monthStr}-${String(
        lastday(year!, month!)
      )}`;
      setDateFilters({ ...dateFilters, toDate: manipulatedEndDate });
      setSelectedFilter("");
    } else if (filterCode.length > 0) {
      metaDataAnalytics?.data.reportFilterList.map((item: ReportFilterList) => {
        if (item.filterByCode === filterCode) {
          setDateFilters({ fromDate: item.fromDate, toDate: item.toDate });
          setSelectedFilter(filterCode);
        }
      });
    }
  };
  const renderCards = () => {
    return analyticsSectionDetails.map((item, index) => (
      <AnalyticsSectionCards
        key={index}
        analyticsSectionDetails={item}
        fromDate={dateFilters.fromDate}
        toDate={dateFilters.toDate}
      />
    ));
  };

  // if (!contextStore.userDetails.data.find(
  //   (item: any) => item.internalRole === CLMGR_INTERNAL_ROLE,
  // ))
  //   return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <div>
      {isMetaDataLoading ? (
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="min-vh-content"
        >
          <Loader />
        </Row>
      ) : (
        <Row>
          <AnalyticsFilter
            onDateChange={onDateChange}
            filterDetails={metaDataAnalytics?.data!}
            selectedFilterType={selectedFilter}
            selectedDateFilters={dateFilters}
          />
          {renderCards()}
        </Row>
      )}
    </div>
  );
}
