import { NetPaySelectedFilters } from "components/NetPayFilter/type";

export const CLI_INTERNAL_ROLE = "CLI";
export const CLMGR_INTERNAL_ROLE = "CLMGR";
export const YES_CODE = "Y";
export const NO_CODE = "N";
export const NET_PAY_MAIN_SCREEN = "NETPAYMAIN";
export const NET_PAY_DETAILS_SCREEN = "NETPAYDETAIL";

export const initialFilteredValue: NetPaySelectedFilters = {
  netPayType: "",
  payrollCalendarCode: "",
  payrollPeriod: "",
  processPeriodNumber: "",
  statusType: "",
};

export enum LoaderType {
  APPROVE_SEELCTED = "approveSelected",
  APPROVE_ALL = "approveAll",
  REJECT_ALL = "rejectAll",
  REJECT_SELECTED = "rejectSelected",
}

export const initialValue = {
  isLoading: false,
  details: {
    data: {},
    message: "",
  },
};

export const processDetailsInitialValue = {
  isLoading: false,
  details: [{
    data: {},
    message: "",
  }],
}

export const metaDataInitialValue = {
  isLoading: true,
  metaData: {
    data: {},
    message: "",
  },
};

export const popUpInitialValue = {
  visible: false,
  action: "",
  data: {
    screenCalling: "",
    index: 0,
  },
};

export const postInitialValue = {
  isLoading: false,
  loaderType: undefined,
  details: {
    data: {},
    message: "",
  },
};
