import { LeaveActions } from "./actions";
import leaveService from "./LeaveService";
import { put, takeLatest } from "redux-saga/effects";
import { AssociatesLeaveDetails } from "../../models/leave.data";

function* getAssociatesData(action: any) {
  const { payload } = action;
  try {
    const response: AssociatesLeaveDetails = yield leaveService.getAssociateLeaves(
      payload,
    );
    yield put(
      LeaveActions.getAssociatesLeaveDetails.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      LeaveActions.getAssociatesLeaveDetails.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* LeaveManagementSaga() {
  yield takeLatest(
    LeaveActions.getAssociatesLeaveDetails.started,
    getAssociatesData,
  );
}

export default LeaveManagementSaga;
