import actionCreatorFactory from "typescript-fsa";
import { getRequestData } from "models/timesheet.data";
import { SummaryResponse } from "models/dashboard.data";

const actionCreator = actionCreatorFactory("LEAVE_SUMMARY");

export class DashboardActions {
  public static getLeaveSummary = actionCreator.async<
    getRequestData,
    SummaryResponse,
    Error
  >("GET_LEAVE_SUMMARY");
  public static getTimesheetSummary = actionCreator.async<
    getRequestData,
    SummaryResponse,
    Error
  >("GET_TIMESHEET_SUMMARY");
  public static getReimbursementSummary = actionCreator.async<
    getRequestData,
    SummaryResponse,
    Error
  >("GET_REIMBURSEMENT_SUMMARY");
}
