import actionCreatorFactory from "typescript-fsa";
import {
  UserContextData,
  UserDetails,
} from "../../../../models/userGontext.data";
import { RelativePathResponse } from "models/relativePath.data";

const actionCreator = actionCreatorFactory("CONTEXT");

export class ContextActions {
  public static getContextDetails = actionCreator.async<
    void,
    UserContextData,
    Error
  >("GET_CONTEXT_DETAILS");
  public static getUserDetails = actionCreator.async<void, UserDetails, Error>(
    "GET_USER_DETAILS",
  );
  public static fetchCandidateRelativePathList = actionCreator.async<
    void,
    RelativePathResponse,
    Error
  >("GET_RELATIVE_API");
  public static fetchUserActivities = actionCreator.async<
    void,
    { [key: string]: string },
    {}
  >("GET_USER_ACTIVITY");
}
