import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { LineManagerDetailsResponse, LineManagerMetadataResponse, ReporteesList } from "models/associate.data";
import { GetLineManagersAssociateParams, GetLineManagersMetadataParams } from "./Type";

export class AssociateService {
  public getRepoteesList(): Promise<ReporteesList> | ReporteesList {
    const service: ApiService = new ApiService(EndPoints.repotees);
    return service.get({});
  }

  public getLineMangerMetadata(params: GetLineManagersMetadataParams) :
   Promise<LineManagerMetadataResponse> | LineManagerMetadataResponse {
    const service: ApiService = new ApiService(
      EndPoints.GET_LINE_MANAGER_METADATA,
    );
    return service.get({
      dynamicQueryParams: [{
        internalRole: params.internalRole,
        customTextInput1: params.customTextInput1,
      }],
    });
  }

  public getLineMangersAssociateData(params: GetLineManagersAssociateParams) :
   Promise<LineManagerDetailsResponse> | LineManagerDetailsResponse {
    const service: ApiService = new ApiService(
      EndPoints.GET_LINE_MANAGER_ASSOCIATE_DETAILS,
    );
    return service.get({
      dynamicQueryParams: [{
        internalRole: params.internalRole,
        existingLineManagerCode: params.existingLineManagerCode,
        customTextInput1: params.customTextInput1,
      }],
    });
  }
}

export default new AssociateService();
