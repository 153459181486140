import React, { useState } from "react";
import { Button, Card, Col, List, Modal, Row, Tag, Typography } from "antd";
import { getPDFURI } from "common/Utils.tsx/Download";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import { ModalType } from "components/Modal/type";
import { style } from "styles/Fonts";
import text from "text";
import { InvoiceCardProps } from "./type";
import PreviewDocument from "./PreviewDocument";
import { getObjectList, ListItem, renderDownloadModal } from "./helper";

const { Text } = Typography;

function InvoiceCard(props: InvoiceCardProps) {
  const { invoiceDetails } = props;

  const [isDownloading, setIsDownloading] = useState(false);

  const [previewDoc, setPreviewDoc] = useState(false);

  const getListHeader = () => (
    <Row type="flex" justify="space-between">
      <Col>
        <span style={style.medium} className="ff-secondary">
          {text.INVOICE_ID}:{" "}
          {invoiceDetails.invoiceNumber ? invoiceDetails.invoiceNumber : "-"}
        </span>
      </Col>
      <Col>
        {text.STATUS} :
        <Tag color="#6BB749" className="ml-1">
          <Text strong className="c-white">
            {invoiceDetails.invoiceStatus ? invoiceDetails.invoiceStatus : "-"}
          </Text>
        </Tag>
      </Col>
    </Row>
  );

  const downloadFile = async () => {
    try {
      setIsDownloading(true);
      if(invoiceDetails.documentList) {
        for(const item of invoiceDetails.documentList){
          if (!item.documentFileName) {
            continue;
          }
          await getPDFURI(
            item.documentFileName as string,
            item.customTextOutputDL1 as string,
          ).then(response => {
            if (response !== 200) {
              ModalPopUp({
                type: ModalType.error,
                title: text.ALERT.toUpperCase(),
                description: text.NOT_ABLE_TO_DOWNLOAD,
              });
            }
          });
        }
      }
    } catch (err) {
    } finally {
      setIsDownloading(false);
    }
  };

  const renderListFooter = () => {
    return (
      <Row type="flex" justify="end" className="my-1" gutter={10}>
        <Button
          disabled={invoiceDetails.documentList.length === 0}
          onClick={() => downloadFile()}
          className="mr-4"
        >
          {text.DOWNLOAD_DOCUMENTS}
        </Button>
        <Button
          type="primary"
          onClick={() => setPreviewDoc(!previewDoc)}
          disabled={invoiceDetails.documentList.length === 0}
        >
          {text.PREVIEW_INVOICES}
        </Button>
      </Row>
    );
  };

  const renderPreviewDoc = () => {
    return (
      <Modal
        visible={previewDoc}
        footer={null}
        onCancel={() => setPreviewDoc(false)}
        closable={false}
        width={800}
      >
        <PreviewDocument documentList={invoiceDetails.documentList} />
      </Modal>
    );
  };

  return (
    <>
      {renderDownloadModal(isDownloading)}
      {renderPreviewDoc()}
      <Card className="my-4">
        <List
          header={getListHeader()}
          className="mt-0"
          dataSource={getObjectList(invoiceDetails)}
          renderItem={item => (
            <ListItem label={item.label} value={item.value} />
          )}
          footer={renderListFooter()}
        />
      </Card>
    </>
  );
}

export default InvoiceCard;
