import actionCreatorFactory from "typescript-fsa";
import {
  AssociatesTimesheetDetails,
  Authrejectrequest,
  ErrorMsg,
} from "models/timesheet.data";
import { TimesheetFilterInterface } from "screens/timesheet/type";

const actionCreator = actionCreatorFactory("TIMESHEET");

export class TimesheetActions {
  public static getAssociatesTimesheetDetails = actionCreator.async<
    TimesheetFilterInterface,
    AssociatesTimesheetDetails,
    Error
  >("GET_ASSOCIATES_TIMESHEET_DETAILS");
}
