import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import {
  LeaveSummary,
  TimesheetSummary,
  ReimbursementSummary,
  ReimbursementReport,
  LeaveBalanceReport,
  LeaveReport,
  AttendanceReport,
  AdvanceSalaryReport,
} from "models/summaryApis.data";
import { getRequestData } from "models/timesheet.data";

export class DashboardService {
  public getLeavesSummary(
    requestData: getRequestData
  ): Promise<LeaveSummary> | LeaveSummary {
    const service: ApiService = new ApiService(EndPoints.leaveSummary);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDateSearch: requestData.endDate },
        { fromDateSearch: requestData.startDate },
      ],
    });
  }
  public getTimesheetSummary(
    requestData: getRequestData
  ): Promise<TimesheetSummary> | TimesheetSummary {
    const service: ApiService = new ApiService(EndPoints.timesheetSummary);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDateSearch: requestData.endDate },
        { fromDateSearch: requestData.startDate },
      ],
    });
  }
  public getReimbursementSummary(
    requestData: getRequestData
  ): Promise<ReimbursementSummary> | ReimbursementSummary {
    const service: ApiService = new ApiService(EndPoints.reimbursementSummary);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDateSearch: requestData.endDate },
        { fromDateSearch: requestData.startDate },
      ],
    });
  }
  public getReimbursementReport(
    requestData: getRequestData
  ): Promise<ReimbursementReport> | ReimbursementReport {
    const service: ApiService = new ApiService(EndPoints.reimbursementReport);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDate: requestData.endDate },
        { fromDate: requestData.startDate },
      ],
    });
  }

  public getLeaveReport(
    requestData: getRequestData
  ): Promise<LeaveReport> | LeaveReport {
    const service: ApiService = new ApiService(EndPoints.leaveReport);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDate: requestData.endDate },
        { fromDate: requestData.startDate },
      ],
    });
  }

  public getLeaveBalanceReport(
    requestData: getRequestData
  ): Promise<LeaveBalanceReport> | LeaveBalanceReport {
    const service: ApiService = new ApiService(EndPoints.leaveBalanceReport);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDate: requestData.endDate },
        { fromDate: requestData.startDate },
      ],
    });
  }

  public getAttendanceReport(
    requestData: getRequestData
  ): Promise<AttendanceReport> | AttendanceReport {
    const service: ApiService = new ApiService(EndPoints.attendanceReport);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDate: requestData.endDate },
        { fromDate: requestData.startDate },
      ],
    });
  }

  public getAdvanceSalaryReport(
    requestData: getRequestData
  ): Promise<AdvanceSalaryReport> | AdvanceSalaryReport {
    const service: ApiService = new ApiService(EndPoints.advanceSalaryReport);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDate: requestData.endDate },
        { fromDate: requestData.startDate },
      ],
    });
  }
}

export default new DashboardService();
